import usePagination from "../../../hooks/usePagination";

import Wrapper from "../../../components/Wrapper";
import TTable from "../../../components/Table";

import { useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/api/useRequestAPI";
import moment from "moment";
import useReview from "../../../hooks/api/useReviewAPI";
import ActionRenderer from "./ActionRenderer";
import PublishBadge from "../../../components/PublishBadge";

const Reviews = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate } =
    usePagination("/website/reviews");

  const { data, isLoading, refetch } = useReview({
    page: page - 1,
    amount,
    enabled: true
  });

  const _columns = [
    {
      label: "#",
      key: "key",
      attributes: {
        align: "left",
        width: 30
      }
    },
    {
      label: "Fullname",
      key: "full_name",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Phone Number",
      key: "phone_number",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Message",
      key: "message",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Rating",
      key: "rating",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Status",
      key: "is_published",
      attributes: {
        align: "left"
      },
      render: ({ data }) => <PublishBadge isPublished={data.is_published} />
    },
    {
      label: "Created At",
      key: "created_at",
      attributes: {
        align: "left"
      },
      render: ({ data }) => (
        <span>{moment(data.createdAt).format("DD-MM-YYYY HH:mm")}</span>
      )
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50
      },
      render: ({ data }) => <ActionRenderer data={data} refetch={refetch} />
    }
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.reviews?.count}
          data={data?.reviews?.rows?.map((region, u) => ({
            ...region,
            key: (page - 1) * amount + u + 1
          }))}
        ></TTable>
      </div>
    </Wrapper>
  );
};

export default Reviews;
