import Switch from "../../../../../components/Switch";
import styles from "./style.module.scss";
import transport from "../../../../../assets/icons/transport.svg";

export default function Transport({ control }) {
  return (
    <div className={styles.items}>
      <div className={styles.item}>
        <div className={styles.img}>
          <img src={transport} alt="transport" />
          Transport
        </div>
        <Switch control={control} name="with_transport" disabled />
      </div>
    </div>
  );
}
