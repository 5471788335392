import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useContentAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_CONTENTS", props?.page, props?.amount, props?.categoryId],
    () =>
      request.get("content", {
        params: {
          page: props.page,
          limit: props.amount,
          category_id: props.categoryId
        }
      }),
    { enabled: !!props?.enabled }
  );

  const { data: content } = useQuery(
    ["GET_CONTENT", props?.contentId],
    () => request.get("content/" + props?.contentId),
    {
      enabled: !!props?.contentId
    }
  );

  const createContent = useMutation((data) => request.post("content", data));

  const updateContent = useMutation((data) =>
    request.put("content/" + data.id, data)
  );

  const deleteContent = useMutation((id) => request.delete("content/" + id));

  return {
    data,
    isLoading,
    refetch,
    content,
    createContent,
    updateContent,
    deleteContent
  };
};

export default useContentAPI;
