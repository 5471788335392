import { useQuery } from "react-query";
import { request } from "../../utils/api";

const useRevenueAPI = ({ agent_ids, start_date, end_date, enabled }) => {
  const { data, isLoading, refetch } = useQuery(
    ["GET_REVENUE", agent_ids, start_date, end_date],
    () =>
      request.get("statistics", {
        params: {
          agent_ids,
          start_date,
          end_date,
        },
      }),
    { enabled: enabled, refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useRevenueAPI;
