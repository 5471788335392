import styles from "./styles.module.scss";
import { Container } from "@mui/material";
import ArrowLeft, { NotFoundPageIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Container className={styles.box}>
      <div className={styles.flexbox}>
        <div className={styles.error}>
          <h2 className={styles.message}>Data not found</h2>
          <p className={styles.description}>
            Sorry, the data you are looking for doesn't exist.
          </p>
          <div className={styles.buttons}>
            <button
              onClick={() => navigate("/dashboard")}
              className={styles.backButton}
            >
              <ArrowLeft /> Go back
            </button>
            <button onClick={() => navigate("/")} className={styles.homeButton}>
              Take me home
            </button>
          </div>
        </div>
        <div className={styles.errorGraphic}>
          <NotFoundPageIcon />
        </div>
      </div>
    </Container>
  );
}
