import React, { useState } from 'react'
import styles from './styles.module.scss'
import GalleryPopup from '../../../Gallery'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const ImageMessage = ({ message }) => {
  const [openGallery, setOpenGallery] = useState(false)

  const gallery = message?.Media?.links?.map((item) => ({
    original: `https://cdn.tripoasia.com/${item}`,
    thumbnail: `https://cdn.tripoasia.com/${item}`
  }))

  return (
    <>
      <LazyLoadImage
        alt='default'
        className={styles.image}
        effect='blur'
        onClick={() => setOpenGallery(true)}
        src={
          `https://cdn.tripoasia.com/${message?.Media?.links?.[0]}` ||
          '/default_image.png'
        }
      />
      <GalleryPopup
        isOpen={openGallery}
        close={() => setOpenGallery(false)}
        images={gallery}
      />
    </>
  )
}

export default ImageMessage
