import React, { useRef, useState } from "react";
import {
  AudioMessagePauseIcon,
  AudioMessagePlayIcon,
} from "../../../../assets/icons";
import styles from "./style.module.scss"; // Импортируйте SCSS-модуль
import { LinearProgress } from "@mui/material";

const AudioMessage = ({ url, message }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const timer = message?.Media?.Document?.Attributes?.[0]?.Duration || 0; // Таймер в секундах

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const defaultTogglePlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const onLoadedMetadata = () => {
    const audio = audioRef.current;
    if (!isFinite(audio.duration)) {
      console.warn("Invalid duration:", audio.duration);
    }
  };

  const onTimeUpdate = () => {
    const audio = audioRef.current;
    if (audio.currentTime) {
      setCurrentTime(audio.currentTime); // Время теперь в секундах
      if (audio.currentTime >= timer) {
        audio.pause();
        setIsPlaying(false);
        setTimeout(() => {
          audio.currentTime = 0;
          audio.load();
          setCurrentTime(0);
        }, 500);
      }
    }
  };

  const handleProgressClick = (event) => {
    const audio = audioRef.current;
    if (!timer) return;

    const progressBar = event.currentTarget;
    const clickPosition = event.nativeEvent.offsetX;
    const progressBarWidth = progressBar.offsetWidth;
    const newTime = (clickPosition / progressBarWidth) * timer; // Рассчитываем новое время в секундах

    if (!isNaN(newTime) && isFinite(newTime)) {
      audio.currentTime = newTime; // Присваиваем время в секундах
      setCurrentTime(newTime);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  if (!timer || timer < 0.1)
    return (
      <div className={styles.audiomessage}>
        <audio ref={audioRef} src={url} />

        <div onClick={defaultTogglePlay} className={styles.control}>
          {isPlaying ? <AudioMessagePauseIcon /> : <AudioMessagePlayIcon />}
        </div>

        <div
          className={styles.content}
          style={{
            opacity: 0,
          }}
        >
          <div style={{ width: "100%" }} onClick={handleProgressClick}>
            <LinearProgress
              variant="determinate"
              value={(currentTime / timer) * 100}
            />
          </div>
          <p>
            {formatTime(currentTime)}/{formatTime(timer)}
          </p>
        </div>
      </div>
    );

  return (
    <div className={styles.audiomessage}>
      <audio
        ref={audioRef}
        src={url}
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={onTimeUpdate}
      />

      <div onClick={togglePlay} className={styles.control}>
        {isPlaying ? <AudioMessagePauseIcon /> : <AudioMessagePlayIcon />}
      </div>

      <div className={styles.content}>
        <div style={{ width: "100%" }} onClick={handleProgressClick}>
          <LinearProgress
            variant="determinate"
            value={(currentTime / timer) * 100}
          />
        </div>
        <p>
          {formatTime(currentTime)}/{formatTime(timer)}
        </p>
      </div>
    </div>
  );
};

export default AudioMessage;
