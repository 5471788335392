import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useSearchFlightAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_SEARCHS", props?.page, props?.amount],
    () =>
      request.get("flight-search", {
        params: {
          page: props.page,
          limit: props.amount,
          search: props.search,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: searchFlight } = useQuery(
    ["GET_SEARCH", props?.categoryId],
    () => request.get("flight-search/" + props?.searchFlightId),
    {
      enabled: !!props?.searchFlightId,
    }
  );

  const createSearchFlight = useMutation((data) =>
    request.post("flight-search", data)
  );

  const createSendFlightToEmail = useMutation((data) =>
    request.post("email/flight", data)
  );

  const updateSearchFlight = useMutation((data) =>
    request.put("flight-search/" + data.id, data)
  );

  const deleteSearchFlight = useMutation((id) =>
    request.delete("flight-search/" + id)
  );

  return {
    data,
    isLoading,
    refetch,
    searchFlight,
    createSearchFlight,
    updateSearchFlight,
    deleteSearchFlight,
    createSendFlightToEmail,
  };
};

export default useSearchFlightAPI;
