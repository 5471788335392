import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useAirportAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_AIRPORTS", props?.page, props?.amount],
    () =>
      request.get("iata_code", {
        params: {
          page: props.page,
          limit: props.amount,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: airport } = useQuery(
    ["GET_AIRPORT", props?.airportId],
    () => request.get("iata_code/" + props?.airportId),
    {
      enabled: !!props?.airportId,
    }
  );

  const createAirport = useMutation((data) => request.post("iata_code", data));

  const updateAirport = useMutation((data) =>
    request.put("iata_code/" + data.id, data)
  );

  const deleteAirport = useMutation((id) => request.delete("iata_code/" + id));

  return {
    data,
    isLoading,
    refetch,
    airport,
    createAirport,
    updateAirport,
    deleteAirport,
  };
};

export default useAirportAPI;
