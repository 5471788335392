import axios from "axios";
import { store } from "../redux/store";
import { tokenUpdateAmadeus } from "../hooks/useAmadeus";
import { setAmadeusToken } from "../redux/auth/authSlice";
import { toast } from "react-hot-toast";

export const AMADEUS_BASE_URL = process.env.REACT_APP_AMADEUS_BASE_URL;
export const AMADEUS_CLIENT_ID = process.env.REACT_APP_AMADEUS_CLIENT_ID;
export const AMADEUS_CLIENT_SECRET =
  process.env.REACT_APP_AMADEUS_CLIENT_SECRET;

const amadeusApi = axios.create({
  baseURL: AMADEUS_BASE_URL,
});

const errorHandler = async (error, hooks) => {
  const status = error.response.status;
  const originalRequest = error.config;

  if (status === 401) {
    const tokenInfo = await tokenUpdateAmadeus();

    store.dispatch(setAmadeusToken(tokenInfo.access_token));

    return amadeusApi(originalRequest);
  }

  toast.error(error.response.data.errors[0].detail);

  return Promise.reject(error.response);
};

amadeusApi.interceptors?.request.use(
  (config) => {
    if (config.url !== "v1/security/oauth2/token") {
      config.headers["Authorization"] =
        "Bearer " + store.getState().auth.amadeusToken;
    } else {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    return config;
  },

  (error) => errorHandler(error)
);

amadeusApi.interceptors?.response.use(
  (response) => response.data,
  errorHandler
);

export default amadeusApi;
