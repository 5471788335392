import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useAirlineAPI from "../../../../hooks/api/useAirlineAPI";

const AirlineCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { airline, createAirline, updateAirline } = useAirlineAPI({
    airlineId: id,
  });

  const { control, handleSubmit } = useForm({
    values: { ...(airline || {}) },
  });

  const onClick = useCallback((vals) => {
    if (!!id) {
      updateAirline.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Airline is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Airline!");
          },
        }
      );
    } else
      createAirline.mutate(
        {
          ...vals,
        },
        {
          onSuccess: () => {
            toast.success("Airline is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Airline!");
          },
        }
      );
  }, []);

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content title={!!id ? "Edit Airline" : "Add Airline"} height="80vh">
            <Box width="100%" display="flex" gap="20px">
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Key</p>
                  <Controller
                    name="key"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Value</p>
                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={styles.input}
                        required
                        minRows={5}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createAirline.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createAirline.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default AirlineCreate;
