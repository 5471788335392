import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Avatar, Box, Button, CircularProgress } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useParams } from 'react-router-dom'
import { CloseIcon, ReplyIcon, RightPanelIcon } from '../../../../assets/icons'
import useChats from '../../../../hooks/api/useChatsAPI'
import { setOpenProfile } from '../../../../redux/chat/chatSlice'
import { stringSingleAvatar } from '../../../../utils'
import ActionRenderer from '../ActionRenderer'
import ContactUpdateModal from '../Contact/Update'
import FieldMockUp from '../FIeldMockUp'
import ChatFileUpload from '../FileUpload'
import ChatMessage from '../Message'
import { FieldSkeleton } from '../Skeleton'
import ChatTools from '../Tool'
import useTool from '../Tool/useTool'
import styles from './style.module.scss'

export const ChatField = () => {
  let dragCounter = 0
  const dropRef = useRef(null)
  const dispatch = useDispatch()
  const { id, hashId } = useParams()
  const messagesEndRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [scrollHeight, setScrollHeight] = useState([])
  const [openUpdateModal, setOpenUpdateModal] = useState(false)

  const userData = useSelector((store) => store.auth)
  const { openProfile } = useSelector((store) => store.chat)

  const { chatData, refetch, reply, setReply, setOpenContact } =
    useOutletContext()
  const isAssignedUser = chatData?.Agent?.Id === userData?.user.id

  const {
    messages,
    chatRefetch,
    messageRefetch,
    messagesLoading,
    agentAssign,
    fetchNextPage,
    hasNextPage,
    uploadFile
  } = useChats({
    id: id,
    hash: hashId
  })

  const {
    payload,
    message,
    setMessage,
    clearAudio,
    setToolMessage,
    toolMessage,
    multipleFiles,
    setMultipleFiles
  } = useTool({ setLoader })

  const handleScroll = () => {
    if (messagesEndRef.current) {
      setScrollHeight(messagesEndRef.current?.scrollTop)
    }
  }

  const onOpenCreate = () => {
    setOpen(true)
  }
  const onOpenUpdateContactModal = () => {
    setOpenUpdateModal(true)
  }

  const handleAssignAgent = () => {
    const payload = {
      chat_id: chatData?.FullUser?.ID,
      user_key: userData?.user.id
    }

    toast.promise(agentAssign.mutateAsync(payload), {
      loading: 'Assigning...',
      success: () => {
        refetch()
        return 'Success'
      },
      error: () => {
        return 'This user is already assigned to another agent'
      }
    })
  }

  const handleScrollDown = () => {
    if (messagesEndRef?.current)
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
  }

  const handleUploadFile = async (file) => {
    await uploadFile.mutateAsync(
      { file },
      {
        onSuccess: (res) => {
          setMultipleFiles((prevFiles) => [
            ...prevFiles,
            `https://cdn.tripoasia.com/${res?.payload?.link}`
          ])
        },
        onError: () => {
          toast.error(
            `Something went wrong with file ${file.name}. Please contact support.`
          )
        }
      }
    )
  }

  const onMultipleFileAdd = async (files, event) => {
    setOpen(true)
    const maxFileSize = 100 * 1024 * 1024
    for (const file of files) {
      if (file.size > maxFileSize) {
        toast.error(
          `File ${file.name} exceeds the 100 MB limit. Please upload a smaller file.`
        )
        setOpen(false)
        continue
      }

      const formData = new FormData()
      formData.append('file', file)

      await handleUploadFile(file)
    }
  }

  const handleDrop = async (e) => {
    setOpen(true)
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    const maxFileSize = 100 * 1024 * 1024

    for (const file of e.dataTransfer.files) {
      if (file.size > maxFileSize) {
        toast.error(
          'File size exceeds the 100 MB limit. Please upload a smaller file.'
        )
        setOpen(false)
        continue
      }

      const formData = new FormData()
      formData.append('file', file)

      await handleUploadFile(file)
    }
  }

  const handlePaste = (event) => {
    const files = []
    const items = event.clipboardData.items

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.kind === 'file') {
        const file = item.getAsFile()
        if (file) {
          files.push(file)
        }
      }
    }

    if (files.length > 0) {
      onMultipleFileAdd(files, event)
      setMessage('')
    }
  }

  const handleRemoveFile = (file) => {
    setMultipleFiles(multipleFiles.filter((item) => item !== file))
  }

  const groupMessagesByDate = (messages) => {
    return messages?.reduce((acc, message) => {
      const messageDate = moment.unix(message?.Date).format('YYYY-MM-DD')
      if (!acc[messageDate]) {
        acc[messageDate] = []
      }
      acc[messageDate].push(message)
      return acc
    }, {})
  }

  const flatMessages = messages?.pages
    ?.flatMap((page) => page.payload?.messages?.Messages)
    ?.slice()

  const groupedMessages = useMemo(() => {
    if (messages) {
      const grouped = groupMessagesByDate(flatMessages)
      Object?.keys(grouped)?.forEach((date) => {
        grouped[date].sort((a, b) =>
          moment.unix(a.Date).diff(moment.unix(b.Date))
        )
      })
      return grouped
    }
  }, [messages])

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter++
    if (dragCounter === 1) {
      setIsDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter--
    if (dragCounter === 0) {
      setIsDragging(false)
    }
  }

  useEffect(() => {
    const dropArea = dropRef.current
    if (dropArea) {
      dropArea.addEventListener('dragenter', handleDragEnter)
      dropArea.addEventListener('dragleave', handleDragLeave)
      dropArea.addEventListener('dragover', (e) => e.preventDefault())
      dropArea.addEventListener('drop', handleDrop)
    } else {
      dropArea.removeEventListener('dragleave', handleDragLeave)
    }
    window.addEventListener('paste', handlePaste)

    return () => {
      if (dropArea) {
        dropArea.removeEventListener('dragenter', handleDragEnter)
        dropArea.removeEventListener('drop', handleDrop)
        dropArea.removeEventListener('dragleave', handleDragLeave)
      } else {
      }
      window.removeEventListener('paste', handlePaste)
    }
  }, [])

  useEffect(() => {
    const scrollableContainer = messagesEndRef.current
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [messagesEndRef.current])

  return (
    <div className={styles.field} ref={dropRef}>
      {isDragging && (
        <div className={styles.dragOverlay}>
          <p>Drag and drop files here to send</p>
        </div>
      )}
      {messagesLoading ? (
        <FieldSkeleton />
      ) : (
        <>
          <div className={styles.header}>
            <Box className={styles.content}>
              <div className={styles.img_box}>
                <Avatar
                  {...stringSingleAvatar(
                    [
                      chatData?.Users?.[0]?.LastName ||
                        chatData?.Users?.[0]?.FirstName ||
                        'User'
                    ].join(' ')
                  )}
                />
              </div>
              <div className={styles.desc}>
                <h5 className={styles.name}>
                  {`${chatData?.Users?.[0]?.LastName || 'User'} ${
                    chatData?.Users?.[0]?.FirstName || ''
                  }`}
                </h5>
                {chatData?.Users?.[0]?.Username && (
                  <p className={styles.username}>
                    @{chatData?.Users?.[0]?.Username}{' '}
                  </p>
                )}
              </div>
            </Box>
            <Box className={styles.settings}>
              <div
                className={styles.panel}
                onClick={() => {
                  dispatch(setOpenProfile(!openProfile))
                }}
              >
                <RightPanelIcon />
              </div>
              <ActionRenderer onOpen={onOpenUpdateContactModal} />
            </Box>
          </div>
          {!Object.keys(groupedMessages)?.includes('Invalid date') ? (
            <div
              id='scrollableDiv'
              className={styles.messages}
              ref={messagesEndRef}
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                scrollBehavior: 'smooth',
                overflow: 'auto'
              }}
            >
              <InfiniteScroll
                dataLength={Object.keys(groupedMessages).length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <Box className={styles.loader}>
                    <CircularProgress />
                  </Box>
                }
                inverse={true}
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse'
                }}
                scrollableTarget='scrollableDiv'
              >
                {Object.keys(groupedMessages).map((date, dateIdx) => (
                  <div key={date}>
                    <div className={styles.dateSeparator}>
                      {moment(date).calendar(null, {
                        sameDay: '[Today]',
                        lastDay: '[Yesterday]',
                        lastWeek: 'D MMMM YYYY',
                        sameElse: 'D MMMM YYYY'
                      })}
                    </div>
                    {groupedMessages[date].map((message, index) => {
                      return (
                        <ChatMessage
                          setOpenContact={setOpenContact}
                          key={message?.ID}
                          out={message?.Out}
                          time={message?.Date}
                          content={message?.Message}
                          senderType={message?.senderType}
                          data={groupedMessages[date]}
                          flatMessages={flatMessages}
                          message={message}
                          nextMessage={groupedMessages[date][index + 1] || null}
                          prevMessage={groupedMessages[date][index - 1] || null}
                          setReply={setReply}
                        />
                      )
                    })}
                  </div>
                ))}
              </InfiniteScroll>
              {scrollHeight < -500 && (
                <Box onClick={handleScrollDown} className={styles.scrollBottom}>
                  <ArrowDownwardIcon />
                </Box>
              )}
            </div>
          ) : (
            <FieldMockUp text='Click the Start Chat button and start sending messages.' />
          )}
          {reply?.ID && (
            <div className={styles.reply}>
              <div className={styles.content}>
                <ReplyIcon />
                <div>
                  <h4>Reply to message</h4>
                  <p>{reply?.Message}</p>
                </div>
              </div>
              <div onClick={() => setReply({})} className={styles.close_button}>
                <CloseIcon />
              </div>
            </div>
          )}
          <div className={styles.footer} id='chat-input'>
            {chatData?.Agent?.Id && isAssignedUser ? (
              <ChatTools
                onMultipleFileAdd={onMultipleFileAdd}
                onUploadOpen={onOpenCreate}
                chatRefetch={chatRefetch}
                messageRefetch={messageRefetch}
                handleScrollDown={handleScrollDown}
                reply={reply}
                setReply={setReply}
                setMessage={setToolMessage}
                message={toolMessage}
                isLoading={loader}
                setIsLoading={setLoader}
              />
            ) : (
              <Button variant='contained' onClick={handleAssignAgent} fullWidth>
                Start Chat
              </Button>
            )}
          </div>
        </>
      )}
      <ChatFileUpload
        open={open}
        setOpen={setOpen}
        handleRemoveFile={handleRemoveFile}
        chatRefetch={chatRefetch}
        messageRefetch={messageRefetch}
        payload={payload}
        clearAudio={clearAudio}
        setMessage={setMessage}
        message={message}
        setMultipleFiles={setMultipleFiles}
        isLoading={uploadFile?.isLoading}
        handleScrollDown={handleScrollDown}
        multipleFiles={multipleFiles}
        onMultipleFileAdd={onMultipleFileAdd}
      />
      {openUpdateModal && (
        <ContactUpdateModal
          chatData={chatData}
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
        />
      )}
    </div>
  )
}

export default ChatField
