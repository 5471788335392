import { NumericFormat } from "react-number-format";
import styles from "./style.module.scss";

const RevenueCard = ({ title, value, suffix, prefix, children, ...props }) => {
  return (
    <div className={styles.card} {...props}>
      <div className={styles.number}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.content}>
          <p>
            <NumericFormat
              thousandSeparator={true}
              decimalScale={1}
              displayType="text"
              value={value}
              suffix={suffix}
              prefix={prefix}
            />
          </p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RevenueCard;
