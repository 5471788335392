import Content from "../../../../../components/Content";
import FlightItem from "./FlightItem";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FlightPopup from "./FlightPopup";

import { Box, Button } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { orderSections } from "../..";

const Flights = () => {
  const navigate = useNavigate();
  const { isConfirmed, flights, addFlight, deleteFlight, changeFlight } =
    useOutletContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const toggle = () => {
    setIsModalOpen((prev) => {
      if (prev) postAction();

      return !prev;
    });
  };

  const onEdit = (data, index) => {
    setSelectedFlight(data);
    setSelectedIndex(index);
    toggle();
  };

  const postAction = () => {
    setSelectedFlight(null);
    setSelectedIndex(null);
  };

  const onContinue = () => {
    if (flights?.length === 0) return;

    navigate(`../${orderSections.PASSENGERS}`);
  };

  const handleBack = () => {
    navigate(`../${orderSections.LEAD}`);
  };

  return (
    <Box width="100%" height="calc(100vh - 144px)" position="relative">
      <Content title="Flights Information">
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          mt="16px"
          gap="12px"
        >
          {flights.map((flight, f) => {
            const destinations = [];
            let arrival_date, departure_date;

            flight?.itineraries?.forEach((itinerary, i) =>
              itinerary?.segments?.forEach((segment, s) => {
                if (i === 0 && s === 0)
                  departure_date = moment(segment.departure.at).format(
                    "DD.MM.YYYY"
                  );

                if (
                  i === flight.itineraries.length - 1 &&
                  s === itinerary.segments.length - 1
                )
                  arrival_date = moment(segment.arrival.at).format(
                    "DD.MM.YYYY"
                  );

                destinations.push({
                  from: segment.departure.iataCode,
                  from_at: moment(segment.departure.at).format("HH:mm"),
                  to: segment.arrival.iataCode,
                  to_at: segment.arrival.at
                    ? moment(segment.arrival.at).format("HH:mm")
                    : null,
                });
              })
            );

            return (
              <FlightItem
                key={f}
                price={flight?.price?.total}
                flightType={flight.flightType}
                destinations={destinations}
                deleteFlight={() => deleteFlight(f)}
                arrival_data={arrival_date}
                departure_data={departure_date}
                onEdit={() => onEdit(flight, f)}
                hideActions={isConfirmed}
              />
            );
          })}
        </Box>
        {!isConfirmed && (
          <Box width="100%" display="flex" mt="8px" flexDirection="column">
            <Button variant="outlinedSecondary" onClick={toggle}>
              <AddOutlinedIcon /> Add Flight
            </Button>
          </Box>
        )}
      </Content>

      <Box
        position="absolute"
        left="-17px"
        bottom="-17px"
        width="calc(100% + 34px)"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        bgcolor="white"
        height={56}
        padding="8px"
        style={{
          "box-shadow": " 0px 1px 0px 0px rgba(229, 233, 235, 1) inset",
        }}
      >
        {isConfirmed && (
          <Button variant="outlinedSecondary" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          variant="contained"
          onClick={onContinue}
          style={{ marginLeft: 12 }}
        >
          {isConfirmed ? "Next" : "Save & continue"}
          <ArrowForwardIosOutlinedIcon
            style={{
              fontSize: "12px",
              marginLeft: 12,
            }}
          />
        </Button>
      </Box>

      <FlightPopup
        key={"flight-popup" + isModalOpen}
        isOpen={isModalOpen}
        setSelectedFlight={setSelectedFlight}
        toggle={toggle}
        addFlight={addFlight}
        selectedFlight={selectedFlight}
        changeFlight={(data) => changeFlight(data, selectedIndex)}
        postAction={postAction}
      />
    </Box>
  );
};

export default Flights;
