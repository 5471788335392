import FormReactAsyncSelect from "../../../../../../components/FormSelect/AsyncSelect";
import { promiseCity } from "../../../../../../utils/getCities";
import styles from "../style.module.scss";
import HFDateTimePicker from "../../../../../../components/FormDatePicker/DateTimePicker";
import { customStyles } from "../../../../../../consts/lead";
import { useWatch } from "react-hook-form";
import { iataCodes } from "../../../../../../consts/flights";

export default function OneWayFormType({
  control,
  index,
  params,
  addRouteParam,
  changeFlightParams,
  removeRouteParam,
  reset,
  setValue,
  watch,
}) {
  const onChangeSelect = (e, index, key) => {
    changeFlightParams(index, key, e);
  };

  const cityTo = useWatch({
    control,
    name: `segments.${[index]}.city_to`,
  });
  const cityFrom = useWatch({
    control,
    name: `segments.${[index]}.city_from`,
  });

  return (
    <>
      <FormReactAsyncSelect
        styles={customStyles}
        placeholder="From"
        className={styles.select}
        getOptionLabel={(opt) => opt.key || opt.label || opt.value}
        getOptionValue={(opt) => opt.value}
        control={control}
        name={`segments.${[index]}.city_from`}
        defaultOptions={iataCodes}
        value={cityFrom ? { value: cityFrom, key: cityFrom } : null}
        loadOptions={promiseCity}
        customOnChange={(e) => {
          setValue(`segments.${[index]}.city_from`, e.value);
          onChangeSelect(e, 0, "city_from");
        }}
      />
      <FormReactAsyncSelect
        styles={customStyles}
        value={cityTo ? { value: cityTo, key: cityTo } : null}
        placeholder="To"
        className={styles.select}
        defaultOptions={iataCodes}
        getOptionLabel={(opt) => opt.key || opt.label || opt.value}
        getOptionValue={(opt) => opt.value}
        control={control}
        name={`segments.${[index]}.city_to`}
        loadOptions={promiseCity}
        customOnChange={(e) => {
          setValue(`segments.${[index]}.city_to`, e.value);
          onChangeSelect(e, 0, "city_to");
        }}
      />
      <HFDateTimePicker
        control={control}
        name={`segments.${[index]}.date_departure`}
        placeholder={"Departure date"}
        // value={selectedDay}
        // inputPlaceholder="Departure date"
        // colorPrimary="var(--primary-color)"
        // shouldHighlightWeekends
        // calendarPopperPosition="bottom"
        className={"date_time-picker"}
        // customOnChange={(e) => onChangeDate(e, 0, "departure_at")}
      />
    </>
  );
}
