export const ClockIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_419_3105)">
      <path
        d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_419_3105">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default function ArrowLeft() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12H5M5 12L12 19M5 12L12 5"
        stroke="#344054"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export const DotsVertical = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 9.99992C10.8332 9.53968 10.4601 9.16659 9.99984 9.16659C9.5396 9.16659 9.1665 9.53968 9.1665 9.99992C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
      stroke="#98A2B3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99984 4.99992C10.4601 4.99992 10.8332 4.62682 10.8332 4.16659C10.8332 3.70635 10.4601 3.33325 9.99984 3.33325C9.5396 3.33325 9.1665 3.70635 9.1665 4.16659C9.1665 4.62682 9.5396 4.99992 9.99984 4.99992Z"
      stroke="#98A2B3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99984 16.6666C10.4601 16.6666 10.8332 16.2935 10.8332 15.8333C10.8332 15.373 10.4601 14.9999 9.99984 14.9999C9.5396 14.9999 9.1665 15.373 9.1665 15.8333C9.1665 16.2935 9.5396 16.6666 9.99984 16.6666Z"
      stroke="#98A2B3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const UserEdit = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00016 10.3333H5.00017C4.06979 10.3333 3.6046 10.3333 3.22607 10.4482C2.3738 10.7067 1.70686 11.3736 1.44832 12.2259C1.3335 12.6044 1.3335 13.0696 1.3335 14M9.66683 5C9.66683 6.65685 8.32368 8 6.66683 8C5.00998 8 3.66683 6.65685 3.66683 5C3.66683 3.34315 5.00998 2 6.66683 2C8.32368 2 9.66683 3.34315 9.66683 5ZM7.3335 14L9.40107 13.4093C9.50008 13.381 9.54958 13.3668 9.59575 13.3456C9.63675 13.3268 9.67573 13.3039 9.7121 13.2772C9.75306 13.2471 9.78946 13.2107 9.86227 13.1379L14.1669 8.83336C14.6271 8.37311 14.6271 7.62689 14.1669 7.16665C13.7066 6.70642 12.9604 6.70642 12.5002 7.16666L8.19561 11.4712C8.1228 11.544 8.08639 11.5804 8.05632 11.6214C8.02962 11.6578 8.00669 11.6967 7.98786 11.7377C7.96666 11.7839 7.95252 11.8334 7.92423 11.9324L7.3335 14Z"
      stroke="#475467"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const IncommingIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#B42318" />
    <path
      d="M10.6668 5.33325L5.3335 10.6666M5.3335 10.6666H8.88905M5.3335 10.6666V7.11103"
      stroke="white"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ReplyIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="6"
      transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 32 0)"
      fill="#F2F4F7"
    />
    <path
      d="M10.1391 16.4049C9.97635 16.2654 9.89497 16.1956 9.86516 16.1126C9.83899 16.0398 9.83899 15.9601 9.86516 15.8873C9.89497 15.8043 9.97635 15.7345 10.1391 15.595L15.7862 10.7546C16.0664 10.5145 16.2065 10.3944 16.3251 10.3915C16.4281 10.3889 16.5266 10.4342 16.5917 10.5141C16.6667 10.6061 16.6667 10.7906 16.6667 11.1595L16.6667 14.023C18.0898 14.272 19.3923 14.9931 20.3602 16.0759C21.4154 17.2563 21.9992 18.7839 22 20.3673L22 20.7753C21.3004 19.9325 20.427 19.251 19.4395 18.7772C18.5689 18.3596 17.6277 18.1122 16.6667 18.047L16.6667 20.8404C16.6667 21.2093 16.6667 21.3938 16.5917 21.4858C16.5266 21.5657 16.4281 21.611 16.3251 21.6084C16.2065 21.6055 16.0664 21.4854 15.7862 21.2453L10.1391 16.4049Z"
      stroke="#475467"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ShareIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.13909 8.40489C1.97635 8.26539 1.89497 8.19564 1.86516 8.11264C1.83899 8.03979 1.83899 7.96011 1.86516 7.88726C1.89497 7.80426 1.97635 7.73451 2.13909 7.59501L7.78625 2.7546C8.0664 2.51447 8.20647 2.3944 8.32507 2.39146C8.42814 2.3889 8.52659 2.43418 8.59172 2.5141C8.66667 2.60606 8.66667 2.79055 8.66667 3.15953L8.66667 6.02304C10.0898 6.27201 11.3923 6.99312 12.3602 8.07586C13.4154 9.25629 13.9992 10.7839 14 12.3673L14 12.7753C13.3004 11.9325 12.427 11.251 11.4395 10.7772C10.5689 10.3596 9.62772 10.1122 8.66667 10.047L8.66667 12.8404C8.66667 13.2093 8.66667 13.3938 8.59172 13.4858C8.52658 13.5657 8.42813 13.611 8.32507 13.6084C8.20647 13.6055 8.0664 13.4854 7.78624 13.2453L2.13909 8.40489Z"
      stroke="#475467"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const OutgoingIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#027A48" />
    <path
      d="M5.3335 10.6667L10.6668 5.33337M10.6668 5.33337H7.11127M10.6668 5.33337V8.88893"
      stroke="white"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const PhoneCallIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1074_7624)">
      <path
        d="M9.3666 3.99992C10.0178 4.12696 10.6162 4.44542 11.0853 4.91454C11.5544 5.38366 11.8729 5.9821 11.9999 6.63325M9.3666 1.33325C10.7195 1.48354 11.981 2.08937 12.9441 3.05126C13.9072 4.01315 14.5146 5.27393 14.6666 6.62659M6.81792 9.24196C6.01687 8.44091 5.38435 7.53515 4.92035 6.56874C4.88044 6.48561 4.86048 6.44405 4.84515 6.39145C4.79067 6.20455 4.8298 5.97505 4.94314 5.81676C4.97504 5.77222 5.01314 5.73411 5.08935 5.65791C5.32242 5.42483 5.43895 5.3083 5.51514 5.19111C5.80247 4.74919 5.80247 4.17947 5.51515 3.73754C5.43895 3.62035 5.32242 3.50382 5.08935 3.27075L4.95943 3.14084C4.60514 2.78654 4.42799 2.60939 4.23774 2.51316C3.85937 2.32178 3.41252 2.32178 3.03415 2.51316C2.84389 2.60939 2.66675 2.78654 2.31245 3.14084L2.20736 3.24593C1.85428 3.59901 1.67774 3.77555 1.54291 4.01557C1.39329 4.28191 1.28572 4.69557 1.28663 5.00105C1.28745 5.27635 1.34085 5.4645 1.44765 5.84079C2.02163 7.86306 3.10462 9.7713 4.6966 11.3633C6.28859 12.9553 8.19683 14.0383 10.2191 14.6122C10.5954 14.719 10.7835 14.7724 11.0588 14.7733C11.3643 14.7742 11.778 14.6666 12.0443 14.517C12.2843 14.3822 12.4609 14.2056 12.814 13.8525L12.9191 13.7474C13.2733 13.3931 13.4505 13.216 13.5467 13.0257C13.7381 12.6474 13.7381 12.2005 13.5467 11.8221C13.4505 11.6319 13.2733 11.4547 12.9191 11.1005L12.7891 10.9705C12.5561 10.7375 12.4395 10.6209 12.3223 10.5447C11.8804 10.2574 11.3107 10.2574 10.8688 10.5447C10.7516 10.6209 10.6351 10.7375 10.402 10.9705C10.3258 11.0467 10.2877 11.0849 10.2431 11.1167C10.0848 11.2301 9.85534 11.2692 9.66843 11.2147C9.61584 11.1994 9.57428 11.1795 9.49115 11.1395C8.52474 10.6755 7.61898 10.043 6.81792 9.24196Z"
        stroke="#D92D20"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1074_7624">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export function NotFoundPageIcon() {
  return (
    <svg
      width="480"
      height="350"
      viewBox="0 0 480 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="240" cy="175" r="174.545" fill="#FECDCA" />
      <circle cx="56.7273" cy="44.091" r="17.4545" fill="#FEE4E2" />
      <circle cx="432" cy="275.364" r="13.0909" fill="#FEE4E2" />
      <circle cx="54.5454" cy="301.545" r="21.8182" fill="#FEE4E2" />
      <circle cx="458.182" cy="100.818" r="21.8182" fill="#FEE4E2" />
      <circle cx="416.727" cy="24.4546" r="15.2727" fill="#FEE4E2" />
      <g filter="url(#filter0_dd_938_25102)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M247.605 35.3638C211.09 35.3638 178.789 53.4077 159.132 81.0656C152.713 79.5518 146.019 78.7508 139.138 78.7508C91.2137 78.7508 52.3636 117.601 52.3636 165.525C52.3636 213.449 91.2137 252.299 139.138 252.299L139.211 252.299H355.954C355.993 252.299 356.033 252.299 356.073 252.299C398.006 252.299 432 218.305 432 176.372C432 134.438 398.006 100.444 356.073 100.444C353.094 100.444 350.156 100.616 347.267 100.95C330.645 62.3712 292.279 35.3638 247.605 35.3638Z"
          fill="#FEF3F2"
        />
        <circle
          cx="139.138"
          cy="165.525"
          r="86.774"
          fill="url(#paint0_linear_938_25102)"
        />
        <circle
          cx="247.605"
          cy="143.831"
          r="108.468"
          fill="url(#paint1_linear_938_25102)"
        />
        <circle
          cx="356.073"
          cy="176.372"
          r="75.9273"
          fill="url(#paint2_linear_938_25102)"
        />
      </g>
      <g filter="url(#filter1_b_938_25102)">
        <rect
          x="178.909"
          y="183.727"
          width="122.182"
          height="122.182"
          rx="61.0909"
          fill="#D92D20"
          fill-opacity="0.8"
        />
        <path
          d="M262.909 267.727L254 258.818M260.364 243.546C260.364 255.495 250.677 265.182 238.727 265.182C226.778 265.182 217.091 255.495 217.091 243.546C217.091 231.596 226.778 221.909 238.727 221.909C250.677 221.909 260.364 231.596 260.364 243.546Z"
          stroke="white"
          stroke-width="4.36364"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_938_25102"
          x="32.3636"
          y="35.3638"
          width="419.636"
          height="256.935"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_938_25102"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_938_25102"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_938_25102"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_938_25102"
            result="effect2_dropShadow_938_25102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_938_25102"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_938_25102"
          x="170.909"
          y="175.727"
          width="138.182"
          height="138.182"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_938_25102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_938_25102"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_938_25102"
          x1="72.5076"
          y1="108.192"
          x2="225.912"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_938_25102"
          x1="164.318"
          y1="72.1652"
          x2="356.073"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_938_25102"
          x1="297.771"
          y1="126.205"
          x2="432"
          y2="252.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FED7D7" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export const NotePadHeaderIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_582_9240)">
      <rect x="2" y="1" width="32" height="32" rx="6" fill="white" />
      <rect x="2.5" y="1.5" width="31" height="31" rx="5.5" stroke="#EAECF0" />
      <path
        d="M17.3333 11.6667H15.2C14.0799 11.6667 13.5198 11.6667 13.092 11.8847C12.7157 12.0764 12.4097 12.3824 12.218 12.7587C12 13.1865 12 13.7466 12 14.8667V19.8C12 20.9201 12 21.4802 12.218 21.908C12.4097 22.2843 12.7157 22.5903 13.092 22.782C13.5198 23 14.0799 23 15.2 23H20.1333C21.2534 23 21.8135 23 22.2413 22.782C22.6176 22.5903 22.9236 22.2843 23.1153 21.908C23.3333 21.4802 23.3333 20.9201 23.3333 19.8V17.6667M18.6667 20.3333H14.6667M20 17.6667H14.6667M23.4142 11.5858C24.1953 12.3668 24.1953 13.6332 23.4142 14.4142C22.6332 15.1953 21.3668 15.1953 20.5858 14.4142C19.8047 13.6332 19.8047 12.3668 20.5858 11.5858C21.3668 10.8047 22.6332 10.8047 23.4142 11.5858Z"
        stroke="#344054"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_582_9240"
        x="0"
        y="0"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_582_9240"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_582_9240"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const DotIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="4" fill="#12B76A" />
  </svg>
);
export const ArrowBack = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6673 10H3.33398M3.33398 10L8.33398 15M3.33398 10L8.33398 5"
      stroke="black"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const FilterIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
      stroke="#344054"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const RecordingIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#FEE4E2" />
    <rect
      x="2"
      y="2"
      width="32"
      height="32"
      rx="16"
      stroke="#FEF3F2"
      stroke-width="4"
    />
    <path
      d="M22.6673 16.6663V17.9997C22.6673 20.577 20.578 22.6663 18.0007 22.6663M13.334 16.6663V17.9997C13.334 20.577 15.4233 22.6663 18.0007 22.6663M18.0007 22.6663V24.6663M15.334 24.6663H20.6673M18.0007 19.9997C16.8961 19.9997 16.0007 19.1042 16.0007 17.9997V13.333C16.0007 12.2284 16.8961 11.333 18.0007 11.333C19.1052 11.333 20.0007 12.2284 20.0007 13.333V17.9997C20.0007 19.1042 19.1052 19.9997 18.0007 19.9997Z"
      stroke="#D92D20"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AudioTrashIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
      stroke="#98A2B3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const PlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 4.16699V15.8337M4.16699 10.0003H15.8337"
      stroke="#344054"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AudioPlayIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.49476C2.5 2.00917 2.5 1.76638 2.60125 1.63255C2.68945 1.51595 2.82426 1.4438 2.9702 1.43509C3.13772 1.42509 3.33973 1.55976 3.74376 1.82912L9.00154 5.3343C9.33538 5.55686 9.5023 5.66815 9.56047 5.80841C9.61133 5.93103 9.61133 6.06885 9.56047 6.19148C9.5023 6.33174 9.33538 6.44302 9.00154 6.66558L3.74376 10.1708C3.33973 10.4401 3.13772 10.5748 2.9702 10.5648C2.82426 10.5561 2.68945 10.4839 2.60125 10.3673C2.5 10.2335 2.5 9.99071 2.5 9.50513V2.49476Z"
      fill="#D92D20"
      stroke="#D92D20"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const UploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const SmileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_638_9582)">
      <path
        d="M6.66602 11.667C6.66602 11.667 7.91602 13.3337 9.99935 13.3337C12.0827 13.3337 13.3327 11.667 13.3327 11.667M12.4993 7.50033H12.5077M7.49935 7.50033H7.50768M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003ZM12.916 7.50033C12.916 7.73044 12.7295 7.91699 12.4993 7.91699C12.2692 7.91699 12.0827 7.73044 12.0827 7.50033C12.0827 7.27021 12.2692 7.08366 12.4993 7.08366C12.7295 7.08366 12.916 7.27021 12.916 7.50033ZM7.91602 7.50033C7.91602 7.73044 7.72947 7.91699 7.49935 7.91699C7.26923 7.91699 7.08268 7.73044 7.08268 7.50033C7.08268 7.27021 7.26923 7.08366 7.49935 7.08366C7.72947 7.08366 7.91602 7.27021 7.91602 7.50033Z"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_638_9582">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const AudioPauseIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.4 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5H3.4C3.96005 10.5 4.24008 10.5 4.45399 10.391C4.64215 10.2951 4.79513 10.1422 4.89101 9.95399C5 9.74008 5 9.46005 5 8.9V3.1C5 2.53995 5 2.25992 4.89101 2.04601C4.79513 1.85785 4.64215 1.70487 4.45399 1.60899C4.24008 1.5 3.96005 1.5 3.4 1.5Z"
      fill="#D92D20"
    />
    <path
      d="M8.9 1.5H8.6C8.03995 1.5 7.75992 1.5 7.54601 1.60899C7.35785 1.70487 7.20487 1.85785 7.10899 2.04601C7 2.25992 7 2.53995 7 3.1V8.9C7 9.46005 7 9.74008 7.10899 9.95399C7.20487 10.1422 7.35785 10.2951 7.54601 10.391C7.75992 10.5 8.03995 10.5 8.6 10.5H8.9C9.46005 10.5 9.74008 10.5 9.95399 10.391C10.1422 10.2951 10.2951 10.1422 10.391 9.95399C10.5 9.74008 10.5 9.46005 10.5 8.9V3.1C10.5 2.53995 10.5 2.25992 10.391 2.04601C10.2951 1.85785 10.1422 1.70487 9.95399 1.60899C9.74008 1.5 9.46005 1.5 8.9 1.5Z"
      fill="#D92D20"
    />
  </svg>
);
export const AudioMessagePlayIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#D92D20" />
    <path
      d="M11.333 11.3267C11.333 10.6792 11.333 10.3555 11.468 10.1771C11.5856 10.0216 11.7654 9.9254 11.9599 9.91378C12.1833 9.90044 12.4527 10.08 12.9914 10.4391L20.0017 15.1127C20.4469 15.4095 20.6694 15.5579 20.747 15.7449C20.8148 15.9084 20.8148 16.0921 20.747 16.2556C20.6694 16.4426 20.4469 16.591 20.0017 16.8878L12.9914 21.5614C12.4527 21.9205 12.1833 22.1001 11.9599 22.0867C11.7654 22.0751 11.5856 21.9789 11.468 21.8234C11.333 21.645 11.333 21.3213 11.333 20.6738V11.3267Z"
      fill="white"
      stroke="white"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AudioMessagePauseIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#D92D20" />
    <path
      d="M12.5333 10H12.1333C11.3866 10 11.0132 10 10.728 10.1453C10.4771 10.2732 10.2732 10.4771 10.1453 10.728C10 11.0132 10 11.3866 10 12.1333V19.8667C10 20.6134 10 20.9868 10.1453 21.272C10.2732 21.5229 10.4771 21.7268 10.728 21.8547C11.0132 22 11.3866 22 12.1333 22H12.5333C13.2801 22 13.6534 22 13.9387 21.8547C14.1895 21.7268 14.3935 21.5229 14.5213 21.272C14.6667 20.9868 14.6667 20.6134 14.6667 19.8667V12.1333C14.6667 11.3866 14.6667 11.0132 14.5213 10.728C14.3935 10.4771 14.1895 10.2732 13.9387 10.1453C13.6534 10 13.2801 10 12.5333 10Z"
      fill="white"
    />
    <path
      d="M19.8667 10H19.4667C18.7199 10 18.3466 10 18.0613 10.1453C17.8105 10.2732 17.6065 10.4771 17.4787 10.728C17.3333 11.0132 17.3333 11.3866 17.3333 12.1333V19.8667C17.3333 20.6134 17.3333 20.9868 17.4787 21.272C17.6065 21.5229 17.8105 21.7268 18.0613 21.8547C18.3466 22 18.7199 22 19.4667 22H19.8667C20.6134 22 20.9868 22 21.272 21.8547C21.5229 21.7268 21.7268 21.5229 21.8547 21.272C22 20.9868 22 20.6134 22 19.8667V12.1333C22 11.3866 22 11.0132 21.8547 10.728C21.7268 10.4771 21.5229 10.2732 21.272 10.1453C20.9868 10 20.6134 10 19.8667 10Z"
      fill="white"
    />
  </svg>
);
export const FileIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#F4EBFF" />
    <path
      d="M17.3337 9.51265V12.2663C17.3337 12.6397 17.3337 12.8264 17.4063 12.969C17.4702 13.0944 17.5722 13.1964 17.6977 13.2603C17.8403 13.333 18.027 13.333 18.4003 13.333H21.154M21.3337 14.6585V19.4663C21.3337 20.5864 21.3337 21.1465 21.1157 21.5743C20.9239 21.9506 20.618 22.2566 20.2416 22.4484C19.8138 22.6663 19.2538 22.6663 18.1337 22.6663H13.867C12.7469 22.6663 12.1868 22.6663 11.759 22.4484C11.3827 22.2566 11.0767 21.9506 10.885 21.5743C10.667 21.1465 10.667 20.5864 10.667 19.4663V12.533C10.667 11.4129 10.667 10.8529 10.885 10.425C11.0767 10.0487 11.3827 9.74274 11.759 9.55099C12.1868 9.33301 12.7469 9.33301 13.867 9.33301H16.0082C16.4974 9.33301 16.7419 9.33301 16.9721 9.38827C17.1762 9.43726 17.3713 9.51807 17.5502 9.62773C17.7521 9.75141 17.925 9.92436 18.2709 10.2703L20.3964 12.3957C20.7423 12.7417 20.9153 12.9146 21.0389 13.1164C21.1486 13.2954 21.2294 13.4905 21.2784 13.6945C21.3337 13.9247 21.3337 14.1693 21.3337 14.6585Z"
      stroke="#475467"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const MapPin = () => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1191_29718)">
      <path
        d="M13.437 15.0474C15.2944 13.6823 16.5 11.4819 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 11.4819 2.70556 13.6823 4.56304 15.0474C5.97456 16.111 7.96422 17.7889 8.42158 20.4896C8.47002 20.7757 8.70985 20.9977 9 20.9977C9.29015 20.9977 9.52998 20.7757 9.57842 20.4896C10.0358 17.7889 12.0254 16.111 13.437 15.0474Z"
        fill="#EA382E"
      />
      <path
        d="M13.7355 15.4485C15.7143 13.9931 17 11.6466 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 11.6467 2.28574 13.9931 4.26452 15.4485C5.66871 16.5068 7.50667 18.0816 7.9286 20.5731C8.01377 21.0761 8.44274 21.4977 9 21.4977C9.55726 21.4977 9.98623 21.0761 10.0714 20.5731C10.4933 18.0816 12.3313 16.5068 13.7355 15.4485Z"
        stroke="url(#paint0_linear_1191_29718)"
      />
      <circle cx="9" cy="9" r="3" fill="black" fill-opacity="0.4" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1191_29718"
        x1="9"
        y1="1.5"
        x2="9"
        y2="20.9977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0.35" />
      </linearGradient>
      <clipPath id="clip0_1191_29718">
        <rect width="18" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const CloseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1663 5.83301L5.83301 14.1663M5.83301 5.83301L14.1663 14.1663"
      stroke="#475467"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const FilePreviewIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#FEE4E2" />
    <path
      d="M26 14.2695V18.4C26 18.9601 26 19.2401 26.109 19.454C26.2049 19.6422 26.3578 19.7951 26.546 19.891C26.7599 20 27.0399 20 27.6 20H31.7305M32 21.9882V29.2C32 30.8802 32 31.7202 31.673 32.362C31.3854 32.9265 30.9265 33.3854 30.362 33.673C29.7202 34 28.8802 34 27.2 34H20.8C19.1198 34 18.2798 34 17.638 33.673C17.0735 33.3854 16.6146 32.9265 16.327 32.362C16 31.7202 16 30.8802 16 29.2V18.8C16 17.1198 16 16.2798 16.327 15.638C16.6146 15.0735 17.0735 14.6146 17.638 14.327C18.2798 14 19.1198 14 20.8 14H24.0118C24.7455 14 25.1124 14 25.4577 14.0829C25.7638 14.1564 26.0564 14.2776 26.3249 14.4421C26.6276 14.6276 26.887 14.887 27.4059 15.4059L30.5941 18.5941C31.113 19.113 31.3724 19.3724 31.5579 19.6751C31.7224 19.9436 31.8436 20.2362 31.9171 20.5423C32 20.8876 32 21.2545 32 21.9882Z"
      stroke="#D92D20"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AddSessionIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M19 21V15M16 18H22M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      stroke="#344054"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const GlassIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const RightPanelIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 2.5V17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z"
      stroke="#344054"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ChatSendIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const FaceSmileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_638_9375)">
      <path
        d="M6.66602 11.667C6.66602 11.667 7.91602 13.3337 9.99935 13.3337C12.0827 13.3337 13.3327 11.667 13.3327 11.667M12.4993 7.50033H12.5077M7.49935 7.50033H7.50768M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003ZM12.916 7.50033C12.916 7.73044 12.7295 7.91699 12.4993 7.91699C12.2692 7.91699 12.0827 7.73044 12.0827 7.50033C12.0827 7.27021 12.2692 7.08366 12.4993 7.08366C12.7295 7.08366 12.916 7.27021 12.916 7.50033ZM7.91602 7.50033C7.91602 7.73044 7.72947 7.91699 7.49935 7.91699C7.26923 7.91699 7.08268 7.73044 7.08268 7.50033C7.08268 7.27021 7.26923 7.08366 7.49935 7.08366C7.72947 7.08366 7.91602 7.27021 7.91602 7.50033Z"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_638_9375">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const MicroPhoneIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8327 8.33366V10.0003C15.8327 13.222 13.221 15.8337 9.99935 15.8337M4.16602 8.33366V10.0003C4.16602 13.222 6.77769 15.8337 9.99935 15.8337M9.99935 15.8337V18.3337M6.66602 18.3337H13.3327M9.99935 12.5003C8.61864 12.5003 7.49935 11.381 7.49935 10.0003V4.16699C7.49935 2.78628 8.61864 1.66699 9.99935 1.66699C11.3801 1.66699 12.4993 2.78628 12.4993 4.16699V10.0003C12.4993 11.381 11.3801 12.5003 9.99935 12.5003Z"
      stroke="#98A2B3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const AttachmentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6261 9.08247L10.1131 16.5955C8.40458 18.304 5.63448 18.304 3.92594 16.5955C2.2174 14.8869 2.2174 12.1168 3.92594 10.4083L11.4389 2.89529C12.578 1.75626 14.4247 1.75626 15.5637 2.89529C16.7028 4.03432 16.7028 5.88105 15.5637 7.02008L8.34536 14.2385C7.77584 14.808 6.85248 14.808 6.28296 14.2385C5.71345 13.6689 5.71345 12.7456 6.28296 12.1761L12.6175 5.84157"
      stroke="#98A2B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ChatIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 10C18 14.4183 14.4183 18 10 18C8.93587 18 7.92027 17.7922 6.99155 17.4151C6.8138 17.3429 6.72493 17.3068 6.65308 17.2907C6.58281 17.2749 6.5308 17.2692 6.45878 17.2692C6.38516 17.2691 6.30497 17.2825 6.14458 17.3092L2.98201 17.8363C2.65083 17.8915 2.48524 17.9191 2.3655 17.8678C2.2607 17.8228 2.17718 17.7393 2.13223 17.6345C2.08087 17.5148 2.10847 17.3492 2.16367 17.018L2.69076 13.8554C2.71749 13.695 2.73086 13.6148 2.73085 13.5412C2.73084 13.4692 2.72508 13.4172 2.70933 13.3469C2.69323 13.2751 2.65713 13.1862 2.58494 13.0085C2.20777 12.0797 2 11.0641 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
      fill="#828282"
    />
    <path
      d="M18 10C18 14.4183 14.4183 18 10 18C8.93587 18 7.92027 17.7922 6.99155 17.4151C6.8138 17.3429 6.72493 17.3068 6.65308 17.2907C6.58281 17.2749 6.5308 17.2692 6.45878 17.2692C6.38516 17.2691 6.30497 17.2825 6.14458 17.3092L2.98201 17.8363C2.65083 17.8915 2.48524 17.9191 2.3655 17.8678C2.2607 17.8228 2.17718 17.7393 2.13223 17.6345C2.08087 17.5148 2.10847 17.3492 2.16367 17.018L2.69076 13.8554C2.71749 13.695 2.73086 13.6148 2.73085 13.5412C2.73084 13.4692 2.72508 13.4172 2.70933 13.3469C2.69323 13.2751 2.65713 13.1862 2.58494 13.0085C2.20777 12.0797 2 11.0641 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
      stroke="white"
    />
    <circle
      cx="6"
      cy="10"
      r="0.9"
      fill="#D9D9D9"
      stroke="#828282"
      stroke-width="0.2"
    />
    <circle
      cx="10"
      cy="10"
      r="0.9"
      fill="#D9D9D9"
      stroke="#828282"
      stroke-width="0.2"
    />
    <circle
      cx="14"
      cy="10"
      r="0.9"
      fill="#D9D9D9"
      stroke="#828282"
      stroke-width="0.2"
    />
  </svg>
);
