import { useMutation } from "react-query";
import amadeusApi, {
  AMADEUS_CLIENT_ID,
  AMADEUS_CLIENT_SECRET,
} from "../utils/amadeusApi";

export const tokenUpdateAmadeus = () =>
  amadeusApi.post(
    "v1/security/oauth2/token",
    `grant_type=client_credentials&client_id=${AMADEUS_CLIENT_ID}&client_secret=${AMADEUS_CLIENT_SECRET}`
  );

const useAmadeus = () => {
  const authorize = useMutation(tokenUpdateAmadeus);

  const searchFlights = useMutation((data) =>
    amadeusApi.post("v2/shopping/flight-offers", data)
  );

  const getCityIatas = (query) =>
    amadeusApi.get("v1/reference-data/locations/cities", {
      params: {
        keyword: query,
        max: 12,
      },
    });

  return { authorize, searchFlights, getCityIatas };
};

export default useAmadeus;
