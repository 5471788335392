import TSelect from "../../../../../../../components/Select";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import styles from "./style.module.scss";

import { flightTypes, iataCodes } from "../../../../../../../consts/flights";
import { Button } from "@mui/material";
import { promiseCity } from "../../../../../../../utils/getCities";
import { useEffect, useState } from "react";
import { getYesterdayDate } from "../../../../../../../utils/date";

export const OneWayFlight = ({ params, changeFlightParams }) => {
  const data = params[0];

  const [selectedDay, setSelectedDay] = useState(null);

  const onChangeSelect = (e, index, key) => {
    changeFlightParams(index, key, e);
  };

  const onChangeDate = (e, index, key) => {
    changeFlightParams(index, key, { ...e, month: e.month - 1 });
  };

  useEffect(() => {
    if (!data.departure_at) return;

    setSelectedDay({
      ...data.departure_at,
      month: data.departure_at.month + 1,
    });
  }, [data.departure_at]);

  return (
    <div className={styles.one_way}>
      <div className={styles.wrapper}>
        <TSelect
          placeholder="From"
          value={data.departure_iataCode}
          loadOptions={promiseCity}
          defaultOptions={iataCodes}
          components={{ IndicatorSeparator: null }}
          onChange={(e) => onChangeSelect(e, 0, "departure_iataCode")}
          getOptionLabel={(opt) => opt.key || opt.label || opt.value}
          getOptionValue={(opt) => opt.value}
          styles={{
            control: () => ({
              backgroundColor: "#fff",
              minWidth: "280px",
            }),
          }}
        />
      </div>

      <div className={styles.wrapper}>
        <TSelect
          placeholder="To"
          value={data.arrival_iataCode}
          loadOptions={promiseCity}
          defaultOptions={iataCodes}
          components={{ IndicatorSeparator: null }}
          onChange={(e) => onChangeSelect(e, 0, "arrival_iataCode")}
          getOptionLabel={(opt) => opt.key || opt.label || opt.value}
          getOptionValue={(opt) => opt.value}
          styles={{
            control: () => ({
              backgroundColor: "#fff",
              minWidth: "280px",
            }),
          }}
        />
      </div>

      <div className={styles.wrapper}>
        <DatePicker
          value={selectedDay}
          onChange={(e) => onChangeDate(e, 0, "departure_at")}
          inputPlaceholder="Departure date"
          minimumDate={getYesterdayDate()}
          colorPrimary="var(--primary-color)"
          shouldHighlightWeekends
        />
      </div>
    </div>
  );
};

export const RoundTripFlight = ({ params, changeFlightParams }) => {
  const data = params[0];
  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);

  const onChangeSelect = (e, index, key) => {
    changeFlightParams(index, key, e);
  };

  const onChangeDate = (e, index, key) => {
    changeFlightParams(index, key, { ...e, month: e.month - 1 });
  };

  useEffect(() => {
    if (!data.departure_at) return;

    setDepartureDate({
      ...data.departure_at,
      month: data.departure_at.month + 1,
    });
  }, [data.departure_at]);

  useEffect(() => {
    if (!data.arrival_at) return;

    setArrivalDate({
      ...data.arrival_at,
      month: data.arrival_at.month + 1,
    });
  }, [data.arrival_at]);

  return (
    <div className={styles.round_trip}>
      <div className={styles.wrapper}>
        <TSelect
          placeholder="From"
          value={data.departure_iataCode}
          loadOptions={promiseCity}
          components={{ IndicatorSeparator: null }}
          onChange={(e) => onChangeSelect(e, 0, "departure_iataCode")}
          getOptionLabel={(opt) => opt.key || opt.label || opt.value}
          getOptionValue={(opt) => opt.value}
          styles={{
            control: () => ({
              backgroundColor: "#fff",
              minWidth: "230px",
            }),
          }}
        />
      </div>

      <div className={styles.wrapper}>
        <TSelect
          placeholder="To"
          value={data.arrival_iataCode}
          loadOptions={promiseCity}
          components={{ IndicatorSeparator: null }}
          onChange={(e) => onChangeSelect(e, 0, "arrival_iataCode")}
          getOptionLabel={(opt) => opt.key || opt.label || opt.value}
          getOptionValue={(opt) => opt.value}
          styles={{
            control: () => ({
              backgroundColor: "#fff",
              minWidth: "230px",
            }),
          }}
        />
      </div>

      <div className={styles.wrapper}>
        <DatePicker
          value={departureDate}
          onChange={(e) => onChangeDate(e, 0, "departure_at")}
          inputPlaceholder="Departure date"
          minimumDate={getYesterdayDate()}
          colorPrimary="var(--primary-color)"
          shouldHighlightWeekends
        />
      </div>

      <div className={styles.wrapper}>
        <DatePicker
          value={arrivalDate}
          onChange={(e) => onChangeDate(e, 0, "arrival_at")}
          inputPlaceholder="Return date"
          minimumDate={departureDate}
          colorPrimary="var(--primary-color)"
          shouldHighlightWeekends
        />
      </div>
    </div>
  );
};

export const DifficultRouteFlight = ({
  params,
  changeFlightParams,
  addRouteParam,
  removeRouteParam,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);

  const onChangeSelect = (e, index, key) => {
    changeFlightParams(index, key, e);
  };

  const onChangeDate = (e, index, key) => {
    changeFlightParams(index, key, { ...e, month: e.month - 1 });
  };
  useEffect(() => {
    let dates = [];

    params.forEach((route, r) => {
      dates.push(
        !!route.departure_at
          ? {
              ...route.departure_at,
              month: route.departure_at.month + 1,
            }
          : null
      );
    });

    setSelectedDates(dates);
  }, [JSON.stringify(params)]);

  return (
    <div className={styles.difficult_route}>
      {params.map((route, r) => (
        <div className={styles.routes} key={r}>
          <div className={styles.wrapper}>
            <TSelect
              placeholder="From"
              value={route.departure_iataCode}
              loadOptions={promiseCity}
              components={{ IndicatorSeparator: null }}
              onChange={(e) => onChangeSelect(e, r, "departure_iataCode")}
              getOptionLabel={(opt) => opt.key || opt.label || opt.value}
              getOptionValue={(opt) => opt.value}
              styles={{
                control: () => ({
                  backgroundColor: "#fff",
                  minWidth: "280px",
                }),
              }}
            />
          </div>

          <div className={styles.wrapper}>
            <TSelect
              placeholder="To"
              components={{ IndicatorSeparator: null }}
              value={route.arrival_iataCode}
              loadOptions={promiseCity}
              onChange={(e) => onChangeSelect(e, r, "arrival_iataCode")}
              getOptionLabel={(opt) => opt.key || opt.label || opt.value}
              getOptionValue={(opt) => opt.value}
              styles={{
                control: () => ({
                  backgroundColor: "#fff",
                  minWidth: "280px",
                }),
              }}
            />
          </div>

          <div className={styles.wrapper}>
            <DatePicker
              value={selectedDates[r]}
              onChange={(e) => onChangeDate(e, r, "departure_at")}
              inputPlaceholder="Departure date"
              minimumDate={
                r === 0 ? getYesterdayDate() : selectedDates[r - 1] || null
              }
              colorPrimary="var(--primary-color)"
              shouldHighlightWeekends
            />
            {/* <TDatePicker
              label="Departure date"
              value={route.departure_at}
              style={{
                backgroundColor: "#fff"
              }}
              onChange={(e) => onChangeDate(e, r, "departure_at")}
              variant="contained"
              disablePast={true}
            /> */}
          </div>

          <button
            className={styles.remove_btn}
            onClick={() => (r !== 0 ? removeRouteParam(r) : {})}
          >
            <DeleteOutlinedIcon />
          </button>
        </div>
      ))}

      <Button
        variant="outlinedSecondary"
        style={{ width: 200 }}
        onClick={addRouteParam}
      >
        <AddOutlinedIcon /> Add Trip
      </Button>
    </div>
  );
};

export const FlightTypeRenders = {
  [flightTypes.ONE_WAY]: OneWayFlight,
  [flightTypes.ROUND_TRIP]: RoundTripFlight,
  [flightTypes.DIFFICULT_ROUTE]: DifficultRouteFlight,
};
