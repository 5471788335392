import TTable from "../../components/Table";
import Wrapper from "../../components/Wrapper";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useUsers from "../../hooks/api/useUsersAPI";
import ActionRenderer from "./ActionRenderer";

import { useNavigate } from "react-router-dom";
import { userRoles } from "../../consts/roles";
import { Box, Button } from "@mui/material";
import usePagination from "../../hooks/usePagination";
import { useSelector } from "react-redux";

const Users = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate } =
    usePagination("/users");

  const { user } = useSelector((store) => store.auth);
  const { data, refetch, isLoading } = useUsers({
    enabled: true,
    page: page - 1,
    amount
  });

  const _columns = [
    {
      label: "#",
      key: "key",
      attributes: {
        align: "left",
        width: 30
      }
    },
    {
      label: "Fullname",
      key: "fullname",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Email",
      key: "email",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Role",
      key: "role",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50
      },
      render: ({ data }) => (
        <ActionRenderer data={data} user={user} refetch={refetch} />
      )
    }
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.users?.count}
          data={data?.users?.rows?.map((user, u) => ({
            ...user,
            key: u + 1,
            fullname: [user.first_name, user.last_name].join(" "),
            role: userRoles.find((role) => user.role === role.value)?.label
          }))}
        >
          <Box display="flex" width="100%">
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate("create")}
            >
              <PersonAddIcon
                style={{ marginRight: "5px", color: "var(--primary-color)" }}
              />
              New user
            </Button>
          </Box>
        </TTable>
      </div>
    </Wrapper>
  );
};

export default Users;
