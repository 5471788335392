import ActionRenderer from "./ActionRenderer";
import usePagination from "../../../hooks/usePagination";

import Wrapper from "../../../components/Wrapper";
import TTable from "../../../components/Table";
import AddIcon from "@mui/icons-material/Add";

import { createSearchParams, useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import TSelect from "../../../components/Select";
import useContentAPI from "../../../hooks/api/useContentAPI";
import PublishBadge from "../../../components/PublishBadge";
import useCategoryAPI from "../../../hooks/api/useCategoryAPI";
import { makeCDN } from "../../../utils/uploadImage";

const Contents = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate, filters } =
    usePagination("/website/contents");

  const { data: categories } = useCategoryAPI({
    enabled: true,
    page: 0,
    amount: 50,
  });

  const { data, isLoading, refetch } = useContentAPI({
    enabled: true,
    page: page - 1,
    amount,
    categoryId: filters.categoryId || "",
  });

  const _columns = [
    {
      label: "#",
      key: "key",
      attributes: {
        align: "left",
        width: 30,
      },
    },
    {
      label: "Title",
      key: "title",
      attributes: {
        align: "left",
        width: 100,
      },
    },
    {
      label: "Category",
      key: "category",
      attributes: {
        align: "left",
      },
      render: ({ data }) => <span>{data?.category?.name}</span>,
    },
    {
      label: "Direction",
      key: "direction",
      attributes: {
        align: "left",
      },
      render: ({ data }) => (
        <span>
          {data.direction?.airport_name} ({data.direction?.iata_code})
        </span>
      ),
    },
    {
      label: "Price",
      key: "price",
      attributes: {
        align: "left",
      },
      render: ({ data }) => (
        <>
          {data.discount_price ? (
            <div>
              <span
                style={{
                  textDecoration: "line-through",
                }}
              >
                {data.price}
              </span>
              &nbsp;
              <span>{data.discount_price}</span>
            </div>
          ) : (
            <span>{data.price}</span>
          )}
        </>
      ),
    },
    {
      label: "Status",
      key: "is_published",
      attributes: {
        align: "left",
      },
      render: ({ data }) => <PublishBadge isPublished={data.is_published} />,
    },
    {
      label: "Image",
      key: "img",
      attributes: {
        align: "left",
      },
      render: ({ data }) => (
        <img
          src={makeCDN(data.image)}
          alt="categorry image"
          width={100}
          style={{ objectFit: "cover" }}
        />
      ),
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50,
      },
      render: ({ data }) => <ActionRenderer data={data} refetch={refetch} />,
    },
  ];

  const handleFilterByCategory = (e) => {
    navigate({
      pathname: "/website/contents",
      search: createSearchParams({
        page: page,
        amount: amount,
        categoryId: e?.[0]?.id,
      }).toString(),
    });
  };

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.contents?.count}
          data={data?.contents?.rows?.map((content, u) => ({
            ...content,
            key: u + 1,
          }))}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate("create")}
            >
              <AddIcon
                style={{ marginRight: "5px", color: "var(--primary-color)" }}
              />
              New Content
            </Button>
          </Box>

          <TSelect
            isMulti={true}
            components={{
              DropdownIndicator: CategoryOutlinedIcon,
              IndicatorSeparator: null,
            }}
            // value={filterAgents}
            // loadOptions={getAgentsByName}
            defaultOptions={categories?.categories?.rows}
            getOptionLabel={(opt) => opt.name}
            onChange={handleFilterByCategory}
            getOptionValue={(opt) => opt.id}
            placeholder="Select categories"
          />
        </TTable>
      </div>
    </Wrapper>
  );
};

export default Contents;
