import classNames from "classnames";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import AirlinesIcon from "@mui/icons-material/Airlines";
const items = [
  {
    title: "Airports",
    path: "airports",
    icon: ConnectingAirportsIcon,
  },
  {
    title: "Airlines",
    path: "airlines",
    icon: AirlinesIcon,
  },
];

const StaticData = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.static_data}>
      <div className={styles.sidebar}>
        <ul className={styles.list}>
          {items.map((item) => (
            <li
              key={item.path}
              className={classNames(styles.item, {
                [styles.active]: pathname.includes(item.path),
              })}
              onClick={() => navigate(item.path)}
            >
              <item.icon /> {item.title}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default StaticData;
