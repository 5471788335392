import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import {
  AttachmentIcon,
  AudioTrashIcon,
  ChatSendIcon,
  MicroPhoneIcon,
  RecordingIcon,
} from "../../../../assets/icons";
import useChats from "../../../../hooks/api/useChatsAPI";
import CustomAudioPlayer from "../AudioMessage";
import EmojiPicker from "../EmojiPicker";
import styles from "./style.module.scss";
import useTool from "./useTool";
import toast from "react-hot-toast";

const ChatTools = ({
  onUploadOpen,
  isUpload = false,
  chatRefetch,
  messageRefetch,
  handleScrollDown,
  reply,
  setReply,
  setMessage,
  message,
  isLoading,
  setIsLoading,
  onMultipleFileAdd,
}) => {
  const { id, hashId } = useParams();
  const audio = new Audio("/sound/bubbleSound.mp3");

  const {
    recording,
    audioURL,
    timer,
    startRecording,
    stopRecording,
    handleDeleteAudio,
    formatTime,
    audioMessage,
    clearAudio,
  } = useTool({ setLoader: setIsLoading });

  const isRecording = !audioURL && recording;
  const isUrlValid = audioURL && !recording;
  const invalidRecord = !audioURL && !recording;

  const clickHandler = () => {
    const handler = document.getElementById("inputFile");
    handler.click();
  };

  const handleEmojiSelect = (emoji) => {
    setMessage(message + emoji.native);
  };

  const { sendMessage, sendMessageLoading } = useChats();

  const handleSendMessage = () => {
    const payload = {
      id: id,
      hashId: hashId,
      file_urls: audioMessage ? [audioMessage] : undefined,
      message: message,
      audio_duration: timer || undefined,
      reply_to_message_id: reply?.ID,
    };
    sendMessage(payload, {
      onSuccess: () => {
        audio.play();
        messageRefetch();
        chatRefetch();
        clearAudio();
        setMessage("");
        setReply({});
        setTimeout(() => {
          handleScrollDown();
        }, 1500);
      },
      onError: (err) => {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error("Error in sending Message!");
        }
      },
    });
  };

  return (
    <>
      {invalidRecord && (
        <TextField
          variant="outlined"
          placeholder="Message"
          key={id}
          autoFocus
          value={message}
          disabled={sendMessageLoading}
          multiline
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          style={{
            height: "100%",
            maxHeight: "300px",
            overflow: "auto",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton
                  disabled={sendMessageLoading}
                  onClick={clickHandler}
                >
                  <input
                    id="inputFile"
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      onMultipleFileAdd(Array.from(e.target.files));
                      e.target.value = "";
                    }}
                  />
                  <AttachmentIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment disablePointerEvents={sendMessageLoading}>
                <EmojiPicker onSelect={handleEmojiSelect} />
                {!isUpload && (
                  <IconButton
                    disabled={sendMessageLoading}
                    onClick={recording ? stopRecording : startRecording}
                    className={recording ? styles.recording : ""}
                  >
                    <MicroPhoneIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          className={styles.messageInput}
        />
      )}
      {isRecording && (
        <Box className={styles.recording_box}>
          <div className={styles.timer}>
            <span className={styles.dot} />
            <p className={styles.timer_text}> {formatTime(timer)}</p>
          </div>
          <p className={styles.cancel} onClick={handleDeleteAudio}>
            Cancel
          </p>

          <Box className={styles.recording} onClick={stopRecording}>
            <RecordingIcon />
          </Box>
        </Box>
      )}
      {isUrlValid && (
        <Box className={styles.recording_box}>
          <Box className={styles.recording_trash} onClick={handleDeleteAudio}>
            <AudioTrashIcon />
          </Box>

          <CustomAudioPlayer url={audioURL} timer={timer} />
        </Box>
      )}
      {!isUpload && (
        <Button
          disabled={sendMessageLoading || isLoading}
          onClick={handleSendMessage}
          className={styles.sendButton}
        >
          {sendMessageLoading || isLoading ? (
            <CircularProgress
              style={{
                color: "#fff",
              }}
              size={16}
            />
          ) : (
            <ChatSendIcon />
          )}
        </Button>
      )}
    </>
  );
};

export default ChatTools;
