import usePagination from "../../../../hooks/usePagination";
import TTable from "../../../../components/Table";
import Wrapper from "../../../../components/Wrapper";
import { Box, TextField } from "@mui/material";
import ActionRenderer from "./ActionRenderer";
import styles from "./style.module.scss";
import SessionFilterMenu from "./Filter";
import { useState } from "react";
import SessionCreateModal from "./Create";
import useUsers from "../../../../hooks/api/useUsersAPI";
import moment from "moment";
import useSessionAPI from "../../../../hooks/api/useSessionAPI";
import SessionOtpModal from "./OTP";
import SessionPasswordModal from "./Password";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { FSelect } from "../../../../components/Select";
import { statusOptions } from "../../mock/chat";
import classNames from "classnames";

const SessionTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [otp, setOtp] = useState("");
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const openNotePad = Boolean(anchorEl);
  const [debouncedSearch] = useDebounce(search, 500);

  const { page, amount, handleAmount, handlePaginate } =
    usePagination("/session");

  const { initSession, activeUsers, refetchActiveUsers } = useSessionAPI();

  const handleSessionCreate = (data) => {
    toast.promise(initSession.mutateAsync({ username: data.email }), {
      loading: "Init...",
      success: (res) => {
        refetchActiveUsers();
        return "Success";
      },
      error: "Error on creating session!",
    });
  };

  const onOpenCreate = (data) => {
    handleSessionCreate(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, refetch, isLoading } = useUsers({
    enabled: true,
    page: page - 1,
    amount,
    search: debouncedSearch,
    status: status,
  });

  const isUserActive = (username) => {
    return activeUsers?.payload?.users?.some(
      (user) => user.username === username
    );
  };

  const _columns = [
    {
      label: "#",
      key: "index",
      attributes: {
        align: "left",
        width: 30,
      },
    },
    {
      label: "Agents",
      key: "key",
      attributes: {
        align: "left",
      },
      render: ({ data }) => `${data.last_name} ${data?.first_name}`,
    },
    {
      label: "Created date",
      key: "value",
      attributes: {
        align: "left",
      },
      render: ({ data }) => moment(data.createdAt).format("DD/MM/YY"),
    },
    {
      label: "Status agents",
      render: ({ data }) => {
        const isActive = isUserActive(data?.email);
        return (
          <Box className={styles.status}>
            <p
              className={classNames(styles.status_box, {
                [styles.active]: isActive,
              })}
            >
              {isActive ? "Active" : "Inactive"}
            </p>
          </Box>
        );
      },
      attributes: {
        align: "left",
      },
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50,
      },
      render: ({ data }) => (
        <ActionRenderer
          onOpenCreate={onOpenCreate}
          data={data}
          refetch={refetch}
          activeUsers={activeUsers}
          refetchActiveUsers={refetchActiveUsers}
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.users?.count}
          data={data?.users?.rows?.map((user, u) => ({
            ...user,
            index: u + 1,
          }))}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <p className={styles.table_title}> Sessions</p>
            <Box className={styles.filters}>
              <TextField
                placeholder="Search by agent"
                style={{
                  width: 250,
                  background: "#fff",
                }}
                className={styles.search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FSelect
                components={{
                  IndicatorSeparator: null,
                }}
                options={statusOptions}
                placeholder="Select status"
                onChange={(val) => setStatus(val?.value || "")}
                isClearable
              />
            </Box>
          </Box>
        </TTable>
      </div>
      <SessionFilterMenu
        open={openNotePad}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
      <SessionCreateModal
        userId={userId}
        open={open}
        setOpen={setOpen}
        setOtpOpen={setOtpOpen}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
      />
      <SessionOtpModal
        open={otpOpen}
        userId={userId}
        setOpen={setOtpOpen}
        phoneNumber={phoneNumber}
        setPasswordOpen={setPasswordOpen}
        setOtp={setOtp}
        otp={otp}
      />
      <SessionPasswordModal
        open={passwordOpen}
        setOpen={setPasswordOpen}
        userId={userId}
        phoneNumber={phoneNumber}
        otp={otp}
        refetch={refetch}
      />
    </Wrapper>
  );
};

export default SessionTable;
