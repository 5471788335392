import html2canvas from "html2canvas";
import toast from "react-hot-toast";
import useUploadImage from "./api/useUploadImage";
import useSearchFlightAPI from "./api/useSearchFlightAPI";
const airports = require("airport-codes/airports.json");

const useLead = ({ element, currentTicket, customer_name, customer_email }) => {
  const { uploadMutation, getImage } = useUploadImage();
  const { createSendFlightToEmail } = useSearchFlightAPI();

  const city_from =
    getCityOptions(currentTicket?.itineraries?.[0]?.segments)?.from_city || "";
  const city_to =
    getCityOptions(currentTicket?.itineraries?.[0]?.segments)?.to_city || "";

  function getCityOptions(segments) {
    if (segments) {
      const departureAirport = airports?.find(
        (item) => item?.iata === segments[0]?.departure?.iataCode
      )?.city;
      const arrivalAirport = airports?.find(
        (item) =>
          item?.iata === segments[segments.length - 1]?.arrival?.iataCode
      )?.city;

      return { from_city: departureAirport, to_city: arrivalAirport };
    }
  }

  const copyToClipboard = (text) => {
    toast.promise(navigator.clipboard.writeText(text), {
      loading: "Loading...",
      success: () => {
        toast.remove();
        return `Link copied to clipboard`;
      },
      error: () => {
        toast.remove();
        return "Failed to copy link!";
      },
    });
  };

  const handleScreenshot = (type) => {
    if (type === "email") {
      if (!customer_email || !customer_name) {
        toast.error("Please enter your name and email");
      } else {
        toast.loading("Sending...");
        htmlToCanvas(type);
      }
    } else {
      toast.loading("Sending...");
      htmlToCanvas(type);
    }
  };

  const htmlToCanvas = (type) => {
    html2canvas(element, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
      scale: 2.5,
    }).then((canvas) => {
      canvas.toBlob(
        (blob) => {
          const fd = new FormData();
          const file = new File([blob], "screenshot.png", {
            type: "image/png",
          });
          fd.append("file", file);
          onFileAdd(file, type);
        },
        "image/png",
        2.0
      );
    });
  };

  const onFileAdd = async (binary, type) => {
    uploadMutation.mutate(binary, {
      onSuccess: async (res) => {
        const resp = await getImage(res.$id);
        if (type === "email") {
          sendToEmail(resp.href);
        } else {
          copyToClipboard(resp.href);
        }
      },
    });
  };

  const sendToEmail = (image) => {
    toast.remove();
    const data = {
      image: image,
      customer_name: customer_name,
      email: customer_email,
      from_city: city_from,
      to_city: city_to,
      subject: `Flight option: ${city_from} - ${city_to}`,
    };
    toast.promise(createSendFlightToEmail.mutateAsync(data), {
      loading: "Sending...",
      success: () => {
        toast.remove();
        return `Flight Option is sent to ${customer_email}`;
      },
      error: () => {
        toast.remove();
        return "Error on creating application!";
      },
    });
  };

  return {
    getCityOptions,
    copyToClipboard,
    handleScreenshot,
  };
};

export default useLead;
