export const siteTypeKeys = {
  MAIN: "main",
  HAWAII: "hawaii",
  UZBEKISTAN: "uzbekistan"
};

export const publishStatusKeys = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED"
};

export const publishStatuses = [
  {
    key: publishStatusKeys.PUBLISHED,
    value: "Published"
  },
  {
    key: publishStatusKeys.UNPUBLISHED,
    value: "Unpublished"
  }
];

export const siteTypes = [
  {
    key: siteTypeKeys.MAIN,
    value: "Main"
  },
  {
    key: siteTypeKeys.HAWAII,
    value: "Hawaii"
  },
  {
    key: siteTypeKeys.UZBEKISTAN,
    value: "Uzbekistan"
  }
];
