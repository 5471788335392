import { Client, Storage } from "appwrite";
import { useMutation } from "react-query";
import uuid from "react-uuid";

const useUploadImage = () => {
  const uploadMutation = useMutation((file) => uploadImage(file));

  const getImage = async (id) => {
    const client = new Client();

    client
      .setEndpoint("https://cloud.appwrite.io/v1")
      .setProject("657a041f99322f5048cc");

    const storage = new Storage(client);

    const promise = storage.getFilePreview("tripoasia-2023", id);

    return promise;
  };

  const uploadImage = async (file) => {
    const client = new Client();
    const id = uuid();

    client
      .setEndpoint("https://cloud.appwrite.io/v1")
      .setProject("657a041f99322f5048cc");

    const storage = new Storage(client);

    const promise = storage.createFile("tripoasia-2023", id, file);

    return promise;
  };
  return {
    uploadMutation,
    getImage
  };
};

export default useUploadImage;
