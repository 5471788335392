export const userRolesForAdmin = [
  {
    label: "Agent",
    value: "7e5916de-4ef9-11ee-be56-0242ac120002"
  },
  {
    label: "Editor",
    value: "0de38c78-a834-4da7-8d14-743951063028"
  }
];

export const userRoles = [
  {
    label: "Supervisor",
    value: "ccffdf1b-eba1-4eb8-9047-1457caeef2d1"
  },
  {
    label: "Admin",
    value: "8dcc49a6-4ef4-11ee-be56-0242ac120002"
  },
  ...userRolesForAdmin
];
