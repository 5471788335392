import Select from "react-select";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { selectStyles } from "./styles";

const FormReactSelect = ({
  control,
  name,
  label,
  width = "100%",
  options = [],
  disabledHelperText,
  placeholder,
  required = false,
  rules = {},
  isClearable,
  isSearchable,
  loadOptions,
  search = () => {},
  customOnChange = () => {},
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: required ? "This is required field" : false,
        ...rules,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl style={{ width, height: "100%", background: "100%" }}>
          <InputLabel size="small">{label}</InputLabel>

          <Select
            value={options?.find((el) => el?.value === value)}
            error={error}
            label={label}
            inputProps={{ placeholder }}
            isClearable={isClearable}
            isSearchable={isSearchable}
            fullWidth={true}
            components={{
              IndicatorSeparator: false,
            }}
            onChange={(e) => {
              onChange(e?.value);
              customOnChange(e);
            }}
            options={options}
            loadOptions={loadOptions}
            placeholder={placeholder}
            defaultOptions
            {...props}
            styles={{
              ...selectStyles,
              ...props.styles,
              control: () => ({
                ...selectStyles.control(),
                ...props?.styles?.control(),
                height: "100%",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#A7A7A7",
              }),
            }}
          />

          {!disabledHelperText && error?.message ? (
            <FormHelperText
              error
              style={{
                margin: 0,
              }}
            >
              {error?.message ?? " "}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    ></Controller>
  );
};

export default FormReactSelect;
