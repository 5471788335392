import { Avatar, Box, Skeleton, Stack } from "@mui/material";
import styles from "./style.module.scss";

export const ChatSkeleton = () => {
  return (
    <Box padding={"0 8px"}>
      <Skeleton variant="text" width={150} height={20} />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton variant="text" width={150} height={20} />{" "}
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
      <Skeleton variant="text" width={150} height={20} />{" "}
      <Skeleton
        className={styles.skeleton}
        variant="rectangular"
        width={305}
        height={95}
      />
    </Box>
  );
};

export const FieldSkeleton = () => {
  return (
    <Box className={styles.field_skeleton}>
      <Box className={styles.header}>
        <Box display={"flex"} alignItems={"center"} columnGap={"12px"}>
          <Skeleton variant="circular">
            <Avatar sx={{ width: 56, height: 56 }} />
          </Skeleton>
          <Stack>
            <Skeleton variant="text" width={300} height={30} />
            <Skeleton variant="text" width={200} height={25} />
          </Stack>
        </Box>
        <Box className={styles.flex}>
          <Skeleton width={48} height={48} />
          <Skeleton width={48} height={48} />
        </Box>
      </Box>

      <Skeleton
        variant="text"
        width={390}
        height={50}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={440} height={90} />
      <Skeleton
        variant="text"
        width={340}
        height={60}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={280} height={55} />
      <Skeleton
        variant="text"
        width={340}
        height={60}
        style={{
          marginLeft: "auto",
        }}
      />
      <Skeleton variant="text" width={340} height={95} />
      <Skeleton
        variant="text"
        width={340}
        height={75}
        style={{
          marginLeft: "auto",
        }}
      />
      <Box className={styles.flex}>
        <Skeleton variant="text" width={"100%"} height={64} />
        <Skeleton variant="text" width={64} height={64} />
      </Box>
    </Box>
  );
};
