export const selectStyles = {
  control: () => ({
    // minWidth: 300,
    backgroundColor: "#fff",
    height: "44px",
    padding: "0 10px 0 0",
    borderRadius: "8px",
    // border: "1px",
    justifyContent: "space-between",
    display: "flex",
    border: "1px solid #E5E9EB",
    // backgroundColor: "rgba(238, 242, 244, 1)",
    "font-size": "16px",
    "font-weight": "500",
    minHeight: "44px",
  }),
  value: () => ({
    "font-size": "16px",
    "font-weight": "500",
  }),
  options: (styles) => {},
  multValue: (styles) => {},
  // container: (styles) => ({
  //   // width: "100%"
  // }),
  menu: (styles) => ({
    ...styles,
    zIndex: 999999999,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "8px",
  }),
  placeholder: () => ({
    "font-size": "16px",
    "font-weight": "500",
    left: 12,
    position: "absolute",
    color: "#A7A7A7 !important",
  }),
};
