import moment from "moment";

const differenceInDays = (date1, date2) => {
  return moment(date2).diff(moment(date1), "days");
};

const filterByOneItemWhereIdEqual = (data) => {
  let uniqueItems = {};
  let filteredArray = [];

  // Iterate over each object in the array
  data.forEach((obj) => {
    // Use object property (e.g., name) as a key to identify unique items
    let key = obj.external_order_id;

    // If key doesn't exist in uniqueItems, add the object to filteredArray
    if (!uniqueItems[key]) {
      uniqueItems[key] = true;
      filteredArray.push(obj);
    }
  });

  return filteredArray;
};

export const groupOrdersByDate = (data, startDate, endDate) => {
  if (!data || data.length === 0) return [];

  const filteredData = data.filter((order) =>
    order.flights.some((flight) => {
      const flightInfo = JSON.parse(flight.info || "{}");
      return flightInfo.itineraries.some((itin) =>
        itin.segments.some((seg) =>
          moment(seg.departure.at).isBetween(
            moment(startDate),
            moment(endDate),
            "[]",
            false
          )
        )
      );
    })
  );

  const today = moment(new Date()).format("DD MMM yyyy");
  const tomorrow = moment(new Date()).add(1, "day").format("DD MMM yyyy");

  const sortedData = filteredData.sort((a, b) => {
    const dateA = new Date(getDateFromFlight(a, startDate, endDate));
    const dateB = new Date(getDateFromFlight(b, startDate, endDate));
    return dateA - dateB;
  });

  const groups = sortedData.reduce((groups, order) => {
    order.flights.forEach((flight) => {
      const flightInfo = JSON.parse(flight.info || "{}");

      flightInfo.itineraries.forEach((itin) => {
        itin.segments.forEach((seg) => {
          const departureDate = moment(seg.departure.at).format("YYYY-MM-DD");

          if (
            moment(seg.departure.at).isBetween(
              moment(startDate),
              moment(endDate),
              "[]",
              false
            )
          ) {
            if (!groups[departureDate]) {
              groups[departureDate] = [];
            }
            groups[departureDate].push({
              ...order,
              segment: seg,
            });
          }
        });
      });
    });
    return groups;
  }, {});

  const groupArrays = Object.keys(groups)
    .map((date) => {
      const _date = moment(date).format("DD MMM yyyy");
      const diff = differenceInDays(today, _date);

      const lastMutatedDate =
        _date === today
          ? "Today"
          : _date === tomorrow
          ? "Tomorrow"
          : diff < 7 && moment(_date).isAfter(today)
          ? moment(date).format("dddd")
          : _date;
      return {
        realDate: date,
        date: lastMutatedDate,
        items: filterByOneItemWhereIdEqual(groups[date]),
      };
    })
    .sort((a, b) => new Date(a.realDate) - new Date(b.realDate));
  return groupArrays;
};

export const getDateFromFlight = (order, startDate, endDate) => {
  let foundDate;
  const flights = order.flights;

  flights.forEach((flight) => {
    const info = JSON.parse(flight.info || "{}");

    const mutatedEndDate =
      moment(startDate).format("YYYY-MM-DD") ===
      moment(endDate).format("YYYY-MM-DD")
        ? moment(endDate).add(1, "day")
        : moment(endDate);

    info?.itineraries?.forEach((itin) => {
      itin.segments.forEach((seg) => {
        if (
          !foundDate &&
          moment(seg.departure.at).isBetween(
            moment(startDate),
            mutatedEndDate,
            "[]",
            false
          )
        )
          foundDate = seg.departure.at;
      });
    });
  });

  return foundDate;
};
