import { useEffect, useState } from "react";
import { request } from "../utils/api";
import { toast } from "react-hot-toast";

const _passenger = {
  first_name: "",
  last_name: "",
  second_name: "",
  passport_serie: "",
  passport_number: "",
  phone_number: "",
  email: "",
  gender: "",
  date_of_birth: ""
};

const usePassenger = ({ passengers }) => {
  const [initPassengers, setInitPassengers] = useState([{ ..._passenger }]);

  useEffect(() => {
    if (!passengers || passengers?.length === 0) return;

    setInitPassengers(passengers);
  }, [passengers]);

  const addPassenger = (data) =>
    setInitPassengers((prev) => [...prev, { ..._passenger }]);

  const removePassenger = (index) =>
    setInitPassengers((prev) => prev.filter((_, idx) => idx !== index));

  const fetchUserByPassportNumber = (val) =>
    request.get("customer/search?", {
      params: {
        search: val
      }
    });

  const changePassengerDetail = async (index, key, value) => {
    const passngrs = [...initPassengers];

    let _psngr = { ...passngrs[index] };

    // const isPassportRelatedField =
    //   key === "passport_serie" || key === "passport_number";

    // const isValidPassportInfo =
    //   _psngr.passport_serie.length === 2 && value.length === 7;

    // if (isPassportRelatedField && isValidPassportInfo) {
    //   const toastId = toast.loading("Searching...");
    //   const psngr = await fetchUserByPassportNumber(
    //     [_psngr.passport_serie.toUpperCase(), _psngr.passport_number].join("")
    //   );

    //   if (!!psngr && psngr.customers?.length > 0)
    //     _psngr = {
    //       id: psngr.customers[0].id,
    //       first_name: psngr.customers[0].first_name,
    //       last_name: psngr.customers[0].last_name,
    //       second_name: psngr.customers[0].surname,
    //       passport_serie: psngr.customers[0].passport_number.slice(0, 2),
    //       passport_number: psngr.customers[0].passport_number.slice(2),
    //       phone_number: psngr.customers[0].phone_number,
    //       email: psngr.customers[0].email,
    //       gender: psngr.customers[0].gender,
    //       date_of_birth: psngr.customers[0].date_of_birth
    //     };
    //   else _psngr[key] = value;

    //   toast.dismiss(toastId);
    // } else {
    //   _psngr[key] = value;
    // }
    _psngr[key] = value?.toUpperCase();

    passngrs[index] = _psngr;

    setInitPassengers(passngrs);
  };

  return {
    initPassengers,
    addPassenger,
    removePassenger,
    changePassengerDetail
  };
};

export default usePassenger;
