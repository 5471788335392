import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useRequest = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_REQUESTS", props?.page, props?.amount],
    () =>
      request.get("application", {
        params: {
          page: props.page,
          limit: props.amount
        }
      }),
    { enabled: !!props?.enabled }
  );

  return {
    data,
    isLoading,
    refetch
  };
};

export default useRequest;
