import React, { useRef, useState } from "react";
import styles from "./style.module.scss"; // Импортируйте SCSS-модуль
import { AudioPauseIcon, AudioPlayIcon } from "../../../../assets/icons";
import { LinearProgress } from "@mui/material";

const CustomAudioPlayer = ({ url, timer }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const togglePlay = () => {
    setCurrentTime(0);
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const onLoadedMetadata = () => {
    const audio = audioRef.current;
    if (audio.duration && isFinite(audio.duration)) {
    } else {
      console.warn("Invalid duration:", audio.duration);
    }
  };

  const onTimeUpdate = () => {
    const audio = audioRef.current;
    if (audio.currentTime) {
      const currentTimeInMs = audio.currentTime * 1000 + 50;
      setCurrentTime(currentTimeInMs);

      if (currentTimeInMs >= timer) {
        audio.currentTime = 0;
        audio.pause();
        setIsPlaying(false);
        setCurrentTime(timer);
      }
    }
  };

  const handleProgressClick = (event) => {
    const audio = audioRef.current;
    if (!timer) return;

    const progressBar = event.currentTarget;
    const clickPosition = event.nativeEvent.offsetX;
    const progressBarWidth = progressBar.offsetWidth;
    const newTime = (clickPosition / progressBarWidth) * timer;

    if (!isNaN(newTime) && isFinite(newTime)) {
      audio.currentTime = newTime / 1000;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (timeInMilliseconds) => {
    const minutes = Math.floor(timeInMilliseconds / 60000);
    const seconds = Math.floor((timeInMilliseconds % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className={styles.audio}>
      <audio
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={onTimeUpdate}
        src={url}
      />
      <div onClick={togglePlay} className={styles.control}>
        {isPlaying ? <AudioPauseIcon /> : <AudioPlayIcon />}
      </div>
      <div style={{ width: "100%" }} onClick={handleProgressClick}>
        <LinearProgress
          variant="determinate"
          value={(currentTime / timer) * 100}
          // key={isPlaying}
        />
      </div>
      <div className={styles.time}>
        <p>
          {formatTime(currentTime)}/{formatTime(timer)}
        </p>
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
