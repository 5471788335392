import styles from "../style.module.scss";
import logo from "../../../assets/icons/logo.png";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { agentElements, editorElements, elements } from "./elements";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Button, Typography, Popover, Avatar } from "@mui/material";
import { userRoles } from "../../../consts/roles";
import { useEffect, useMemo, useState } from "react";
import { setToken, setUser } from "../../../redux/auth/authSlice";
import { stringAvatar } from "../../../utils";
import {
  checkVisitedTime,
  patchVisitedTime,
} from "../../../redux/settings/settingsSlice";
import { setOpenProfile } from "../../../redux/chat/chatSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.auth);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(setUser(null));
    dispatch(setToken(null));
  };

  const visitEnd = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const els = useMemo(() => {
    switch (user.role) {
      case userRoles[2].value:
        return agentElements;
      case userRoles[0].value:
        return elements;
      case userRoles[1].value:
        return elements;
      case userRoles[3].value:
        return editorElements;
      default:
        break;
    }
  }, [user?.role]);

  useEffect(() => {
    dispatch(patchVisitedTime(visitEnd));
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkVisitedTime(visitEnd));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // function handleKeyDown(e) {
    //   e.preventDefault();
    //   dispatch(patchVisitedTime(visitEnd));
    // }

    function handleClick(e) {
      // e.preventDefault();
      dispatch(patchVisitedTime(visitEnd));
    }

    // document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClick);

    // Don't forget to clean up
    return function cleanup() {
      // document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={styles.sidebar}>
      <header className={styles.header}>
        <img src={logo} width={123.91} alt="logo" />
      </header>

      <main className={styles.main}>
        <div className={styles.elements}>
          {els.map((element, e) => (
            <NavLink
              to={element.to}
              key={e}
              className={
                pathname.includes(element.to)
                  ? styles.activeNavLink
                  : styles.navLink
              }
              onClick={() => dispatch(setOpenProfile(false))}
            >
              {element.icon}
              <Typography className={styles.label}>{element.label}</Typography>
            </NavLink>
          ))}
        </div>
        <Button className={styles.profile} onClick={handleClick}>
          <Box width="80%" display="flex">
            <Avatar
              {...stringAvatar([user.first_name, user.last_name].join(" "))}
            />

            <Box
              width="60%"
              ml="8px"
              height="52px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography variant="h3">
                {[user.first_name, user.last_name].join(" ")}
              </Typography>
              <Typography variant="h4">
                {userRoles.find((role) => role.value === user.role)?.label}
              </Typography>
            </Box>
          </Box>
          <ArrowForwardIosRoundedIcon />
        </Button>
      </main>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Button
          variant="outlinedSecondary"
          style={{
            width: 120,
          }}
          onClick={logout}
        >
          Выйти{" "}
          <LogoutIcon
            style={{
              marginLeft: "10px",
              fontSize: 15,
            }}
          />
        </Button>
      </Popover>
    </div>
  );
};

export default Sidebar;
