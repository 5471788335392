import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";

import styles from "./style.module.scss";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import useCountry from "../../../../hooks/api/useCountryAPI";

const CountryCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { country, createCountry, updateCountry } = useCountry({
    countryId: id
  });
  const { control, handleSubmit } = useForm({
    values: { ...(country?.country || {}) }
  });

  const onClick = useCallback((vals) => {
    if (!!id) {
      updateCountry.mutate(
        { ...vals },
        {
          onSuccess: () => {
            toast.success("Country is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Country!");
          }
        }
      );
    } else
      createCountry.mutate(
        { ...vals },
        {
          onSuccess: () => {
            toast.success("Country is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Country!");
          }
        }
      );
  }, []);

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content title={!!id ? "Edit Country" : "Add Country"} height="80vh">
            <Box width="100%" display="flex">
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createCountry.isLoading}
            style={{
              width: "120px"
            }}
          >
            {createCountry.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default CountryCreate;
