import moment from "moment";

export const predefinedRanges = [
  {
    label: "Today",
    value: [moment(), moment()],
    placement: "left"
  },
  {
    label: "Yesterday",
    value: [moment().subtract(1, "day"), moment().subtract(1, "day")],
    placement: "left"
  },
  {
    label: "This week",
    value: [moment().startOf("week"), moment().endOf("week")],
    placement: "left"
  },
  {
    label: "Last 7 days",
    value: [moment().subtract(6, "days"), moment()],
    placement: "left"
  },
  {
    label: "Last 30 days",
    value: [moment().subtract(29, "days"), moment()],
    placement: "left"
  },
  {
    label: "This month",
    value: [moment().startOf("month"), moment()],
    placement: "left"
  },
  {
    label: "Last month",
    value: [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month")
    ],
    placement: "left"
  },
  {
    label: "This year",
    value: [moment().startOf("year"), moment()],
    placement: "left"
  },
  {
    label: "Last year",
    value: [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year")
    ],
    placement: "left"
  },
  {
    label: "All time",
    value: [moment(0), moment()],
    placement: "left"
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = moment()] = value || [];
      return [
        moment(start).startOf("week").subtract(7, "days"),
        moment(start).endOf("week").subtract(7, "days")
      ];
    },
    appearance: "default"
  }
];

export const predefinedFutureRanges = [
  {
    label: "Tomorrow",
    value: [moment().add(1, "day"), moment().add(1, "day")],
    appearance: "default"
  },

  {
    label: "Next 7 days",
    value: [moment(), moment().add(6, "days")],
    appearance: "default"
  },
  {
    label: "Next 30 days",
    value: [moment(), moment().add(29, "days")],
    appearance: "default"
  },
  {
    label: "Next month",
    value: [
      moment().add(1, "month").startOf("month"),
      moment().add(1, "month").endOf("month")
    ],
    appearance: "default"
  },
  {
    label: "Next year",
    value: [moment(), moment().add(1, "year").startOf("year")],
    appearance: "default"
  },

  {
    label: "Next week",
    value: (value) => {
      const [start = moment()] = value || [];
      return [
        moment(start).startOf("week").add(7, "days"),
        moment(start).endOf("week").add(7, "days")
      ];
    },
    appearance: "default"
  }
];
