import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import styles from "./style.module.scss";
import {
  carrierCodes,
  flightTypesArray,
} from "../../../../../../../consts/flights";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TSelect from "../../../../../../../components/Select";
import { validateDate } from "@mui/x-date-pickers/internals";
import { promiseAirline } from "../../../../../../../utils/getAirlines";

const SearchParams = ({
  flightType,
  changeFlightType,
  isManualForm,
  setIsManualForm,
  toggleTicketResult,
  searchTickets,
  ticketsCount,
  isSearchLoading,
  includedCarriers,
  setIncludedCarriers,
}) => {
  const onChangeFlightType = (e) => {
    changeFlightType(e.target.value);
  };

  const promiseOptions = (val) =>
    new Promise((resolve) => {
      resolve(
        carrierCodes.filter((car) =>
          car.value.toLowerCase().includes(val.toLowerCase())
        )
      );
    });

  return (
    <div className={styles.params}>
      <div className={styles.box}>
        <Select
          value={flightType}
          className={styles.select}
          onChange={onChangeFlightType}
          style={{
            minWidth: 130,
          }}
        >
          {flightTypesArray.map((flight, f) => (
            <MenuItem value={flight.key} key={f}>
              {flight.value}
            </MenuItem>
          ))}
        </Select>
        <TSelect
          defaultOptions={carrierCodes}
          placeholder="Carriers"
          isMulti={true}
          value={includedCarriers}
          loadOptions={promiseAirline}
          components={{ IndicatorSeparator: null }}
          onChange={(e) => {
            setIncludedCarriers(e);
          }}
          getOptionLabel={(opt) => opt.value}
          getOptionValue={(opt) => opt.key}
          styles={{
            control: () => ({
              backgroundColor: "#fff",
              minWidth: "100px",
              height: 56,
              border: "1px solid transparent",
            }),
          }}
        />

        <Box display="flex" alignItems="center" width="330px">
          <Typography m={0} p={0}>
            Manual Form
          </Typography>
          <Switch
            aria-label="Manual form"
            title="Manual Form"
            checked={isManualForm}
            onChange={(e) => setIsManualForm((prev) => !prev)}
          />
        </Box>
      </div>

      <div className={styles.btn_group}>
        {ticketsCount && (
          <Button variant="containedSecondary" onClick={toggleTicketResult}>
            Found {ticketsCount} fl.
          </Button>
        )}
        <Button
          variant="outlinedSecondary"
          onClick={searchTickets}
          disabled={isSearchLoading}
          style={{
            width: 120,
          }}
        >
          {isSearchLoading ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <SearchOutlinedIcon style={{ marginRight: "5px" }} />
              Search
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default SearchParams;
