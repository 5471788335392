import { RouterProvider } from "react-router-dom";
import { agentRouter, authRouter, editorRouter, router } from "./router";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { isUserAdmin } from "./utils";
import { userRoles } from "./consts/roles";
import "react-image-upload/dist/index.css";

function App() {
  const { token, user } = useSelector((store) => store.auth);

  const routes = useMemo(() => {
    if (!token || !user) return authRouter;

    switch (user.role) {
      case userRoles[2].value:
        return agentRouter;
      case userRoles[0].value:
        return router;
      case userRoles[1].value:
        return router;
      case userRoles[3].value:
        return editorRouter;
      default:
        break;
    }
  }, [token, user]);

  return <RouterProvider router={routes} />;
}

export default App;
