import { iataCodes } from "../consts/flights";
import amadeusApi from "./amadeusApi";
import { request } from "./api";

export const promiseCity = async (query) => {
  if (!query?.length > 0) return [];

  const memoCities = iataCodes.filter((city) =>
    city.key.toLowerCase().includes(query?.toLowerCase())
  );

  if (memoCities?.length > 0) return memoCities;

  if (query?.length < 3) return [];

  const airports = await request.get("iata_code", {
    params: {
      search: query,
      limit: 20,
    },
  });

  if (airports?.rows?.length > 0) return airports?.rows;

  if (query?.length < 3) return [];

  const res = await amadeusApi.get("v1/reference-data/locations/cities", {
    params: {
      keyword: query,
      max: 12,
    },
  });

  return res?.data?.map((city) => ({
    value: city.iataCode,
    label: `${city.name} - ${city.iataCode || ""}`,
  }));
};

export const promiseDirections = (query) =>
  request.get("direction/autocomplete/search", {
    params: {
      term: query,
    },
  });
