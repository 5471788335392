import { useEffect, useState } from "react";
import { flightTypes } from "../consts/flights";
const _route = {
  duration: null,
  departureIataCode: null,
  departureTerminal: null,
  departure_at: null,
  arrivalIataCode: null,
  arrival_at: null,
  carrierCode: null,
  duration_hours: 0,
  duration_minutes: 0,
};

const useFlight = ({ selectedFlight }) => {
  const [flight, setFlight] = useState({
    totalPrice: 0,
    flightType: flightTypes.ONE_WAY,
    routes: [{ ..._route }],
  });

  useEffect(() => {
    if (!selectedFlight) return;

    const routes = [];

    selectedFlight.itineraries.forEach((itinerary) => {
      return itinerary.segments.forEach((flight) => {
        routes.push({
          duration: itinerary.duration,
          departureIataCode: flight.departure.iataCode,
          departureTerminal: flight.departure.terminal,
          departure_at: flight.departure.at,
          arrivalIataCode: flight.arrival.iataCode,
          arrival_at: flight.arrival.at,
          carrierCode: flight.carrierCode,
          duration_hours:
            itinerary?.duration?.match(/(\d+)H/) &&
            itinerary?.duration?.match(/(\d+)H/)?.[1],
          duration_minutes:
            itinerary?.duration?.match(/(\d+)M/) &&
            itinerary?.duration?.match(/(\d+)M/)?.[1],
        });
      });
    });

    const fType = selectedFlight.oneWay
      ? flightTypes.ONE_WAY
      : routes?.length > 4
      ? flightTypes.DIFFICULT_ROUTE
      : flightTypes.ROUND_TRIP;

    setFlight({
      totalPrice: selectedFlight.price.total,
      flightType: fType,
      routes,
    });
  }, [selectedFlight]);

  const changeRouteField = (index, key, value) => {
    const prevFlight = { ...flight };

    prevFlight.routes[index][key] = value;

    setFlight(prevFlight);
  };

  const changePrice = (value) =>
    setFlight((prev) => ({ ...prev, totalPrice: value }));

  const changeFlightType = (value) => {
    setFlight((prev) => {
      if (prev.flightType === flightTypes.ROUND_TRIP && prev.routes.length < 2)
        return {
          ...prev,
          flightType: value,
          routes: [
            ...prev.routes,
            {
              ...({
                departureIataCode: prev.routes[0].arrivalIataCode,
                arrivalIataCode: prev.routes[0].departureIataCode,
              } || _route),
            },
          ],
        };

      return { ...prev, flightType: value };
    });
  };

  const addRoute = () =>
    setFlight((prev) => ({ ...prev, routes: [...prev.routes, _route] }));

  const removeRoute = (index) =>
    setFlight((prev) => ({
      ...prev,
      routes: prev.routes.filter((_, r) => r !== index),
    }));

  const setRoutes = (newRoutes, grossPrice, nettPrice) =>
    setFlight((prev) => ({
      ...prev,
      routes: newRoutes,
      grossPrice: grossPrice,
      nettPrice: nettPrice,
    }));

  const clearData = () => {
    setFlight({
      totalPrice: 0,
      flightType: flightTypes.ONE_WAY,
      routes: [{ ..._route }],
    });
  };

  return {
    flight,
    addRoute,
    removeRoute,
    setRoutes,
    clearData,
    changePrice,
    changeRouteField,
    changeFlightType,
  };
};

export default useFlight;
