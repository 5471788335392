import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ActionRenderer from "./ActionRenderer";
import usePagination from "../../../hooks/usePagination";
import useUsers from "../../../hooks/api/useUsersAPI";
import Wrapper from "../../../components/Wrapper";
import TTable from "../../../components/Table";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";
import { userRoles } from "../../../consts/roles";
import useSearchFlightAPI from "../../../hooks/api/useSearchFlightAPI";
import PublishBadge from "../../../components/PublishBadge";
import { makeCDN } from "../../../utils/uploadImage";
import { flightTypesArray } from "../../../consts/flights";

const FlightSearches = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate } = usePagination(
    "/website/flight-searches"
  );

  const { data, isLoading, refetch } = useSearchFlightAPI({
    enabled: true,
    amount,
    page: page - 1
  });

  const _columns = [
    {
      label: "#",
      key: "key",
      attributes: {
        align: "left",
        width: 30
      }
    },
    {
      label: "Title",
      key: "title",
      attributes: {
        align: "left"
      }
    },
    {
      label: "From Placement",
      key: "from_destination",
      attributes: {
        align: "left"
      },
      render: ({ data }) => {
        return (
          <span>
            {data.from_direction?.airport_name} - (
            {data.from_direction?.iata_code})
          </span>
        );
      }
    },
    {
      label: "To Placement",
      key: "to_placement",
      attributes: {
        align: "left"
      },
      render: ({ data }) => (
        <span>
          {data.to_direction?.airport_name} - ({data.to_direction?.iata_code})
        </span>
      )
    },
    {
      label: "Price",
      key: "price",
      attributes: {
        align: "left"
      },
      render: ({ data }) => (
        <>
          {data.discount_price ? (
            <div>
              <span
                style={{
                  textDecoration: "line-through"
                }}
              >
                {data.price}
              </span>
              &nbsp;
              <span>{data.discount_price}</span>
            </div>
          ) : (
            <span>{data.price}</span>
          )}
        </>
      )
    },

    {
      label: "Type",
      key: "type",
      attributes: {
        align: "left"
      },
      render: ({ data }) => (
        <span>
          {flightTypesArray.find((type) => data.type === type.key)?.value}
        </span>
      )
    },
    {
      label: "Status",
      key: "is_published",
      attributes: {
        align: "left"
      },
      render: ({ data }) => <PublishBadge isPublished={data.is_published} />
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50
      },
      render: ({ data }) => <ActionRenderer data={data} refetch={refetch} />
    }
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.flights?.count}
          data={data?.flights?.rows?.map((user, u) => ({
            ...user,
            key: (page - 1) * amount + u + 1
          }))}
        >
          <Box display="flex" width="100%">
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate("create")}
            >
              <AddIcon
                style={{ marginRight: "5px", color: "var(--primary-color)" }}
              />
              New Flight
            </Button>
          </Box>
        </TTable>
      </div>
    </Wrapper>
  );
};

export default FlightSearches;
