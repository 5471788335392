import { useQuery } from "react-query";
import { request } from "../../utils/api";

const useDashboardAPI = (props) => {
  const decodedIds = decodeURIComponent(props.agent_ids);
  const idsString = decodedIds.split("%2C").join(",");

  const { data, refetch, isLoading } = useQuery(
    ["GET_DASHBOARD", props.type, props.start_date, props.end_date, idsString],
    () =>
      request.get("statistics/dashboard", {
        params: {
          type: props.type,
          start_date: props.start_date,
          end_date: props.end_date,
          agent_ids: idsString,
        },
      }),
    { enabled: props?.enabled, refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useDashboardAPI;
