import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";
import { useWatch } from "react-hook-form";
import FormReactSelect from "../../../../../components/FormSelect";
import FormReactAsyncSelect from "../../../../../components/FormSelect/AsyncSelect";
import {
  carrierCodes,
  flightTypes,
  flightTypesOption,
} from "../../../../../consts/flights";
import { customStyles } from "../../../../../consts/lead";
import MultipleFlightFormType from "./SegmentForm/MultipleFormType";
import OneWayFormType from "./SegmentForm/OneWayFormType";
import RoundTripFormType from "./SegmentForm/RoundTripFormType";
import styles from "./style.module.scss";
import { promiseAirline } from "../../../../../utils/getAirlines";

const Renderers = {
  [flightTypes.ONE_WAY]: OneWayFormType,
  [flightTypes.ROUND_TRIP]: RoundTripFormType,
  [flightTypes.DIFFICULT_ROUTE]: MultipleFlightFormType,
};

export default function FlightSegments({
  segments,
  segmentAppend,
  segmentRemove,
  control,
  watch,
  addSegmentParams,
  params,
  addRouteParam,
  changeFlightParams,
  removeRouteParam,
  reset,
  searchTicketsWithSegmentParams,
  changeFlightType,
  setValue,
  setIncludedCarriers,
  unregister,
}) {
  const newSegment = {
    type: flightTypes.ONE_WAY,
  };
  const formSegments = useWatch({
    control,
    name: "segments",
  });

  const promiseOptions = (val) =>
    new Promise((resolve) => {
      resolve(
        carrierCodes.filter((car) =>
          car.value.toLowerCase().includes(val.toLowerCase())
        )
      );
    });

  const addSegmentParamsAndSearchTickets = (index) => {
    addSegmentParams([formSegments[index]]);
    if (watch(`segments.${[index]}.type`) === "difficult_route") {
      searchTicketsWithSegmentParams(formSegments[index]?.routes);
    } else {
      searchTicketsWithSegmentParams([formSegments[index]]);
    }
  };

  const handleChange = (idx) => {
    unregister(`segments.${[idx]}.city_from`);
    unregister(`segments.${[idx]}.city_to`);
    unregister(`segments.${[idx]}.date_departure`);
    setValue(`segments.${[idx]}.date_departure`, new Date());
  };

  return (
    <div className={styles.segment}>
      {!!segments?.length && (
        <Box className={styles.accordion_list}>
          {segments?.map((item, idx) => {
            const Renderer = Renderers[watch(`segments.${[idx]}.type`)];
            return (
              <Accordion className={styles.accordion} key={idx}>
                <AccordionSummary
                  className={styles.summary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <p>Segment {idx + 1}</p>
                </AccordionSummary>
                <AccordionDetails className={styles.content}>
                  <Box className={styles.segment_form}>
                    <Box className={styles.head}>
                      <FormReactSelect
                        isSearchable={true}
                        control={control}
                        options={flightTypesOption}
                        name={`segments.${[idx]}.type`}
                        customOnChange={(e) => {
                          changeFlightType(e?.value);
                          if (e.value === flightTypes.DIFFICULT_ROUTE) {
                            handleChange(idx);
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => {
                          addSegmentParamsAndSearchTickets(idx);
                        }}
                      >
                        <SearchIcon />
                      </Button>
                    </Box>
                    <FormReactAsyncSelect
                      styles={customStyles}
                      placeholder="Carriers"
                      className={styles.select}
                      isMulti={true}
                      defaultOptions={carrierCodes}
                      options={carrierCodes}
                      getOptionLabel={(opt) => opt.value}
                      getOptionValue={(opt) => opt.key}
                      control={control}
                      name={`segments.${[idx]}.carriers`}
                      loadOptions={promiseAirline}
                      customOnChange={(e) => {
                        setValue(`segments.${[idx]}.carriers`, [...e]);
                        setIncludedCarriers(e);
                      }}
                      components={{ DropdownIndicator: null }}
                    />
                    <Renderer
                      control={control}
                      index={idx}
                      params={params}
                      addRouteParam={addRouteParam}
                      changeFlightParams={changeFlightParams}
                      removeRouteParam={removeRouteParam}
                      reset={reset}
                      setValue={setValue}
                      watch={watch}
                    />
                    <p
                      className={styles.delete_button}
                      onClick={() => segmentRemove(idx)}
                    >
                      <DeleteOutlineIcon /> Delete
                    </p>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
      <Button
        variant="contained"
        fullWidth
        className={styles.add_segment_button}
        onClick={() => {
          segmentAppend(newSegment);
        }}
      >
        <AddIcon /> Segment
      </Button>
    </div>
  );
}
