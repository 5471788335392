import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useNoteAPI = (props) => {
  const { data: getNote } = useQuery(
    ["GET_COMMENT", props?.orderId],
    () => request.get("note/" + props?.orderId),
    {
      enabled: !!props?.orderId,
    }
  );

  const createNote = useMutation((data) => request.post("note", data));

  return {
    getNote,
    createNote,
  };
};

export default useNoteAPI;
