import styles from "./style.module.scss";

const FRow = ({
  label = "",
  children,
  position = "vertical",
  componentClassName = "",
  required = false,
  extra,
  classname,
  ...props
}) => {
  return (
    <div
      className={`${styles.row} ${
        position === "vertical" ? styles.vertical : styles.horizontal
      }`}
      {...props}
    >
      {required || label ? (
        <div className={styles.desc}>
          <div className={`${styles.label} ${classname}`}>
            {required && <span className={styles.requiredStart}>*</span>}
            {label && label}
          </div>
          <div className={styles.extra}>{extra}</div>
        </div>
      ) : null}
      <div className={`${styles.component} ${componentClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default FRow;
