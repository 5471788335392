export default function Form(theme) {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: 44,
          backgroundColor: "rgba(238, 242, 244, 1)",
          borderRadius: 8,
          width: "100%"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "font-size": "16px!important",
          "font-weight": "500!important",
          color: "#0c0c0c!important"
        }
      }
    }
  };
}
