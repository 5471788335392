import { request } from "./api";

export const getAgentsByName = (val) =>
  request
    .get("users/search", {
      params: {
        search: val,
      },
    })
    .then((res) => {
      return res.users;
    });
