import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const HFDateTimePicker = ({
  control,
  className,
  name,
  label,
  width,
  inputProps,
  disabledHelperText,
  placeholder,
  required = false,
  customOnChange = () => {},
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={required ? { required: "This is required field" } : {}}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={className}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="dd-MM-yyyy"
              disablePast
              //   toolbarFormat="dd-MM-yyyy"
              value={new Date(value) || value}
              name={name}
              onChange={(e) => {
                onChange(e);
                customOnChange(e);
              }}
              {...props}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width }}
                  size="small"
                  error={error}
                  helperText={!disabledHelperText && (error?.message ?? " ")}
                  label={label}
                  inputProps={{
                    ...params.inputProps,
                    placeholder,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      )}
    ></Controller>
  );
};

export default HFDateTimePicker;
