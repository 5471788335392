import moment from "moment";

export const calculateAge = (_dob) => {
  const dob = new Date(_dob);
  //calculate month difference from current date in time
  let month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format
  let age_dt = new Date(month_diff);

  //extract year from date
  let year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  let age = Math.abs(year - 1970);

  return age;
};

export const getYesterdayDate = () => ({
  day: moment(new Date()).subtract(1, "days").date(),
  month: moment(new Date()).subtract(1, "days").month() + 1,
  year: moment(new Date()).subtract(1, "days").year()
});

export const getDifferenceOfTwoDates = (firstDate, secondDate) => {
  var startDate = moment(firstDate);
  var endDate = moment(secondDate);

  // Calculate the difference in hours and minutes
  var duration = moment.duration(endDate.diff(startDate));

  // Get the hour and minute components
  var hoursDifference = Math.floor(duration.asHours());
  var minutesDifference = duration.minutes();

  // Format the result as PT{HH}H{MM}M
  return `PT${hoursDifference}H${minutesDifference}M`;
};
