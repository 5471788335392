import TicketVariant from "../../../../../components/TicketVariant";
import Card from "../Card";
import styles from "./style.module.scss";

export default function FlightInfo({ flights, user }) {
  return (
    <Card title="Flights Information" className={styles.box}>
      {flights?.map((item) => (
        <TicketVariant
          key={item.id}
          onlyRead
          data={JSON.parse(item.info)}
          isRoundTrip={item.type === "round_trip"}
        />
      ))}
    </Card>
  );
}
