import { useState } from "react";
import styles from "./style.module.scss";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";

const Accordion = ({ title, extraActions, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div className={styles.accordion}>
      <button className={styles.header} onClick={toggle}>
        <h3>{title}</h3>

        <div className={styles.actions}>
          {extraActions || <></>}
          {isOpen ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </div>
      </button>
      {isOpen && children}
    </div>
  );
};

export default Accordion;
