import FlightTicket from "../../../../../../../components/FlightTicket";

import styles from "./style.module.scss";

const Tickets = ({ currentTicket, selectTicket, tickets, isRoundTrip }) => {
  return (
    <div className={styles.tickets}>
      <div className={styles.wrapper}>
        <h2>Information about existed flights</h2>
        <div className={styles.list}>
          {tickets.map((ticket, t) => (
            <FlightTicket
              key={t}
              onClick={selectTicket}
              data={ticket}
              isRoundTrip={isRoundTrip}
              isActive={currentTicket?.id === ticket.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tickets;
