import Wrapper from "../../components/Wrapper";
import usePagination from "../../hooks/usePagination";
import useUsers from "../../hooks/api/useUsersAPI";
import moment from "moment";
import styles from "./style.module.scss";
import useRevenueAPI from "../../hooks/api/useRevenueAPI";
import OverallStats from "./OverallStats";
import OrderStatsTable from "./OrderStatsTable";
import useDepartureStatsAPI from "../../hooks/api/useDepartureStatsAPI";

import { useSelector } from "react-redux";
import { isUserAdmin, isUserSupervisor } from "../../utils";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { groupOrdersByDate } from "../../utils/group";
import useDashboardAPI from "../../hooks/api/useDashboard";
import { useDebounce } from "use-debounce";

const Dashboard = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("day");
  const { user } = useSelector((store) => store.auth);
  const { filters, page, handleAmount, handlePaginate, amount } =
    usePagination("/dashboard");
  const [debouncedTerm] = useDebounce(filters.term, 500);

  const handleChange = (event, newValue) => {
    setType(newValue);
  };

  const { data: dashboardData } = useDashboardAPI({
    enabled: Boolean(filters),
    type: type,
    start_date:
      filters?.start_date ||
      moment().startOf("month").add(1, "days").format("YYYY-MM-DD"),
    end_date: filters?.end_date || moment().endOf("month").format("YYYY-MM-DD"),
    agent_ids:
      isUserAdmin(user.role) || isUserSupervisor(user.role)
        ? filters.agents?.replace(/%2C/g, ",") || ""
        : user.id,
  });

  const chartData = {
    labels: dashboardData?.dashboard?.map((item) =>
      type === "day"
        ? moment(item.date).format("D MMM")
        : moment(item.month, "YYYY-MM").format("MMMM")
    ),
    soldCount: dashboardData?.dashboard?.map((item) =>
      parseInt(item.sold_count)
    ),
    totalCount: dashboardData?.dashboard?.map((item) =>
      parseInt(item.total_count)
    ),
    cubicInterpolationMode: "monotone",
  };

  const orderFilterDates =
    filters.order_start_date || filters.order_end_date
      ? [new Date(filters.order_start_date), new Date(filters.order_end_date)]
      : [
          new Date(
            moment().startOf("month").add(1, "days").format("YYYY-MM-DD")
          ),
          new Date(moment().endOf("month").add(1, "days").format("YYYY-MM-DD")),
        ];

  const { data } = useRevenueAPI({
    enabled: Boolean(filters),
    agent_ids:
      isUserAdmin(user.role) || isUserSupervisor(user.role)
        ? filters.agents?.replace(/%2C/g, ",")
        : user.id,
    start_date: filters.start_date,
    end_date: filters.end_date,
  });

  const { data: departures, isLoading: isLoadingDepartures } =
    useDepartureStatsAPI({
      enabled: Boolean(filters),
      start_date: filters?.order_start_date || moment().format("YYYY-MM-DD"),
      end_date:
        filters?.order_end_date || moment().add(6, "days").format("YYYY-MM-DD"),
      term: debouncedTerm,
      iata_code: filters?.iata_code,
      agent_ids:
        isUserAdmin(user.role) || isUserSupervisor(user.role)
          ? filters?.order_agents?.replace(/%2C/g, ",")
          : user.id,
    });

  const soldOrders = useMemo(() => {
    return departures?.orders?.filter((item) => item?.lead?.status === "sold");
  }, [departures?.orders]);

  const { data: users } = useUsers({
    enabled: Boolean(filters),
    page: 0,
    amount: 20,
  });

  const result = useMemo(() => data?.result, [data]);

  const onChangeAgentsFilter = useCallback(
    (e, isOrder) => {
      let search = { ...(filters || {}) };

      if (!isOrder)
        search = { ...search, agents: e.map((ag) => ag.id).join(",") };
      else search = { ...search, order_agents: e.map((ag) => ag.id).join(",") };

      if (!filters.start_date)
        if (!isOrder) delete search.start_date;
        else delete search.order_start_date;

      if (!filters.end_date)
        if (!isOrder) delete search.end_date;
        else delete search.order_end_date;

      if (e.length === 0)
        if (!isOrder) delete search.agents;
        else delete search.order_agents;

      navigate({
        pathname: "/dashboard",
        search: createSearchParams(search).toString(),
      });
    },
    [filters, navigate]
  );

  const onChangeDateFilter = useCallback(
    (e, isOrder) => {
      let search = { ...(filters || {}) };
      if (!isOrder)
        search = {
          ...search,
          start_date: moment(e?.[0]).format("YYYY-MM-DD"),
          end_date: moment(e?.[1]).format("YYYY-MM-DD"),
        };
      else
        search = {
          ...search,
          order_start_date: moment(e?.[0]).format("YYYY-MM-DD"),
          order_end_date: moment(e?.[1]).format("YYYY-MM-DD"),
        };

      if (!filters.agents) delete search.agents;
      if (!filters.order_agents) delete search.order_agents;

      navigate({
        pathname: "/dashboard",
        search: createSearchParams(search).toString(),
      });
    },
    [filters, navigate]
  );

  const onCleanDate = useCallback(
    (isOrder) => {
      let search = { ...(filters || {}) };

      if (!isOrder) {
        delete search.start_date;
        delete search.end_date;
      } else {
        delete search.order_start_date;
        delete search.order_end_date;
      }

      if (!filters.agents) delete search.agents;
      if (!filters.order_agents) delete search.order_agents;

      navigate({
        pathname: "/dashboard",
        search: createSearchParams(search).toString(),
      });
    },
    [filters, navigate]
  );

  const setTerm = useCallback(
    (e) => {
      const search = { ...(filters || {}), term: e.target.value };
      navigate({
        pathname: "/dashboard",
        search: createSearchParams(search).toString(),
      });
    },
    [filters, navigate]
  );

  const onChangeIataCode = useCallback(
    (e) => {
      const search = { ...(filters || {}), iata_code: e?.value };

      if (!e?.value) delete search.iata_code;

      navigate({
        pathname: "/dashboard",
        search: createSearchParams(search).toString(),
      });
    },
    [filters, navigate]
  );

  const onClearFilter = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  return (
    <>
      <Wrapper>
        <div className={styles.container}>
          <OverallStats
            user={user}
            users={users}
            result={result}
            filters={filters}
            chartData={chartData}
            onCleanDate={() => onCleanDate()}
            onClearFilter={onClearFilter}
            onChangeDateFilter={(e) => onChangeDateFilter(e, false)}
            onChangeAgentsFilter={(e) => onChangeAgentsFilter(e, false)}
            onChangeType={handleChange}
            type={type}
            dashboardData={dashboardData}
          />
        </div>
      </Wrapper>
      <Wrapper>
        <div className={styles.container}>
          <OrderStatsTable
            amount={amount}
            page={page}
            user={user}
            users={users}
            filters={filters}
            orderFilterDates={orderFilterDates}
            data={groupOrdersByDate(
              soldOrders,
              orderFilterDates[0],
              orderFilterDates[1]
            )}
            orders={soldOrders}
            isLoading={isLoadingDepartures}
            setTerm={setTerm}
            handleAmount={handleAmount}
            onClearFilter={onClearFilter}
            onChangeIataCode={onChangeIataCode}
            onCleanDate={() => onCleanDate(true)}
            handlePaginate={handlePaginate}
            onChangeAgentsFilter={(e) => onChangeAgentsFilter(e, true)}
            onChangeDateFilter={(e) => onChangeDateFilter(e, true)}
          />
        </div>
      </Wrapper>
    </>
  );
};

export default Dashboard;
