import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ActionRenderer from "./ActionRenderer";
import usePagination from "../../../hooks/usePagination";

import Wrapper from "../../../components/Wrapper";
import TTable from "../../../components/Table";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";

import useRegion from "../../../hooks/api/useRegionAPI";

const Regions = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate } =
    usePagination("/website/regions");

  const { data, isLoading, refetch } = useRegion({
    page: page - 1,
    amount,
    enabled: true
  });

  const _columns = [
    {
      label: "#",
      key: "key",
      attributes: {
        align: "left",
        width: 30
      }
    },
    {
      label: "Name",
      key: "name",
      attributes: {
        align: "left"
      }
    },
    {
      label: "Country",
      key: "country",
      attributes: {
        align: "left"
      },

      render: ({ data }) => <span>{data.country.name}</span>
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50
      },
      render: ({ data }) => <ActionRenderer data={data} refetch={refetch} />
    }
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.regions?.count}
          data={data?.regions?.rows?.map((region, u) => ({
            ...region,
            key: u + 1
          }))}
        >
          <Box display="flex" width="100%">
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate("create")}
            >
              <AddIcon
                style={{ marginRight: "5px", color: "var(--primary-color)" }}
              />
              New Region
            </Button>
          </Box>
        </TTable>
      </div>
    </Wrapper>
  );
};

export default Regions;
