import { useMutation, useQuery } from "react-query";
import { telegramApi } from "../../utils/telegramApi";

const useSessionAPI = (props) => {
  const { data: activeUsers, refetch: refetchActiveUsers } = useQuery(
    ["GET_ACTIVE_USERS"],
    () => telegramApi.get("user/list"),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const initSession = useMutation((data) => {
    return telegramApi.post("user/create", data);
  });

  const authTelegram = useMutation((data) =>
    telegramApi.post("telegram/auth", data, {
      headers: {
        "USER-ID": data.user_id,
      },
    })
  );

  const addContactTelegram = useMutation((data) =>
    telegramApi.post("telegram/contact", data)
  );

  const updateContactTelegram = useMutation((data) =>
    telegramApi.put("telegram/contact", data)
  );

  const deleteSession = useMutation((id) =>
    telegramApi.delete("user/" + id, {
      headers: {
        "USER-ID": id,
      },
    })
  );

  return {
    activeUsers,
    refetchActiveUsers,
    initSession,
    authTelegram,
    deleteSession,
    addContactTelegram,
    updateContactTelegram,
  };
};

export default useSessionAPI;
