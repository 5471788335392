import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import styles from "./style.module.scss";
import { useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import EmailPqModal from "../../../OrderPopup/EmailPqModal";

const FlightInfo = ({ currentTicket, handleScreenshot, control }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailModalOpen, setEmailModaOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={styles.flights_info}>
        <Typography variant="h4">Flight information</Typography>
        <Button
          onClick={handleOpen}
          disabled={!currentTicket}
          variant="contained"
        >
          Send PQ
        </Button>
        <Menu
          id="basic-menu"
          className="lead_menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            className={styles.item}
            onClick={() => {
              handleScreenshot("email");
              handleClose();
            }}
          >
            <EmailIcon /> Email
          </MenuItem>
          <MenuItem
            className={styles.item}
            onClick={() => {
              handleScreenshot("tg");
              handleClose();
            }}
          >
            <TelegramIcon /> Telegram
          </MenuItem>
        </Menu>
      </Box>
      {/* <EmailPqModal
        currentTicket={currentTicket}
        open={emailModalOpen}
        setOpen={setEmailModaOpen}
        control={control}
      /> */}
    </>
  );
};

export default FlightInfo;
