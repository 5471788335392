import { Button, CircularProgress, Dialog, TextField } from "@mui/material";
import styles from "./styles.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { UserEdit } from "../../../../../assets/icons";
import FRow from "../../../../../components/FRow";
import useSessionAPI from "../../../../../hooks/api/useSessionAPI";
import toast from "react-hot-toast";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

export default function ContactUpdateModal({ open, setOpen, chatData }) {
  const { id, hashId } = useParams();
  const queryClient = useQueryClient();
  const { updateContactTelegram } = useSessionAPI();
  const [loader, setLoader] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      first_name: chatData?.Users?.[0]?.FirstName,
      last_name: chatData?.Users?.[0]?.LastName,
      chat_id: chatData?.Users?.[0]?.ID,
      access_hash: hashId,
    },
  });

  const handleClear = () => {
    reset({
      first_name: chatData?.Users?.[0]?.FirstName,
      last_name: chatData?.Users?.[0]?.LastName,
      chat_id: chatData?.Users?.[0]?.ID,
    });
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const onSubmit = (values) => {
    setLoader(true);
    const payload = {
      ...values,
    };

    toast.promise(updateContactTelegram.mutateAsync(payload), {
      loading: "Updating contact...",
      success: () => {
        reset({});
        setLoader(false);
        handleClose();
        queryClient.refetchQueries(["GET_CHATS"]);
        queryClient.refetchQueries(["GET_CHAT", id, hashId]);
        return "Success";
      },
      error: () => {
        setLoader(false);
        handleClear();
        return "Error on create contact!";
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <UserEdit />
          </div>
          <div className={styles.content}>
            <h3>Edit Contact</h3>
            <p>Edit your last and first name</p>
          </div>
        </div>
        <div className={styles.body}>
          <FRow label="First name" required>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter first name"
                  style={{
                    background: "#fff",
                  }}
                  required
                />
              )}
            />
          </FRow>

          <FRow label="Last name">
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter last name"
                  style={{
                    background: "#fff",
                  }}
                />
              )}
            />
          </FRow>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={styles.save_button}
            disabled={loader}
            type="submit"
          >
            {loader ? (
              <CircularProgress
                style={{
                  color: "#fff",
                }}
                size={16}
              />
            ) : (
              "Edit"
            )}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
