import { useWatch } from "react-hook-form";
import Switch from "../../../../../components/Switch";
import styles from "./style.module.scss";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
const items = [
  "Flexible tickets for 24 hours",
  "Protected flight",
  "Trip change",
  "Additional services"
];

export default function Insurance({ control }) {
  const hasInsurance = useWatch({
    control,
    name: "has_insurance"
  });

  return (
    <div className={styles.insurance}>
      <div className={styles.header}>
        <h4>Insurance</h4>
        <Switch control={control} name="has_insurance" disabled />
      </div>
      {hasInsurance && (
        <div className={styles.body}>
          {items.map((item) => (
            <div key={item} className={styles.item}>
              <CheckRoundedIcon /> {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
