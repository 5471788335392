import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import classNames from "classnames";
import AirplaneTicketOutlinedIcon from "@mui/icons-material/AirplaneTicketOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import ConnectingAirportsOutlinedIcon from "@mui/icons-material/ConnectingAirportsOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import ThumbsUpDownOutlinedIcon from "@mui/icons-material/ThumbsUpDownOutlined";

const items = [
  {
    title: "Requests",
    path: "requests",
    icon: CloudDoneOutlinedIcon,
  },
  {
    title: "Reviews",
    path: "reviews",
    icon: ThumbsUpDownOutlinedIcon,
  },
  {
    title: "Content",
    path: "contents",
    icon: AddPhotoAlternateOutlinedIcon,
  },
  {
    title: "Categories",
    path: "categories",
    icon: CategoryOutlinedIcon,
  },
  {
    title: "Flight Searches",
    path: "flight-searches",
    icon: ConnectingAirportsOutlinedIcon,
  },
  {
    title: "Directions",
    path: "directions",
    icon: AirplaneTicketOutlinedIcon,
  },
  {
    title: "Regions",
    path: "regions",
    icon: LocationOnOutlinedIcon,
  },
  {
    title: "Countries",
    path: "countries",
    icon: PublicOutlinedIcon,
  },
];

const Website = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <ul className={styles.list}>
          {items.map((item) => (
            <li
              key={item.path}
              className={classNames(styles.item, {
                [styles.active]: pathname.includes(item.path),
              })}
              onClick={() => navigate(item.path)}
            >
              <item.icon /> {item.title}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default Website;
