import Card from "../Card";
import styles from "./style.module.scss";

import passport from "../../../../../assets/icons/passport.svg";
import EventIcon from "@mui/icons-material/Event";

import { Box, TextField, Typography } from "@mui/material";
import { genderTypesArray } from "../../../../../consts/genders";

export default function Customers({
  customers,
  onChangeComment,
  comments,
  customerPayments
}) {
  return (
    <Card title="Passengers">
      <div className={styles.items}>
        {customers?.map((item, i) => (
          <div className={styles.item} key={item.id}>
            <Box display="flex" alignItems="center">
              <Typography fontSize={16} fontWeight={500} mb={1}>
                Passenger {i + 1}
              </Typography>
              &nbsp;
              <Typography>
                ({genderTypesArray.find((gn) => gn.key === item.gender).value})
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={styles.wrapper}
            >
              <div className={styles.info}>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" mb={1}>
                    <Typography fontSize="12px">First Name</Typography>
                    <p className={styles.name}>{item.first_name}</p>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography fontSize="12px">Last Name</Typography>
                    <p className={styles.name}>{item.last_name}</p>
                  </Box>
                </Box>
              </div>
              <div className={styles.element}>
                <p>
                  <img src={passport} alt="passport" /> {item.passport_number}
                </p>
                <p>
                  <EventIcon
                    style={{
                      color: "grey"
                    }}
                  />
                  {item.date_of_birth}
                </p>
              </div>
              <div className={styles.element}>
                <p>
                  <Box display="flex" gap="15px" mb={1}>
                    <Typography fontSize="12px">Weight: </Typography>
                    <p className={styles.name}>
                      {[
                        JSON.parse(customerPayments)[i]?.weight?.value,
                        JSON.parse(customerPayments)[i]?.weight?.unit
                      ].join(" ")}
                    </p>
                  </Box>
                </p>
                <p>
                  <Box alignItems="center" className={styles.comment}>
                    <TextField
                      label="Comment"
                      value={
                        comments.find(
                          (comment) => comment.customer_id === item.id
                        )?.comment
                      }
                      disabled
                    />
                  </Box>
                </p>
              </div>
            </Box>
          </div>
        ))}
      </div>
    </Card>
  );
}
