import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useReview = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_REVIEWS", props?.page, props?.amount],
    () =>
      request.get("review", {
        params: {
          page: props.page,
          limit: props.amount
        }
      }),
    { enabled: !!props?.enabled }
  );

  const updateMutation = useMutation((data) =>
    request.put("review/" + data.id, data)
  );

  const deleteMutation = useMutation((id) => request.delete("review/" + id));

  return {
    data,
    isLoading,
    refetch,
    updateMutation,
    deleteMutation
  };
};

export default useReview;
