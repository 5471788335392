import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useDirectionAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_DIRECTIONS", props?.page, props?.amount],
    () =>
      request.get("direction", {
        params: {
          page: props.page,
          limit: props.amount
        }
      }),
    { enabled: !!props?.enabled }
  );

  const { data: direction } = useQuery(
    ["GET_DIRECTION", props?.directionId],
    () => request.get("direction/" + props?.directionId),
    {
      enabled: !!props?.directionId
    }
  );

  const createDirection = useMutation((data) =>
    request.post("direction", data)
  );

  const updateDirection = useMutation((data) =>
    request.put("direction/" + data.id, data)
  );

  const deleteDirection = useMutation((id) =>
    request.delete("direction/" + id)
  );

  return {
    data,
    isLoading,
    refetch,
    direction,
    createDirection,
    updateDirection,
    deleteDirection
  };
};

export default useDirectionAPI;
