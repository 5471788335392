export const selectStyles = {
  control: () => ({
    minWidth: 300,
    height: "44px",
    padding: "0 10px 0 0",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    backgroundColor: "#fff",
    "font-size": "16px",
    "font-weight": "500",
    border: "1px solid #E5E9EB",
    minHeight: "44px",
  }),
  value: () => ({
    "font-size": "16px",
    "font-weight": "500",
  }),
  options: (styles) => ({
    ...styles,
    zIndex: 99999,
  }),
  option: (styles) => ({
    ...styles,
    zIndex: 99999,
    width: "100%",
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 99999000,
    width: "100%",
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
  placeholder: () => ({
    "font-size": "16px",
    "font-weight": "500",
    left: 12,
    position: "absolute",
    color: "#A7A7A7 !important",
  }),
};
export const leadSelectStyles = {
  control: () => ({
    minWidth: 240,
    height: "44px",
    padding: "0 10px 0 0",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    backgroundColor: "#fff",
    "font-size": "16px",
    "font-weight": "500",
    border: "1px solid #E5E9EB",
    minHeight: "44px",
  }),
  value: () => ({
    "font-size": "16px",
    "font-weight": "500",
  }),
  options: (styles) => ({
    ...styles,
    zIndex: 99999,
    width: "100%",
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
};
export const weightSelectStyle = {
  control: () => ({
    backgroundColor: "#fff",
    border: "1px solid rgba(229, 233, 235, 1)",
    minHeight: "auto",
    height: "-webkit-fill-available",
    borderRadius: "4px",
    fontSize: "12px",
    padding: "0",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "12px",
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
};

export const chatSelectStyles = {
  control: () => ({
    minWidth: "100%",
    height: "44px",
    padding: "0 10px 0 0",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    backgroundColor: "#FEF3F2",
    "font-size": "16px",
    "font-weight": "500",
    color: "#B42318",
    border: "1px solid #FEF3F2",
    minHeight: "44px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#B42318",
    padding: 0,
    cursor: "pointer",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "#B42318",
    padding: 0,
    cursor: "pointer",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#B42318",
  }),
  value: () => ({
    "font-size": "16px",
    "font-weight": "500",
  }),
  options: (styles) => ({
    ...styles,
    zIndex: 99999,
  }),
  multValue: (styles) => {},
  menu: (styles) => ({
    ...styles,
    zIndex: 99999000,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
  placeholder: () => ({
    "font-size": "16px",
    "font-weight": "500",
    left: 12,
    position: "absolute",
    color: "#B42318 !important",
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#fff",
      border: "1px solid #D0D5DD",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#B42318",
      borderRadius: "6px",
      fontWeight: 500,
    };
  },
};
