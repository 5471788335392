import { Box, Button, Popover, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import useCountry from "../../../hooks/api/useCountryAPI";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4
};

const ActionRenderer = ({ data, refetch }) => {
  const navigate = useNavigate();
  const { deleteCountry } = useCountry();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    toast.promise(
      deleteCountry.mutateAsync(data.id, {
        onSuccess: refetch
      }),
      {
        loading: "Удаляется...",
        success: <b>Country delete!</b>,
        error: <b>Error in deleting country.</b>
      }
    );
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <MoreHorizIcon
        aria-describedby={id}
        onClick={handleClick}
        style={{
          cursor: "pointer"
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Box width={120} display="flex" flexDirection="column">
          <Button
            variant="outlinedSecondary"
            onClick={() => navigate(data.id)}
            style={{
              borderRadius: 0
            }}
          >
            Edit
            <LaunchRoundedIcon
              style={{
                marginLeft: "10px",
                fontSize: 16
              }}
            />
          </Button>
          <Button
            variant="contained"
            style={{
              borderRadius: 0
            }}
            onClick={toggleModal}
          >
            Delete
            <DeleteOutlineRoundedIcon
              style={{
                marginLeft: "10px",
                fontSize: 16
              }}
            />
          </Button>
        </Box>
      </Popover>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure, want to delete?
          </Typography>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            gap="10px"
            mt="15px"
          >
            <Button
              variant="outlinedSecondary"
              onClick={handleDelete}
              style={{
                width: "50%"
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="containedSecondary"
              onClick={toggleModal}
              style={{
                width: "50%"
              }}
            >
              No, cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ActionRenderer;
