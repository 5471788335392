import React, { useEffect, useMemo, useRef } from "react";
import Chart from "chart.js/auto";

const WavyLineChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const chartData = useMemo(() => {
    return {
      labels: data.labels,
      datasets: [
        {
          label: "Sold Count",
          data: data.soldCount,
          borderColor: "#099250",
          backgroundColor: "#E3DFD9",
          fill: true,
          tension: 0.5,
        },
        {
          label: "Total Count",
          data: data.totalCount,
          borderColor: "#D92D20",
          backgroundColor: "#F6E8E7",
          tension: 0.5,
          fill: true,
        },
      ],
    };
  }, [data]);

  useEffect(() => {
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          filler: {
            propagate: true,
          },
          legend: {
            display: true,
            position: "bottom",
          },
        },
      },
    });
  }, [chartData]);

  return (
    <div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default WavyLineChart;
