import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useCategoryAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_CATEGORIES", props?.page, props?.amount],
    () =>
      request.get("category", {
        params: {
          page: props.page,
          limit: props.amount
        }
      }),
    { enabled: !!props?.enabled }
  );

  const { data: category } = useQuery(
    ["GET_CATEGORY", props?.categoryId],
    () => request.get("category/" + props?.categoryId),
    {
      enabled: !!props?.categoryId
    }
  );

  const createCategory = useMutation((data) => request.post("category", data));

  const updateCategory = useMutation((data) =>
    request.put("category/" + data.id, data)
  );

  const deleteCategory = useMutation((id) => request.delete("category/" + id));

  return {
    data,
    isLoading,
    refetch,
    category,
    createCategory,
    updateCategory,
    deleteCategory
  };
};

export default useCategoryAPI;
