import moment from "moment";
import { useOutletContext } from "react-router-dom";
import LeadFlightItem from "../LeadFlightItem";
import styles from "../style.module.scss";

const FlightBasket = ({ onEdit, editMode }) => {
  const { isConfirmed, flights, deleteFlight } = useOutletContext();
  return (
    <div className={styles.flight_basket}>
      {flights.map((flight, f) => {
        const destinations = [];
        let arrival_date, departure_date;

        flight?.itineraries?.forEach((itinerary, i) =>
          itinerary?.segments?.forEach((segment, s) => {
            if (i === 0 && s === 0)
              departure_date = moment(segment.departure.at).format(
                "DD.MM.YYYY"
              );

            if (
              i === flight.itineraries.length - 1 &&
              s === itinerary.segments.length - 1
            )
              arrival_date = moment(segment.arrival.at).format("DD.MM.YYYY");

            destinations.push({
              from: segment.departure.iataCode,
              from_at: moment(segment.departure.at).format("HH:mm"),
              to: segment.arrival.iataCode,
              to_at: segment.arrival.at
                ? moment(segment.arrival.at).format("HH:mm")
                : null,
            });
          })
        );

        const flightWithBaggage = {
          ...flight,
          weightType: "NO",
          weightValue: "",
        };

        return (
          <LeadFlightItem
            key={f}
            price={flight?.price?.total}
            flightType={flight.flightType}
            destinations={destinations}
            deleteFlight={() => deleteFlight(f)}
            arrival_data={arrival_date}
            departure_data={departure_date}
            hideActions={isConfirmed}
            onEdit={() => onEdit(flightWithBaggage, f)}
            editMode={editMode}
          />
        );
      })}
    </div>
  );
};

export default FlightBasket;
