import { TextField, Typography } from "@mui/material";
import Card from "../Card";
import styles from "./style.module.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Controller } from "react-hook-form";

const ContactInformation = ({ phone_number, email, control }) => {
  return (
    <Card title="Contact Information">
      <div className={styles.wrapper}>
        <div className={styles.contact}>
          <Typography
            fontSize={18}
            fontWeight={500}
            display="flex"
            alignItems="center"
            gap="10px"
          >
            <LocalPhoneIcon color="primary" />
            <Controller
              name="contact_number"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={phone_number}
                  label="Phone Number"
                  InputProps={{
                    required: true
                  }}
                  disabled
                  required={true}
                />
              )}
            />
          </Typography>
        </div>
        <div className={styles.contact}>
          <Typography
            fontSize={18}
            fontWeight={500}
            display="flex"
            alignItems="center"
            gap="10px"
          >
            <AlternateEmailIcon color="primary" />
            <Controller
              name="contact_email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  value={email}
                  label="Email"
                  InputProps={{
                    required: true
                  }}
                  disabled
                  required={true}
                />
              )}
            />
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default ContactInformation;
