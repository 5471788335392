import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";

import styles from "./style.module.scss";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import TSelect from "../../../../components/Select";
import useCountry from "../../../../hooks/api/useCountryAPI";
import useRegion from "../../../../hooks/api/useRegionAPI";

const RegionCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: countries } = useCountry({
    enabled: true,
    page: 0,
    amount: 50
  });

  const { region, createRegion, updateRegion } = useRegion({
    regionId: id
  });

  const [selectedCountry, setSelectedCountry] = useState(
    region?.region?.country
  );

  const { control, handleSubmit } = useForm({
    values: { ...(region?.region || {}) }
  });

  const onClick = useCallback(
    (vals) => {
      if (!!id) {
        updateRegion.mutate(
          { ...vals, country_id: selectedCountry.id },
          {
            onSuccess: () => {
              toast.success("Region is edited successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in editing Region!");
            }
          }
        );
      } else
        createRegion.mutate(
          { ...vals, country_id: selectedCountry.id },
          {
            onSuccess: () => {
              toast.success("Region is added successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in adding Region!");
            }
          }
        );
    },
    [selectedCountry]
  );

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content title={!!id ? "Edit Region" : "Add Region"} height="80vh">
            <Box width="100%" display="flex">
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Country</p>
                  <TSelect
                    placeholder=""
                    value={selectedCountry}
                    loadOptions={() => countries?.countries?.rows}
                    defaultOptions={countries?.countries?.rows}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setSelectedCountry(e)}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)"
                      })
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createRegion.isLoading}
            style={{
              width: "120px"
            }}
          >
            {createRegion.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default RegionCreate;
