import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: null, user: null, amadeusToken: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, { payload }) {
      state.token = payload;
    },
    setUser(state, { payload }) {
      state.user = payload;
    },
    setAmadeusToken(state, { payload }) {
      state.amadeusToken = payload;
    }
  }
});

export const { setToken, setUser, setAmadeusToken } = authSlice.actions;

export default authSlice.reducer;
