import classNames from 'classnames'
import styles from './style.module.scss'

export default function Card({ title, children, className }) {
  return (
    <div className={classNames(styles.card, className)}>
      <h4>{title}</h4>
      {children}
    </div>
  )
}
