import { carrierCodes } from "../consts/flights";
import { request } from "./api";

export const promiseAirline = async (query) => {
  const airports = await request.get("airline", {
    params: {
      search: query,
      limit: 20,
    },
  });

  if (airports?.rows?.length > 0) return airports?.rows;

  if (query?.length < 3) return [];

  return carrierCodes;
};
