import { useQuery } from "react-query";
import { request } from "../../utils/api";

const useDepartureStatsAPI = ({
  term,
  iata_code,
  start_date,
  end_date,
  agent_ids,
  enabled,
}) => {
  const { data, isLoading, refetch } = useQuery(
    ["GET_DEPARTURE_STATS", term, start_date, end_date, iata_code, agent_ids],
    () =>
      request.get("statistics/departure", {
        params: {
          term,
          start_date: start_date,
          end_date: end_date,
          iata_code,
          agent_ids,
        },
      }),
    { enabled: enabled, refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useDepartureStatsAPI;
