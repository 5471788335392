import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { Checkbox, TextField } from "@mui/material";
import TSelect from "../../../../../../components/Select";
import { genderTypesArray } from "../../../../../../consts/genders";
import ClearIcon from "@mui/icons-material/Clear";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { setExchangePassengers } from "../../../../../../redux/order/orderSlice";

const PassengerItem = ({
  data = {
    first_name: "Name",
    last_name: "Surname",
  },
  changePassengerDetail,
  isConfirmed,
  isRemovable,
  removePassenger,
  isCanceled,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const { exchangePassengers } = useSelector((store) => store.order);

  const handleCheckboxChange = (data, checked) => {
    if (checked) {
      addExchange(data);
    } else {
      deleteExchange(data);
    }
  };

  const addExchange = (data) => {
    dispatch(setExchangePassengers([...exchangePassengers, data.id]));
  };

  const deleteExchange = (data) =>
    dispatch(
      setExchangePassengers(
        exchangePassengers.filter((item, idx) => item !== data.id)
      )
    );

  useEffect(() => {
    dispatch(setExchangePassengers([]));
  }, []);

  const toggle = () => setIsOpen((prev) => !prev);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(genderTypesArray);
      }, 1000);
    });

  const onChangeSelect = (e, key) => {
    changePassengerDetail(key, e.key);
  };

  const onChangeInput = (e) => {
    changePassengerDetail(e.target.name, e.target.value);
  };

  return (
    <div className={styles.passenger}>
      <button className={styles.header} onClick={toggle}>
        <div className={styles.title_box}>
          {isConfirmed || isCanceled ? (
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
              }}
              checked={exchangePassengers?.includes(data.id || {}) || false}
              onChange={(e) => {
                handleCheckboxChange(data, e.target.checked);
              }}
              style={{
                background: "#fff",
              }}
            />
          ) : null}
          <h3>
            {data.first_name || "Name"} {data.last_name || "Surname"}
          </h3>
        </div>

        <div className={styles.actions}>
          {isRemovable && (
            <ClearIcon color="primary" onClick={removePassenger} />
          )}
          {isOpen ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </div>
      </button>
      {isOpen && (
        <div className={styles.body}>
          <div className={styles.field}>
            <p>First name</p>
            <TextField
              variant="outlined"
              className={styles.input}
              name="first_name"
              onChange={onChangeInput}
              value={data.first_name}
              disabled={isConfirmed}
            />
          </div>
          <div className={styles.field}>
            <p>Last name</p>
            <TextField
              variant="outlined"
              className={styles.input}
              name="last_name"
              value={data.last_name}
              onChange={onChangeInput}
              disabled={isConfirmed}
            />
          </div>
          <div className={styles.field}>
            <p>Date of birth</p>
            {/* <TDatePicker
              className={styles.input}
              value={moment(data.date_of_birth)}
              onChange={(e) => onChangeDate(e, "date_of_birth")}
              disabled={isConfirmed}
            /> */}
            <InputMask
              value={data.date_of_birth}
              onChange={onChangeInput}
              disabled={isConfirmed}
              mask="99 aaa 9999"
              maskChar=" "
            >
              {() => (
                <TextField
                  placeholder="01 Jan 2000"
                  name="date_of_birth"
                  variant="outlined"
                  className={styles.input}
                />
              )}
            </InputMask>
          </div>
          {/* <div className={styles.field}>
            <p>Passport serie</p>
            <TextField
              placeholder='AA'
              variant='outlined'
              className={styles.input}
              name='passport_serie'
              value={data.passport_serie}
              onChange={onChangeInput}
              // inputProps={{ maxLength: 2 }}
              disabled={isConfirmed}
            />
          </div> */}
          <div className={styles.field}>
            <p>Passport detail</p>
            <TextField
              placeholder="1234567"
              variant="outlined"
              className={styles.input}
              name="passport_number"
              value={data.passport_number}
              onChange={onChangeInput}
              // inputProps={{ maxLength: 7 }}
              disabled={isConfirmed}
            />
          </div>
          {/* <div className={styles.field}>
            <p>Номер телефона</p>
            <TextField
              placeholder="+998991234567"
              variant="outlined"
              className={styles.input}
              name="phone_number"
              value={data.phone_number}
              onChange={onChangeInput}
              disabled={isConfirmed}
            />
          </div> */}

          {/* <div className={styles.field}>
            <p>Отчество</p>
            <TextField
              variant="outlined"
              className={styles.input}
              name="second_name"
              value={data.second_name}
              onChange={onChangeInput}
              disabled={isConfirmed}
            />
          </div> */}
          {/* <div className={styles.field}>
            <p>Почта</p>
            <TextField
              variant="outlined"
              className={styles.input}
              name="email"
              value={data.email}
              disabled={isConfirmed}
              onChange={onChangeInput}
            />
          </div> */}

          <div className={styles.field}>
            <p>Gender</p>
            <TSelect
              placeholder=""
              value={genderTypesArray.find(
                (gender) => gender.key === data.gender?.toLowerCase()
              )}
              loadOptions={promiseOptions}
              defaultOptions={genderTypesArray}
              components={{ IndicatorSeparator: null }}
              getOptionLabel={(opt) => opt.value}
              getOptionValue={(opt) => opt.key}
              onChange={(e) => (isConfirmed ? {} : onChangeSelect(e, "gender"))}
              disabled={isConfirmed}
              className={isConfirmed && "disabled"}
              styles={{
                control: () => ({
                  backgroundColor: "#fff",
                  minWidth: "200px",
                  border: "1px solid rgba(229, 233, 235, 1)",
                }),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PassengerItem;
