import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useCountry = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_COUNTRIES", props?.page, props?.amount],
    () =>
      request.get("country", {
        params: {
          page: props.page,
          limit: props.amount
        }
      }),
    { enabled: !!props?.enabled }
  );

  const { data: country } = useQuery(
    ["GET_COUNTRY", props?.countryId],
    () => request.get("country/" + props?.countryId),
    {
      enabled: !!props?.countryId
    }
  );

  const createCountry = useMutation((data) => request.post("country", data));

  const updateCountry = useMutation((data) =>
    request.put("country/" + data.id, data)
  );

  const deleteCountry = useMutation((id) => request.delete("country/" + id));

  return {
    data,
    isLoading,
    refetch,
    country,
    createCountry,
    updateCountry,
    deleteCountry
  };
};

export default useCountry;
