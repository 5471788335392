import classNames from "classnames";
import styles from "./style.module.scss";

const PublishBadge = ({ isPublished }) => {
  return (
    <span
      className={classNames(styles.badge, {
        [styles.isPublished]: isPublished
      })}
    >
      {isPublished ? "Published" : "Unpublished"}
    </span>
  );
};

export default PublishBadge;
