import { Box, Typography } from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  flightTypes,
  flightTypesArray,
} from "../../../../../../consts/flights";

const FlightItem = ({
  price = 2400000,
  flightType = flightTypes.ONE_WAY,
  destinations = [
    {
      from: "NYU",
      from_at: "09:00",
      to: "IST",
      to_at: "12:00",
    },
  ],
  arrival_data,
  departure_data,
  onEdit,
  deleteFlight,
  hideActions,
}) => {
  return (
    <Box
      width="100%"
      //   height="110px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="16px 20px"
      borderRadius="8px"
      style={{
        backgroundColor: "rgba(245, 245, 245, 1)",
      }}
    >
      <Box display="flex" flexDirection="column" gap="8px" width="100%">
        <Box display="flex" alignItems="flex-start" gap="8px">
          <Typography
            fontSize="18px"
            lineHeight="22px"
            fontWeight={600}
            style={{
              color: "rgba(44, 44, 44, 1)",
            }}
          >
            {price}
          </Typography>
          <Typography
            fontSize="14px"
            lineHeight="18px"
            fontWeight={500}
            style={{
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            ({flightTypesArray.find((fl) => fl.key === flightType)?.value})
          </Typography>
        </Box>
        <Box display="flex" width="80%" flexWrap="wrap">
          {destinations.map((dest, d) => (
            <Typography
              color="rgba(0, 0, 0, 1)"
              fontSize="16px"
              lineHeight="20px"
              fontWeight={400}
              key={d}
              display="flex"
            >
              {dest.from}&nbsp;
              <Typography
                ontSize="16px"
                lineHeight="20px"
                style={{
                  color: "rgba(110, 110, 110, 1)",
                }}
              >
                ({dest.from_at})
              </Typography>
              &nbsp; - {dest.to}&nbsp;
              {dest.to_at && (
                <Typography
                  ontSize="16px"
                  lineHeight="20px"
                  style={{
                    color: "rgba(110, 110, 110, 1)",
                  }}
                ></Typography>
              )}
              {d !== destinations.length - 1 && (
                <Typography color="primary">&nbsp;/&nbsp;</Typography>
              )}
              &nbsp;
            </Typography>
          ))}
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            color="rgba(0, 0, 0, 1)"
            fontSize="16px"
            lineHeight="20px"
            fontWeight={400}
          >
            {departure_data} {arrival_data && `- ${arrival_data}`}
          </Typography>
        </Box>
      </Box>
      {!hideActions && (
        <Box display="flex" gap="15px">
          <CreateOutlinedIcon style={{ cursor: "pointer" }} onClick={onEdit} />
          <DeleteIcon style={{ cursor: "pointer" }} onClick={deleteFlight} />
        </Box>
      )}
    </Box>
  );
};

export default FlightItem;
