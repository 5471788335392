import { useMutation } from "react-query";
import { request } from "../../utils/api";

const useAuth = () => {
  const login = useMutation((data) => request.post("auth/login", data));

  return { login };
};

export default useAuth;
