import styles from "./style.module.scss";
import { ReactComponent as ArrivalFlightIcon } from "../../assets/icons/arrival_plane.svg";
import { ReactComponent as DepartureFlightIcon } from "../../assets/icons/departure_plane.svg";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import classNames from "classnames";
import moment from "moment";
import { Box, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import TSelect from "../Select";
import { weightTypesArray } from "../../consts/flights";
import { weightSelectStyle } from "../Select/styles";

const calculateDuration = (val) => {
  const removedPt = val?.replace("PT", "");
  const splittedHrs = removedPt?.split("H");
  const hours = splittedHrs?.[0];
  const splittedMinutes = splittedHrs?.[1]?.split("M");
  const minutes = splittedMinutes?.[0];
  return [hours, minutes].filter((item) => !!item).join(":");
};

const calculateLayover = (prev_itin_route_time, this_route_time) => {
  let a = moment(prev_itin_route_time);
  let b = moment(this_route_time);

  const total_hours = b.diff(a, "hours");
  const total_mins = b.diff(a, "minutes");

  return `${total_hours}h ${total_mins - total_hours * 60}m`;
};

const LeadFlightTicket = ({
  data,
  onClick,
  isActive,
  onlyRead = false,
  isRoundTrip,
  clientMode,
  currentTicket,
  editMode,
}) => {
  const [nettPrice, setNettPrice] = useState(data?.price?.total);
  const [grossPrice, setGrossPrice] = useState(
    data?.grossPrice?.total || data?.price?.total
  );
  const [weightType, setWeightType] = useState();
  const [weightValue, setWeightValue] = useState();

  const layovers = useMemo(() => {
    const _lays = [];
    const segments = [];

    data?.itineraries.forEach((itin, i) =>
      itin.segments.forEach((segm, s) => {
        segments.push(segm);
      })
    );

    segments.forEach((segm, idx) => {
      if (idx === 0) return;
      const calculatedTime = calculateLayover(
        segments[idx - 1].arrival.at,
        segm.departure.at
      );

      const hours = Number(calculatedTime.split("h")[0]);

      if (hours > 24) _lays.push("roundtrip");
      else _lays.push(calculatedTime);
    });

    if (isRoundTrip) _lays[Math.floor(_lays.length / 2)] = "roundtrip";

    return _lays;
  }, [data, isRoundTrip]);

  const handleSelect = (e) => {
    if (onlyRead) return;
    onClick({
      ...data,
      grossPrice: grossPrice,
      nettPrice: nettPrice,
      weightValue: data?.weightValue || weightValue || "",
      weightType: data?.weightType || weightType || "NO",
    });
  };

  const promiseOptionsWeights = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(weightTypesArray);
      }, 1000);
    });

  const handleWeightValueChange = (e) => {
    if (editMode) {
      onClick({
        ...data,
        weightValue: e?.target?.value,
      });
    } else {
      onClick({
        ...currentTicket,
        weightValue: e?.target?.value,
      });
    }
    setWeightValue(e.target.value);
  };
  const handleWeightTypeChange = (e) => {
    if (editMode) {
      onClick({
        ...data,
        weightType: e?.value,
        weightValue: e?.value !== "NO" ? data?.weightValue : "",
      });
    } else {
      onClick({
        ...currentTicket,
        weightType: e?.value,
        weightValue: e?.value !== "NO" ? currentTicket?.weightValue : "",
      });
    }
    setWeightType(e?.value);
  };

  const handleGrossPriceChange = (e) => {
    setGrossPrice(e.target.value);
    onClick({
      ...data,
      grossPrice: e.target.value,
      weightValue: weightValue,
      weightType: weightType,
      nettPrice: nettPrice,
    });
  };

  const handleNettPriceChange = (e) => {
    setNettPrice(e.target.value);
    onClick({
      ...data,
      grossPrice: grossPrice,
      weightType: weightType,
      weightValue: weightValue,
      nettPrice: e.target.value,
    });
  };

  return (
    <div
      className={classNames(styles.ticket, {
        [styles.active]: isActive,
        [styles.onlyRead]: onlyRead,
      })}
      onClick={(e) => {
        e.stopPropagation();
        handleSelect(e);
      }}
    >
      {data?.itineraries.map((itinerary, it_idx) =>
        itinerary?.segments?.map((route, r) => {
          const itins = [...data?.itineraries];
          const initSegmentCount =
            itins
              .splice(0, it_idx)
              .map((itin) => itin.segments.length)
              .reduce((prev, curr) => {
                return prev + curr;
              }, 0) + r;

          const layover =
            it_idx >= 1 ? layovers[initSegmentCount] : layovers[r];
          return (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <div key={r} className={classNames(styles.route)}>
                <div className={styles.left}>
                  <img
                    // src={`https://content.airhex.com/content/logos/airlines_${route.carrierCode}_85_30_r.png?proportions=keep`}
                    src={`http://pics.avs.io/100/25/${route?.carrierCode}.png`}
                    alt={route.carrierCode}
                  />
                  <h3>{moment(route.departure.at).format("HH:mm")}</h3>
                  <Box display="flex" flexDirection="column">
                    <p>{route.departure.iataCode}</p>
                    <p>{moment(route.departure.at).format("MMMM DD")}</p>
                  </Box>
                </div>
                <div className={styles.middle}>
                  <div className={styles.date}>
                    <DepartureFlightIcon />
                    {!!calculateDuration(route?.duration) && (
                      <>
                        {calculateDuration(route?.duration)}&nbsp;{" "}
                        {calculateDuration(route?.duration).includes("M")
                          ? null
                          : "hours"}
                      </>
                    )}
                    <ArrivalFlightIcon />
                  </div>
                  <div className={styles.iatas}>
                    <p>{route.departure.iataCode}</p>
                    <p>{route.arrival.iataCode}</p>
                  </div>
                </div>
                <div className={styles.right}>
                  <img
                    // src={`https://content.airhex.com/content/logos/airlines_${route.carrierCode}_85_30_r.png?proportions=keep`}
                    src={`http://pics.avs.io/100/25/${route?.carrierCode}.png`}
                    alt={route.carrierCode}
                  />
                  <h3>{moment(route.arrival.at).format("HH:mm")}</h3>
                  <Box display="flex" flexDirection="column">
                    <p>{route.arrival.iataCode}</p>
                    <p>{moment(route.arrival.at).format("MMMM DD")}</p>
                  </Box>
                </div>
              </div>

              {!!layover ? (
                layover === "roundtrip" ? (
                  <Box
                    display="flex"
                    width="90%"
                    height="0"
                    borderTop="1px solid var(--primary-color)"
                    justifyContent="center"
                    alignItems="center"
                    my={2}
                  ></Box>
                ) : (
                  <Box
                    display="flex"
                    width="100%"
                    height={20}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <HistoryToggleOffIcon color="error" />
                    <Typography
                      color="error"
                      fontSize="12px"
                      fontWeight={600}
                      ml={1}
                    >
                      Layover - {layover}
                      &nbsp;
                    </Typography>
                  </Box>
                )
              ) : (
                ""
              )}
            </Box>
          );
        })
      )}
      <div
        className={classNames(styles.client_mode, {
          [styles.client_mode_active]: clientMode,
        })}
      >
        <p>
          Price:{" "}
          <span>
            <NumericFormat
              value={grossPrice}
              displayType="text"
              decimalScale={2}
              thousandSeparator
              prefix="$"
            />
          </span>
        </p>
      </div>
      <div
        className={classNames(styles.price_form, {
          [styles.price_form_active]: !clientMode,
        })}
      >
        <div className={styles.row}>
          <p>Nett</p>
          <TextField
            className={styles.input}
            defaultValue={nettPrice}
            onChange={(e) => {
              handleNettPriceChange(e);
            }}
          />
        </div>
        <div className={styles.row}>
          <p>Gross</p>
          <TextField
            className={styles.input}
            defaultValue={grossPrice}
            onChange={(e) => {
              handleGrossPriceChange(e);
            }}
          />
        </div>
      </div>
      <div
        className={classNames(styles.price_form, {
          [styles.price_form_active]: !clientMode,
        })}
      >
        <div className={styles.row}>
          <p>Weihgt value</p>
          <TextField
            className={styles.input}
            defaultValue={""}
            onChange={(e) => {
              handleWeightValueChange(e);
            }}
          />
        </div>
        <div
          className={styles.row}
          onClick={(e) => {
            if (!e) return;
            e.stopPropagation();
          }}
        >
          <p>Weight type</p>
          <TSelect
            placeholder="Weight Unit"
            loadOptions={promiseOptionsWeights}
            defaultOptions={weightTypesArray}
            components={{ IndicatorSeparator: null }}
            getOptionLabel={(opt) => opt.value}
            getOptionValue={(opt) => opt.key}
            onChange={(e) => {
              handleWeightTypeChange(e);
            }}
            className={styles.input}
            styles={weightSelectStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default LeadFlightTicket;
