import React from "react";
import { FileIcon } from "../../../../../../assets/icons";
import styles from "./styles.module.scss"; // Импортируйте SCSS-модуль

const FileMessage = ({ isFile, url, message }) => {
  function formatFileSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Bytes";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  }
  return (
    <div className={styles.filemessage}>
      <div className={styles.control}>
        <FileIcon />
      </div>

      <div className={styles.content}>
        <div style={{ width: "100%" }}>
          <a href={url} target="_blank" rel="noreferrer">
            {isFile}
          </a>
        </div>
        <p className={styles.size}>
          {message ? formatFileSize(message?.Media?.Document?.Size || 0) : ""}
        </p>
      </div>
    </div>
  );
};

export default FileMessage;
