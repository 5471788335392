export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 44,
          borderRadius: "8px!important",
          border: "none",
          "font-size": "16px",
          "font-weight": "500",

          "&::placeholder": {
            "font-size": "16px",
            "font-weight": "500"
          }
        },
        contained: {
          backgroundColor: "rgba(238, 242, 244, 1)",
          border: "none"
        },
        outlined: {
          backgroundColor: "transparent"
        }
      }
    }
  };
}
