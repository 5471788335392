import { Avatar, Box } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { stringSingleAvatar } from "../../../../utils/index.js";
import styles from "./style.module.scss";

const SearchResult = ({ formattedContacts, setLeadFormOpen }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div className={styles.contact_box}>
      {formattedContacts?.map((item, index) => {
        const isActive = item.id === id;
        return (
          <Box
            key={item?.id}
            className={classNames(styles.contact, {
              [styles.active]: isActive,
            })}
            onClick={() => {
              setLeadFormOpen(false);
              navigate(`/chat/${item.id}/${item.hash}`);
            }}
          >
            <Avatar
              style={{
                width: "24px",
                height: "24px",
                fontSize: "14px",
              }}
              {...stringSingleAvatar([item?.name].join(" "))}
            />

            <div className={styles.name}>{item.name}</div>
            <div className={styles.username}>{item.username}</div>
          </Box>
        );
      })}
    </div>
  );
};

export default SearchResult;
