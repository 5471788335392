import SelectAsync from "react-select/async";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { selectStyles } from "./styles";

const FormReactAsyncSelect = ({
  control,
  name,
  label,
  width = "100%",
  options = [],
  disabledHelperText,
  placeholder,
  required = false,
  rules = {},
  isClearable,
  isSearchable,
  loadOptions,
  search = () => {},
  customOnChange = () => {},
  onInputChange,
  components,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: required ? "This is required field" : false,
        ...rules,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl style={{ width, height: "100%", background: "100%" }}>
          <InputLabel size="small">{label}</InputLabel>

          <SelectAsync
            value={options?.find((item) => item?.key === value)}
            error={error}
            label={label}
            isClearable={isClearable}
            isSearchable={isSearchable}
            fullWidth={true}
            components={{ ...components, IndicatorSeparator: false }}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e);
              customOnChange(e);
            }}
            onInputChange={onInputChange}
            options={options}
            loadOptions={loadOptions}
            defaultOptions
            {...props}
            styles={{
              ...selectStyles,
              ...props.styles,
              control: () => ({
                ...selectStyles.control(),
                ...props?.styles?.control(),
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#A7A7A7",
              }),
            }}
          />

          {!disabledHelperText && error?.message ? (
            <FormHelperText
              style={{
                margin: 0,
              }}
              error
            >
              {error?.message ?? " "}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    ></Controller>
  );
};

export default FormReactAsyncSelect;
