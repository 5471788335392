export const genderTypes = {
  MALE: "male",
  FEMALE: "female"
};

export const genderTypesArray = [
  {
    key: genderTypes.MALE,
    value: "Male"
  },
  {
    key: genderTypes.FEMALE,
    value: "Female"
  }
];

export const passengerTypes = {
  INFANT: "infant",
  CHILD: "child",
  ADULT: "adult"
};

export const passengerTypesArray = [
  {
    key: passengerTypes.ADULT,
    value: "Adult"
  },
  {
    key: passengerTypes.CHILD,
    value: "Child"
  },
  {
    key: passengerTypes.INFANT,
    value: "Infant"
  }
];
