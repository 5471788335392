import React from "react";
import styles from "./styles.module.scss";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import FileMessagePreview from "../Message/components/FilePreview";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImagePreview = ({ file, onRemove }) => {
  const allowedFileTypes = ["png", "jpg", "jpeg"];

  function extractFileName(url) {
    const fileName = url.split("/").pop();
    return fileName.split("_").slice(1).join("_");
  }

  if (file?.includes("mp4"))
    return (
      <Box className={styles.video}>
        <ReactPlayer url={file} controls />
      </Box>
    );

  if (file && !allowedFileTypes.some((ext) => file.includes(ext))) {
    return <FileMessagePreview isFile={extractFileName(file)} url={file} />;
  }

  return (
    <div className={styles.previewItem}>
      <LazyLoadImage
        src={file}
        alt="preview"
        effect="blur"
        className={styles.previewImage}
        style={{
          transition: "0.5s",
        }}
      />
      <button className={styles.closeButton} onClick={() => onRemove(file)}>
        ×
      </button>
    </div>
  );
};

export default ImagePreview;
