import MuiSwitch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const Switch = styled((props) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ field: { value, onChange } }) => {
      return (
        <MuiSwitch
          focusVisibleClassName=".Mui-focusVisible"
          checked={value}
          onChange={(_, val) => onChange(val)}
          {...props}
        />
      );
    }}
  />
))(({ theme }) => ({
  width: 46,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#D90506",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#CDCDCD"
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#CDCDCD",
    opacity: 1
  }
}));
export default Switch;
