import {
  Box,
  Button,
  CircularProgress,
  Switch,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import Content from "../../../../components/Content";
import TSelect from "../../../../components/Select";
import Wrapper from "../../../../components/Wrapper";
import { publishStatusKeys, publishStatuses } from "../../../../consts/content";
import { flightTypesArray } from "../../../../consts/flights";
import useDirectionAPI from "../../../../hooks/api/useDirectionAPI";
import useSearchFlightAPI from "../../../../hooks/api/useSearchFlightAPI";
import { request } from "../../../../utils/api";
import { promiseDirections } from "../../../../utils/getCities";
import styles from "./style.module.scss";
import FormMultiAsyncSelect from "../../../../components/FormSelect/MultiAsyncSelect";

const FlightSearchCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [fromDirection, setFromDirection] = useState(null);
  const [toDirection, setToDirection] = useState(null);
  const [status, setStatus] = useState(null);
  const [image, setImage] = useState(null);
  const [fightType, setFlightType] = useState();

  const { searchFlight, createSearchFlight, updateSearchFlight } =
    useSearchFlightAPI({ searchFlightId: id });

  const { data, refetch, isLoading } = useSearchFlightAPI({
    searchFlightId: id,
    // amount: 2,
    enabled: true,
  });
  const { control, handleSubmit, watch } = useForm({
    values: {
      ...(searchFlight?.flight || {}),
      similar_flight_ids: data?.flights?.rows
        ?.filter((flight) =>
          searchFlight?.flight?.similar_flight_ids?.includes(flight.id)
        )
        .map((item) => ({ name: item.title, id: item.id })),
    },
  });

  const flightOptions = useMemo(() => {
    return data?.flights?.rows?.map((item) => ({
      name: item?.title,
      id: item?.id,
    }));
  }, [data]);

  const { data: directions } = useDirectionAPI({
    enabled: true,
    page: 0,
    amount: 50,
  });

  useEffect(() => {
    if (!searchFlight) return;

    setStatus(
      searchFlight.flight.is_published ? publishStatuses[0] : publishStatuses[1]
    );
    setFromDirection(searchFlight.flight.from_direction);
    setToDirection(searchFlight.flight.to_direction);
    setImage(searchFlight.flight.image);
    setFlightType(
      flightTypesArray.find((type) => type.key === searchFlight.flight.type)
    );
  }, [searchFlight]);

  const onClick = useCallback(
    (vals) => {
      if (!!id) {
        updateSearchFlight.mutate(
          {
            ...vals,
            from_direction_id: fromDirection.id,
            to_direction_id: toDirection.id,
            is_published: publishStatusKeys.PUBLISHED === status.key,
            image,
            type: fightType?.key,
            expired_date: moment(vals.expired_date).format("YYYY-MM-DD"),
            similar_flight_ids: vals.similar_flight_ids?.map((item) => item.id),
            similar_flights: data?.flights?.rows?.filter((flight) =>
              vals.similar_flight_ids
                ?.map((item) => item.id)
                ?.includes(flight.id)
            ),
          },
          {
            onSuccess: () => {
              toast.success("Search Flight is edited successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in editing Search Flight!");
            },
          }
        );
      } else
        createSearchFlight.mutate(
          {
            ...vals,
            from_direction_id: fromDirection.id,
            to_direction_id: toDirection.id,
            is_published: publishStatusKeys.PUBLISHED === status.key,
            image,
            type: fightType?.key,
            expired_date: moment(vals.expired_date).format("YYYY-MM-DD"),
            similar_flight_ids: vals.similar_flight_ids?.map((item) => item.id),
            similar_flights: data?.flights?.rows?.filter((flight) =>
              vals.similar_flight_ids
                ?.map((item) => item.id)
                ?.includes(flight.id)
            ),
          },
          {
            onSuccess: () => {
              toast.success("Search Flight is added successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in adding Search Flight!");
            },
          }
        );
    },
    [fromDirection, toDirection, status, image, fightType]
  );

  const handleSearchFlights = async (query) => {
    if (!query?.length > 0) return [];

    const memoCities = flightOptions.filter((flight) => {
      return flight.id.toLowerCase().includes(query?.toLowerCase());
    });

    if (memoCities?.length > 0) return memoCities;

    if (query?.length < 3) return [];

    const res = await request.get("flight-search", {
      params: {
        search: query,
      },
    });

    return res?.flights?.rows?.map((fligth) => ({
      id: fligth.id,
      name: fligth.title,
    }));
  };

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Search Flight" : "Add Search Flight"}
            height="80vh"
          >
            <Box width="100%" display="flex" columnGap="24px">
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Title</p>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>From</p>
                  <TSelect
                    placeholder=""
                    value={fromDirection}
                    loadOptions={promiseDirections}
                    defaultOptions={directions?.directions?.rows}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setFromDirection(e)}
                    getOptionLabel={(opt) =>
                      [opt.airport_name, opt.iata_code].join("  -  ")
                    }
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>
                <Box className={styles.field}>
                  <p className={styles.label}>To</p>
                  <TSelect
                    placeholder=""
                    value={toDirection}
                    loadOptions={promiseDirections}
                    defaultOptions={directions?.directions?.rows}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setToDirection(e)}
                    getOptionLabel={(opt) =>
                      [opt.airport_name, opt.iata_code].join("  -  ")
                    }
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Price</p>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Discount Price</p>
                  <Controller
                    name="discount_price"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Expired Date</p>
                  <Controller
                    name="expired_date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="date"
                        className={styles.input}
                        required
                        value={moment(field.value).format("yyyy-MM-DD")}
                      />
                    )}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Flight Types</p>
                  <TSelect
                    placeholder=""
                    value={fightType}
                    loadOptions={() => flightTypesArray}
                    defaultOptions={flightTypesArray}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setFlightType(e)}
                    getOptionLabel={(opt) => opt.value}
                    getOptionValue={(opt) => opt.key}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Status</p>
                  <TSelect
                    placeholder=""
                    value={status}
                    loadOptions={() => publishStatuses}
                    defaultOptions={publishStatuses}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setStatus(e)}
                    getOptionLabel={(opt) => opt.value}
                    getOptionValue={(opt) => opt.key}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Is Popular</p>
                  <Controller
                    name="is_popular"
                    control={control}
                    render={({ field }) => (
                      <Switch {...field} checked={field.value} />
                    )}
                  />
                </Box>
              </Box>
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Meta title</p>
                  <Controller
                    name="meta_title"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta description</p>
                  <Controller
                    name="meta_description"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta keywords</p>
                  <Controller
                    name="meta_keywords"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>SEO text</p>
                  <Controller
                    name="seo_text"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field}>
                  <p className={styles.label}>Similar flights</p>
                  <FormMultiAsyncSelect
                    placeholder="Choose similar flights"
                    className={styles.select}
                    defaultOptions={flightOptions}
                    options={flightOptions}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    control={control}
                    name={`similar_flight_ids`}
                    isMulti
                    loadOptions={handleSearchFlights}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createSearchFlight.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createSearchFlight.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default FlightSearchCreate;
