import { Box, Modal } from "@mui/material";
import ImageGallery from "react-image-gallery";
import styles from "./styles.module.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  px: 4,
  pb: 3,
  borderRadius: "10px",
};

const GalleryPopup = ({ isOpen, close, images }) => {
  return (
    <Modal open={isOpen} onClose={close} className={styles.order_popup}>
      <Box className={styles.card} style={modalStyle}>
        <ImageGallery
          showThumbnails={false}
          originalWidth="1000px"
          showBullets={false}
          showPlayButton={false}
          showFullscreenButton={false}
          items={images}
        />
      </Box>
    </Modal>
  );
};

export default GalleryPopup;
