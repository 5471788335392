import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";
import FormReactAsyncSelect from "../../../../../../components/FormSelect/AsyncSelect";
import { promiseCity } from "../../../../../../utils/getCities";
import styles from "../style.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFieldArray, useWatch } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import HFDateTimePicker from "../../../../../../components/FormDatePicker/DateTimePicker";
import { customStyles } from "../../../../../../consts/lead";
import moment from "moment";
import { iataCodes } from "../../../../../../consts/flights";

export default function MultipleFlightFormType({
  control,
  index,
  params,
  addRouteParam,
  changeFlightParams,
  removeRouteParam,
  setValue,
  watch,
}) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `segments[${index}].routes`,
  });
  const newRoute = {
    from_where: "",
    to_where: "",
    date: "",
  };
  const routesField = `segments[${index}].routes`;

  const onChangeSelect = (e, index, key) => {
    changeFlightParams(index, key, e);
  };

  const onChangeDate = (e, index, key) => {
    changeFlightParams(index, key, { ...e, month: e.month - 1 });
  };

  const cityFrom = useWatch({
    control,
    name: fields.map((_, idx) => `${routesField}[${idx}].city_from`),
  });

  const cityTo = useWatch({
    control,
    name: fields.map((_, idx) => `${routesField}[${idx}].city_to`),
  });
  // useEffect(() => {
  //   let dates = [];

  //   params.forEach((route, r) => {
  //     dates.push(
  //       !!route.departure_at
  //         ? {
  //             ...route.departure_at,
  //             month: route.departure_at.month + 1,
  //           }
  //         : null
  //     );
  //   });

  //   setSelectedDates(dates);
  // }, [JSON.stringify(params)]);

  return (
    <>
      {fields.map((item, idx) => {
        return (
          <Accordion className={styles.accordion}>
            <AccordionSummary
              className={styles.summary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p>Route {idx + 1}</p>
            </AccordionSummary>
            <AccordionDetails className={styles.content}>
              <Box display={"flex"} flexDirection={"column"} rowGap={"8px"}>
                <FormReactAsyncSelect
                  styles={customStyles}
                  placeholder="From"
                  className={styles.select}
                  getOptionLabel={(opt) => opt.key || opt.label || opt.value}
                  getOptionValue={(opt) => opt.value}
                  control={control}
                  defaultOptions={iataCodes}
                  value={cityFrom ? { value: cityFrom, key: cityFrom } : null}
                  name={`${routesField}[${idx}].city_from`}
                  loadOptions={promiseCity}
                  customOnChange={(e) => {
                    setValue(`${routesField}[${idx}].city_from`, e.value);
                    onChangeSelect(e, idx, "city_from");
                  }}
                />
                <FormReactAsyncSelect
                  styles={customStyles}
                  placeholder="To"
                  className={styles.select}
                  getOptionLabel={(opt) => opt.key || opt.label || opt.value}
                  getOptionValue={(opt) => opt.value}
                  control={control}
                  name={`${routesField}[${idx}].city_to`}
                  value={cityTo ? { value: cityTo, key: cityTo } : null}
                  loadOptions={promiseCity}
                  defaultOptions={iataCodes}
                  customOnChange={(e) => {
                    setValue(`${routesField}[${idx}].city_to`, e.value);
                    onChangeSelect(e, idx, "city_to");
                  }}
                />
                <HFDateTimePicker
                  className={"date_time-picker"}
                  control={control}
                  name={`${routesField}[${idx}].date_departure`}
                  customOnChange={(e) => onChangeDate(e, 0, "date_departure")}
                  minDate={
                    new Date(
                      moment(
                        watch(`${routesField}[${idx - 1}].date_departure`)
                      ).add(1, "days")
                    )
                  }
                />
                <Button
                  variant="contained"
                  fullWidth
                  className={styles.add_segment_button}
                  onClick={() => {
                    remove(idx);
                  }}
                >
                  Delete route
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Button
        variant="contained"
        fullWidth
        className={styles.add_segment_button}
        onClick={() => {
          append(newRoute);
        }}
      >
        <AddIcon /> New route
      </Button>
    </>
  );
}
