import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ActionRenderer from "./ActionRenderer";
import usePagination from "../../../hooks/usePagination";
import useUsers from "../../../hooks/api/useUsersAPI";
import Wrapper from "../../../components/Wrapper";
import TTable from "../../../components/Table";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";
import PublishBadge from "../../../components/PublishBadge";
import useDirectionAPI from "../../../hooks/api/useDirectionAPI";
import { makeCDN } from "../../../utils/uploadImage";

const Directions = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate } = usePagination(
    "/website/destinations"
  );

  const { data, isLoading, refetch } = useDirectionAPI({
    enabled: true,
    page: page - 1,
    amount
  });

  const _columns = [
    {
      label: "#",
      key: "key",
      attributes: {
        align: "left",
        width: 30
      }
    },
    {
      label: "Airport Name",
      key: "airport_name",
      attributes: {
        align: "left",
        width: 450
      }
    },
    {
      label: "IATA Code",
      key: "iata_code",
      attributes: {
        align: "left",
        width: 100
      }
    },
    {
      label: "Region",
      key: "region",
      attributes: {
        align: "left"
      },
      render: ({ data }) => <span>{data.region.name}</span>
    },
    // {
    //   label: "Image",
    //   key: "img",
    //   attributes: {
    //     align: "left"
    //   },
    //   render: ({ data }) => (
    //     <img
    //       src={makeCDN(data.image)}
    //       alt="categorry image"
    //       width={100}
    //       style={{ objectFit: "cover" }}
    //     />
    //   )
    // },
    {
      label: "Status",
      key: "status",
      attributes: {
        align: "left",
        width: 100
      },
      render: ({ data }) => <PublishBadge isPublished={data.is_active} />
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50
      },
      render: ({ data }) => <ActionRenderer data={data} refetch={refetch} />
    }
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.directions?.count}
          data={data?.directions?.rows?.map((direction, u) => ({
            ...direction,
            key: (page - 1) * amount + u + 1
          }))}
        >
          <Box display="flex" width="100%">
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate("create")}
            >
              <AddIcon
                style={{ marginRight: "5px", color: "var(--primary-color)" }}
              />
              New Direction
            </Button>
          </Box>
        </TTable>
      </div>
    </Wrapper>
  );
};

export default Directions;
