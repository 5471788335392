import React from "react";
import { Box } from "@mui/material";
import styles from "./style.module.scss";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const MapMessage = ({ location }) => {
  const svgIcon = new L.DivIcon({
    className: "custom-icon",
    html: `<img src="${"/svg/map-pin.svg"}" style="width: 34px; height: 34px;" />`,
    iconSize: [34, 34],
  });

  const generateYandexMapsLink = (location) => {
    const [latitude, longitude] = location || [51.505, -0.09];
    const zoom = 13;
    return `https://yandex.com/maps/?ll=${longitude},${latitude}&z=${zoom}&pt=${longitude},${latitude},pm2rdm`;
  };

  const yandexLink = generateYandexMapsLink(location);

  return (
    <>
      <Box className={styles.map}>
        <MapContainer
          style={{
            width: "100%",
            height: "100%",
          }}
          center={location || [51.505, -0.09]}
          zoom={13}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker icon={svgIcon} position={location || [51.505, -0.09]}>
            <Popup>
              <a href={yandexLink} target="_blank" rel="noreferrer">
                Open to yandex map
              </a>
            </Popup>
          </Marker>
        </MapContainer>
      </Box>
    </>
  );
};

export default MapMessage;
