export default function checkTime(startTime, endTime, checkTime) {
  const startHour = parseInt(startTime.split(":")[0]);
  const startMinute = parseInt(startTime.split(":")[1]);
  const endHour = parseInt(endTime.split(":")[0]);
  const endMinute = parseInt(endTime.split(":")[1]);
  const checkHour = parseInt(checkTime.split(":")[0]);
  const checkMinute = parseInt(checkTime.split(":")[1]);

  const startTotalMinutes = startHour * 60 + startMinute;
  const endTotalMinutes = endHour * 60 + endMinute;
  const checkTotalMinutes = checkHour * 60 + checkMinute;

  return (
    checkTotalMinutes >= startTotalMinutes &&
    checkTotalMinutes <= endTotalMinutes
  );
}
