import { Button, CircularProgress, Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ChatTools from "../Tool";
import { UploadIcon } from "../../../../assets/icons";
import ImagePreview from "../ImagesPreview";
import useChats from "../../../../hooks/api/useChatsAPI";
import toast from "react-hot-toast";

export default function ChatFileUpload({
  open,
  setOpen,
  handleRemoveFile,
  setMultipleFiles,
  chatRefetch,
  messageRefetch,
  payload,
  clearAudio,
  setMessage,
  message,
  isLoading,
  handleScrollDown,
  multipleFiles,
  onMultipleFileAdd,
}) {
  const audio = new Audio("/sound/bubbleSound.mp3");
  const handleClose = () => {
    setOpen(false);
    setMultipleFiles([]);
  };

  const { sendMessage, sendMessageLoading } = useChats();

  const handleSendMessage = () => {
    sendMessage(payload, {
      onSuccess: () => {
        audio.play();
        chatRefetch();
        clearAudio();
        messageRefetch();
        handleClose();
        setMessage("");
        setTimeout(() => {
          handleScrollDown();
        }, 1500);
      },
      onError: (err) => {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error("Error in sending Message!");
        }
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <UploadIcon />
          </div>
          <div className={styles.content}>
            <h3>Upload</h3>
            <p>{multipleFiles?.length} Images uploaded</p>
          </div>
        </div>
        <div className={styles.body}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <div className={styles.previews}>
                {multipleFiles?.map((file) => (
                  <ImagePreview file={file} onRemove={handleRemoveFile} />
                ))}
              </div>
              <ChatTools
                onMultipleFileAdd={onMultipleFileAdd}
                setMessage={setMessage}
                message={message}
                isUpload
              />
            </>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.btn_group}>
            <Button className={styles.cancel_button} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={sendMessageLoading}
              className={styles.save_button}
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
