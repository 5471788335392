import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { userRoles } from "../../../../consts/roles";
import ImageUploader from "react-image-upload";

import styles from "./style.module.scss";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import TSelect from "../../../../components/Select";
import { publishStatusKeys, publishStatuses } from "../../../../consts/content";
import useDirectionAPI from "../../../../hooks/api/useDirectionAPI";
import useRegion from "../../../../hooks/api/useRegionAPI";
import { useMutation } from "react-query";
import { makeCDN, uploadImage } from "../../../../utils/uploadImage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const DirectionCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { direction, createDirection, updateDirection } = useDirectionAPI({
    directionId: id
  });

  const { data: regions } = useRegion({ enabled: true, page: 0, amount: 50 });

  const { control, handleSubmit } = useForm({
    values: { ...(direction?.direction || {}) }
  });

  const uploatMutation = useMutation(uploadImage);

  const [status, setStatus] = useState(null);
  const [region, setRegion] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!direction) return;

    setStatus(
      direction.direction.is_active ? publishStatuses[0] : publishStatuses[1]
    );
    setImage(direction.direction.image);
    setRegion(direction.direction.region);
  }, [direction]);

  const onFileAdd = async (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    uploatMutation.mutate(formData, {
      onSuccess: (res) => {
        setImage(res.res.result.id);
      }
    });
  };

  const onClick = useCallback(
    (vals) => {
      if (!!id) {
        updateDirection.mutate(
          {
            ...vals,
            is_active: status.key === publishStatusKeys.PUBLISHED,
            region_id: region.id,
            image
          },
          {
            onSuccess: () => {
              toast.success("Direction is edited successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in editing direction!");
            }
          }
        );
      } else
        createDirection.mutate(
          {
            ...vals,
            is_active: status.key === publishStatusKeys.PUBLISHED,
            region_id: region.id,
            image
          },
          {
            onSuccess: () => {
              toast.success("Direction is added successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in adding direction!");
            }
          }
        );
    },
    [region, status, image]
  );

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Direction" : "Add Direction"}
            height="80vh"
          >
            <Box width="100%" display="flex">
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Airport Name</p>
                  <Controller
                    name="airport_name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field} width="100%">
                  <p className={styles.label}>IATA Code</p>
                  <Controller
                    name="iata_code"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field}>
                  <p className={styles.label}>Region</p>
                  <TSelect
                    placeholder=""
                    value={region}
                    loadOptions={() => regions?.regions?.rows}
                    defaultOptions={regions?.regions?.rows}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setRegion(e)}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)"
                      })
                    }}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Status</p>
                  <TSelect
                    placeholder=""
                    value={status}
                    loadOptions={() => publishStatuses}
                    defaultOptions={publishStatuses}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setStatus(e)}
                    getOptionLabel={(opt) => opt.value}
                    getOptionValue={(opt) => opt.key}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)"
                      })
                    }}
                  />
                </Box>
              </Box>

              <Box
                width="50%"
                display="flex"
                marginTop="24px"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <label
                  htmlFor="imageUpload"
                  style={{
                    width: "243px",
                    height: "243px",
                    border: "2px dashed #c1c1c1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                >
                  {uploatMutation.isLoading ? (
                    <CircularProgress size={30} />
                  ) : image ? (
                    <img
                      src={makeCDN(image)}
                      width="100%"
                      height="100%"
                      alt="Upload image"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <AddAPhotoIcon />
                  )}

                  <input
                    type="file"
                    id="imageUpload"
                    onChange={onFileAdd}
                    style={{
                      display: "none"
                    }}
                  />
                </label>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createDirection.isLoading}
            style={{
              width: "120px"
            }}
          >
            {createDirection.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default DirectionCreate;
