import { Button, Box, Tab, Tabs } from "@mui/material";
import styles from "../style.module.scss";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { elements } from "../Sidebar/elements";
import { useDispatch, useSelector } from "react-redux";
import { isUserAdmin, isUserSupervisor } from "../../../utils";
import useUsers from "../../../hooks/api/useUsersAPI";
import { statusesArray } from "../../../consts/lead";
import { setStatus } from "../../../redux/order/orderSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.auth);
  const { status } = useSelector((store) => store.order);

  const handleChange = (event, value) => {
    dispatch(setStatus(value));
  };

  const label = useMemo(
    () => elements.find((item) => pathname.includes(item.to))?.label,
    [pathname]
  );

  const showApplicationBtn = useMemo(() => {
    return pathname === "/orders/" || pathname === "/orders";
  }, [pathname]);

  const { downloadContacts } = useUsers();

  const getContacts = () => {
    downloadContacts.mutate(null, {
      onSuccess: (res) => {},
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <div className={styles.navbar}>
      <h2>{label}</h2>

      {showApplicationBtn && (
        <>
          <div id="order_tabs">
            <Tabs
              value={status}
              onChange={handleChange}
              aria-label="secondary tabs example"
              className={styles.order_tabs}
              sx={{ "& .MuiTabs-indicator": { display: "none" } }}
              id
            >
              {statusesArray.map((item) => (
                <Tab value={item.value} label={item.label} key={item.value} />
              ))}
            </Tabs>
          </div>
          <Box sx={{ display: "flex", columnGap: "8px" }}>
            {(isUserAdmin(user.role) || isUserSupervisor(user.role)) && (
              <Box display="flex" alignItems="center">
                <Button
                  variant="contained"
                  disabled={downloadContacts?.isLoading}
                  onClick={getContacts}
                >
                  Get Contacts
                </Button>
              </Box>
            )}
            <Button
              variant="contained"
              onClick={() => navigate("/orders/create")}
            >
              <AddRoundedIcon />
              New Application
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default Navbar;
