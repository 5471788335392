import FlightInfo from "./FlightInfo";
import styles from "./style.module.scss";
import Customers from "./Customers";
import Insurance from "./Insurance";
import Transport from "./Transport";
import PaymentInfo from "./PaymentInfo";
import ContactInformation from "./ContactInformation";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { Container } from "@mui/material";

const OrderPreview = ({ order }) => {
  const [comments, setComments] = useState([]);

  const { setValue, control, watch, reset } = useForm({
    defaultValues: {
      with_hotel: order?.with_hotel,
      with_transport: order?.with_transport,
      has_insurance: order?.has_insurance,
      agent_tip: 20,
      contact_number: null,
      contact_email: null,
      insurance_price: 0,
    },
  });

  useEffect(() => {
    if (!order) return;

    reset({
      with_hotel: order.with_hotel,
      with_transport: order.with_transport,
      has_insurance: order.has_insurance,
      agent_tip: order.agent_tip,
    });

    setComments(JSON.parse(order.customers_comments || "[]"));
  }, [order]);

  // useEffect(() => {
  //   if (!order) return;

  //   setValue(
  //     "insurance_price",
  //     watch().has_insurance ? Number(order.payment.price * 0.09) : 0
  //   );
  // }, [watch().has_insurance, order]);

  const onChangeComment = (e, idx) => {
    const _comments = [...comments];

    _comments[idx].comment = e.target.value;

    setComments(_comments);
  };

  return (
    <>
      <main className={styles.main}>
        <form>
          <Container className={styles.container}>
            <div className={styles.box}>
              <FlightInfo user={order?.user} flights={order?.flights} />
              <Customers
                customers={order?.customers}
                comments={comments}
                customerPayments={order?.customer_payments}
                onChangeComment={onChangeComment}
              />
              <ContactInformation
                phone_number={order?.contact_number}
                email={order?.contact_email}
                control={control}
              />
              <Insurance control={control} />
              <Transport control={control} />
            </div>
            <div className={styles.payment}>
              <PaymentInfo
                isConfirmed={order?.is_confirmed}
                control={control}
                payment={order?.payment}
                insurance_price={watch().insurance_price}
                customerPayments={
                  order?.customer_payments
                    ? JSON.parse(order?.customer_payments)
                    : []
                }
                externalOrderId={order?.external_order_id}
              />
            </div>
          </Container>
        </form>
      </main>
    </>
  );
};

export default OrderPreview;
