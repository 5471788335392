import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  segmentParams: {},
};

const segmentSlice = createSlice({
  name: "segment",
  initialState,
  reducers: {
    setSegmentParams: (state, { payload }) => {
      state.segmentParams = payload;
    },
  },
});
export const { setSegmentParams } = segmentSlice.actions;

export default segmentSlice.reducer;
