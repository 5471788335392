import React from "react";
import styles from "../../style.module.scss";

const LinkMessage = ({ message }) => {
  const URL = message?.Media?.Webpage?.URL;

  return (
    <>
      <div className={styles.body}>
        <a
          href={URL}
          target="_blank"
          rel="noreferrer"
          className={styles.url_content}
        >
          {URL}
        </a>
      </div>
    </>
  );
};

export default LinkMessage;
