import TicketForPq from "../../../../../components/LeadFlightTIcket/TicketForPq";
import styles from "./style.module.scss";

const TicketMockUp = ({ currentTicket, captureRef }) => {
  return (
    <div className={styles.mock_up} ref={captureRef}>
      <TicketForPq data={currentTicket} onlyRead clientMode={true} />
    </div>
  );
};

export default TicketMockUp;
