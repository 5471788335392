import styles from "../style.module.scss";
import FormReactAsyncSelect from "../../../../../../components/FormSelect/AsyncSelect";
import { promiseCity } from "../../../../../../utils/getCities";
import { useEffect, useState } from "react";
import HFDateTimePicker from "../../../../../../components/FormDatePicker/DateTimePicker";
import { useWatch } from "react-hook-form";
import { customStyles } from "../../../../../../consts/lead";
import { iataCodes } from "../../../../../../consts/flights";

export default function RoundTripFormType({
  control,
  index,
  params,
  addRouteParam,
  changeFlightParams,
  removeRouteParam,
  setValue,
}) {
  const data = params[0];
  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);

  const onChangeSelect = (e, index, key) => {
    changeFlightParams(index, key, e);
  };

  const onChangeDate = (e, index, key) => {
    changeFlightParams(index, key, { ...e, month: e.month - 1 });
  };

  useEffect(() => {
    if (!data.departure_at) return;

    setDepartureDate({
      ...data.departure_at,
      month: data.departure_at.month + 1,
    });
  }, [data.departure_at]);

  useEffect(() => {
    if (!data.arrival_at) return;

    setArrivalDate({
      ...data.arrival_at,
      month: data.arrival_at.month + 1,
    });
  }, [data.arrival_at]);

  const departure = useWatch({
    control,
    name: `segments.${[index]}.date_departure`,
  });
  const cityTo = useWatch({
    control,
    name: `segments.${[index]}.city_to`,
  });
  const cityFrom = useWatch({
    control,
    name: `segments.${[index]}.city_from`,
  });

  return (
    <>
      <FormReactAsyncSelect
        styles={customStyles}
        placeholder="From"
        className={styles.select}
        getOptionLabel={(opt) => opt.key || opt.label || opt.value}
        getOptionValue={(opt) => opt.value}
        value={cityFrom ? { value: cityFrom, key: cityFrom } : null}
        control={control}
        defaultOptions={iataCodes}
        name={`segments.${[index]}.city_from`}
        loadOptions={promiseCity}
        customOnChange={(e) => {
          setValue(`segments.${[index]}.city_from`, e.value);
          onChangeSelect(e, index, "city_from");
        }}
      />
      <FormReactAsyncSelect
        styles={customStyles}
        placeholder="To"
        className={styles.select}
        getOptionLabel={(opt) => opt.key || opt.label || opt.value}
        getOptionValue={(opt) => opt.value}
        defaultOptions={iataCodes}
        value={cityTo ? { value: cityTo, key: cityTo } : null}
        control={control}
        name={`segments.${[index]}.city_to`}
        loadOptions={promiseCity}
        customOnChange={(e) => {
          setValue(`segments.${[index]}.city_to`, e.value);
          onChangeSelect(e, index, "city_to");
        }}
      />
      <HFDateTimePicker
        control={control}
        name={`segments.${[index]}.date_departure`}
        customOnChange={(e) => {
          onChangeDate(e, index, "date_departure");
          setValue(`segments.${[index]}.date_back`, "");
        }}
        className={"date_time-picker"}
      />
      <HFDateTimePicker
        control={control}
        minDate={departure}
        name={`segments.${[index]}.date_back`}
        customOnChange={(e) => onChangeDate(e, index, "date_back")}
        className={"date_time-picker"}
      />
    </>
  );
}
