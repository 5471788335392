import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useAirlineAPI = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_AIRLINES", props?.page, props?.amount],
    () =>
      request.get("airline", {
        params: {
          page: props.page,
          limit: props.amount,
        },
      }),
    { enabled: !!props?.enabled }
  );

  const { data: airline } = useQuery(
    ["GET_AIRLINE", props?.airlineId],
    () => request.get("airline/" + props?.airlineId),
    {
      enabled: !!props?.airlineId,
    }
  );

  const createAirline = useMutation((data) => request.post("airline", data));

  const updateAirline = useMutation((data) =>
    request.put("airline/" + data.id, data)
  );

  const deleteAirline = useMutation((id) => request.delete("airline/" + id));

  return {
    data,
    isLoading,
    refetch,
    airline,
    createAirline,
    updateAirline,
    deleteAirline,
  };
};

export default useAirlineAPI;
