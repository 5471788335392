import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useRegion = (props) => {
  const { data, refetch, isLoading } = useQuery(
    ["GET_REGIONS", props?.page, props?.amount],
    () =>
      request.get("region", {
        params: {
          page: props.page,
          limit: props.amount
        }
      }),
    { enabled: !!props?.enabled }
  );

  const { data: region } = useQuery(
    ["GET_REGION", props?.regionId],
    () => request.get("region/" + props?.regionId),
    {
      enabled: !!props?.regionId
    }
  );

  const createRegion = useMutation((data) => request.post("region", data));

  const updateRegion = useMutation((data) =>
    request.put("region/" + data.id, data)
  );

  const deleteRegion = useMutation((id) => request.delete("region/" + id));

  return {
    data,
    isLoading,
    refetch,
    region,
    createRegion,
    updateRegion,
    deleteRegion
  };
};

export default useRegion;
