import React from "react";
import styles from "../../style.module.scss";
import { Avatar } from "@mui/material";
import { stringSingleAvatar } from "../../../../../../utils";

const ContactMessage = ({ message, setOpenContact }) => {
  const firstName = message?.Media?.FirstName;
  const lastName = message?.Media?.LastName;
  const PhoneNumber = message?.Media?.PhoneNumber;
  return (
    <>
      <div className={styles.body}>
        <div
          className={styles.contact}
          onClick={() =>
            setOpenContact({
              open: true,
              first_name: firstName,
              last_name: lastName,
              phone_number: PhoneNumber,
            })
          }
        >
          <div className={styles.content}>
            <Avatar
              style={{
                width: "40px",
                height: "40px",
              }}
              {...stringSingleAvatar([lastName || firstName].join(" "))}
            />
            <div className={styles.desc}>
              <p className={styles.name}>{`${firstName} ${lastName}`}</p>
              <span className={styles.phone}>{PhoneNumber}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMessage;
