import { Box, Button, Popover, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import styles from "./styles.module.scss";
import { DotsVertical, UserEdit } from "../../../../assets/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const ActionRenderer = ({ onOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const open = Boolean(anchorEl);
  const id = open ? "session-simple-popover" : undefined;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <div
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      >
        <DotsVertical
          aria-describedby={id}
          style={{
            cursor: "pointer",
          }}
        />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          width={160}
          display="flex"
          flexDirection="column"
          className={styles.popover_container}
        >
          <Box className={styles.wrapper}>
            <Button
              variant="outlinedSecondary"
              className={styles.button}
              onClick={onOpen}
            >
              <UserEdit
                style={{
                  fontSize: 16,
                }}
              />
              Edit contact
            </Button>
          </Box>
        </Box>
      </Popover>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure , want to delete?
          </Typography>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            gap="10px"
            mt="15px"
          >
            <Button
              variant="outlinedSecondary"
              style={{
                width: "50%",
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="containedSecondary"
              onClick={toggleModal}
              style={{
                width: "50%",
              }}
            >
              No, cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ActionRenderer;
